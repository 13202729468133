@use 'sass:color';

@use 'styles/mixins/rounded-borders';
@use 'styles/mixins/responsive';
@use 'styles/mixins/theme';

.forecast-schedule-notifier {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  gap: var(--space-md);
  padding: var(--space-md);
  cursor: pointer;

  @include responsive.below-tablet() {
    display: none !important;
  }

  .instance {
    overflow: hidden;
    background-color: var(--bg-card);
    font-size: var(--font-xxs);
    animation: highlight 750ms ease-in-out 1s 1;
    padding: var(--space-md);
    box-shadow: var(--shadow-lg);
    @include rounded-borders.border-radius();

    $prev-value: 1s;

    @for $i from 2 through 10 {
      &:nth-of-type(#{$i}) {
        $value: $prev-value + 0.25s;
        $prev-value: $value;

        animation-delay: $value;
      }
    }

    .instance-content {
      margin-bottom: var(--space-sm);

      .instance-name {
        display: flex;
        flex-direction: column;
        gap: var(--space-3xs);
      }
    }

    .instance-progress-bar {
      border-radius: 10px;
      overflow: hidden;

      .instance-progress {
        height: 5px;
      }
    }

    @keyframes highlight {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-10px);
      }
      100% {
        transform: translateY(0);
      }
    }
  }
}
