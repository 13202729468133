@use 'styles/vars/margins';

.chart-creator-list-page {
  padding: margins.$margin-default;

  .data-content {
    padding: 0;

    h1 {
      margin-bottom: margins.$margin-small2;
    }
  }
}
