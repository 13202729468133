@use 'sass:color';

@use 'styles/vars/margins';
@use 'styles/vars/palette';
@use 'styles/vars/colors';
@use 'styles/vars/zindex';
@use 'styles/vars/fonts';
@use 'styles/mixins/responsive';
@use 'styles/mixins/theme';
@use 'styles/mixins/rounded-borders';

@mixin variant($variant, $background, $text, $border, $text-hover) {
  &.is-#{$variant} {
    --message-bar-background: #{$background};
    --message-bar-text: #{$text};
    --message-bar-hover-background: #{$text};
    --message-bar-text-hover: #{$text-hover};

    --message-bar-button-text: #{$text};
    --message-bar-button-border: #{$text};
    --message-bar-button-hover-text: #{$background};
  }
}

.message-bar {
  display: flex;
  align-items: center;
  background-color: var(--message-bar-background);
  color: var(--message-bar-text);
  transition: background-color 150ms;
  padding: var(--space-md);
  gap: var(--space-md);

  .message-bar-icon {
    width: 1.5rem;
    flex-shrink: 0;
    text-align: center;
    font-size: 1.35rem;
    line-height: 0;
  }

  .message-bar-content {
    line-height: 1.15;

    * {
      margin: 0;
    }

    a {
      color: inherit;
      text-decoration-color: inherit;
    }
  }

  .message-bar-close {
    font-size: 1.25rem;
    margin-left: auto;
  }

  button {
    border-color: var(--message-bar-button-border) !important;
    color: var(--message-bar-button-text) !important;
    white-space: nowrap;

    &:hover {
      border-color: var(--message-bar-button-hover-border) !important;
      background-color: var(--message-bar-hover-background);
      color: var(--message-bar-button-hover-text) !important;
    }
  }

  &.is-clickable {
    &:hover {
      //background-color: var(--message-bar-hover-background);
    }
  }

  @include responsive.below-desktop() {
    .message-bar-label {
      display: none;
    }
  }

  @include variant(
    default,
    // bg-default is the same color as the top bar,
    // so we use bg-subtle instead to make a slight contrast
    var(--bg-subtle),
    var(--text-default),
    var(--border-default),
    var(--bg-default-hover)
  );
  @include variant(
    info,
    var(--bg-info),
    var(--text-on-info),
    var(--border-info),
    var(--bg-info-hover)
  );
  @include variant(
    error,
    var(--bg-error),
    var(--text-on-error),
    var(--border-error),
    var(--bg-error-hover)
  );
  @include variant(
    warning,
    var(--bg-warning),
    var(--text-on-warning),
    var(--border-warning),
    var(--bg-warning-hover)
  );
  @include variant(
    success,
    var(--bg-success),
    var(--text-on-success),
    var(--border-success),
    var(--bg-success-hover)
  );
}
