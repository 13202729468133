@use 'styles/vars/fonts';

@use 'styles/components/article-content';

.knowledge-base {
  .knowledge-base-article-page {
    .knowledge-base-article-content {
      max-width: 700px;

      > h1 {
        font-size: 2.5em;
      }

      > p {
        font-weight: fonts.$font-weight-light;
        font-size: fonts.$font-header;
      }
    }

    @include article-content.render();
  }
}
