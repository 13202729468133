@use 'styles/vars/margins';
@use 'styles/vars/zindex';
@use 'styles/mixins/rounded-borders';
@use 'styles/mixins/shadows';
@use 'styles/mixins/responsive';
@use 'styles/mixins/theme';

@use 'styles/components/article-content';

.page-description-popup {
  position: fixed;
  overflow: hidden;
  z-index: zindex.$z-index-nav-menu + 1;
  width: 450px;
  max-width: 100%;
  top: 50px;

  background-color: var(--bg-card);

  opacity: 0;
  pointer-events: none;
  transition: opacity 200ms;

  box-shadow: var(--shadow-lg);
  @include rounded-borders.border-radius();

  &.is-visible {
    opacity: 1;
    pointer-events: auto;
    transform: scale(1);
  }

  .popup-handle {
    display: flex;
    align-items: center;
    background-color: var(--bg-card);

    svg {
      font-size: 1.35em;
    }

    .popup-title {
      flex-grow: 1;
      display: flex;
      align-items: center;
      gap: var(--space-sm);
      cursor: grab;
      padding: var(--space-md);

      &:active {
        cursor: grabbing;
      }
    }

    .popup-close {
      flex-shrink: 0;
      line-height: 0;
      cursor: pointer;
      padding: var(--space-md);

      svg {
        font-size: 1.5em;
      }
    }
  }

  .popup-content {
    max-height: 50vh;
    overflow: auto;
    padding: 0 var(--space-md) var(--space-md) var(--space-md);

    // Article styling from Knowledge base
    .article-content {
      > *:first-child {
        margin-top: 0;
      }
    }

    @include article-content.render();
  }

  @include responsive.below-tablet() {
    display: flex;
    flex-direction: column;
    top: var(--space-sm) !important;
    right: var(--space-sm) !important;
    bottom: var(--space-sm) !important;
    left: var(--space-sm) !important;
    transform: unset !important;
    z-index: zindex.$z-index-dialogs;
    width: auto;

    .popup-handle {
      .popup-title {
        svg {
          display: none;
        }
      }
    }

    .popup-content {
      max-height: none;
      flex-grow: 1;
    }
  }
}
