@use 'styles/mixins/responsive';

// Defaults to border-box

html,
body,
*,
*:before,
*:after {
  box-sizing: border-box;
}

// Prevent popping by always showing scrollbar

:root {
  scroll-behavior: smooth;

  // user-select: none;
}

// Body defaults

body {
  padding: 0;
  margin: 0;

  min-width: 120px;
  min-height: 100vh;

  background: var(--bg-page-body);
  color: var(--text-default);

  overflow-y: scroll;

  &.no-scroll {
    overflow: hidden;

    @include responsive.above-tablet() {
      // Replace scrollbar with empty space
      padding-right: 15px;
    }
  }

  &.no-select {
    user-select: none;
  }
}
