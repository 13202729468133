a.share-page-icon {
  display: inline-block;
  text-decoration: none;
  color: inherit;

  &:hover,
  &:active,
  &:visited {
    color: inherit;
  }

  > svg {
    font-size: 1.75em;
    transition: opacity 150ms;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }
}
