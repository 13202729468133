@use 'sass:color';

@use 'styles/vars/margins';
@use 'styles/mixins/rounded-borders';
@use 'styles/mixins/responsive';
@use 'styles/mixins/theme';
@use 'styles/vars/colors';
@use 'styles/vars/fonts';

@mixin variant($name, $background-color) {
  &.label-#{$name} {
    --label-background: #{$background-color};
    --label-background-hover: #{color.adjust(
        $background-color,
        $lightness: 4%,
        $space: hsl
      )};
    --label-text: var(--text-on-dark);

    &.is-outlined {
      --label-background: transparent;
      --label-border: #{$background-color};
      --label-text: #{$background-color};
    }
  }

  @include theme.variant(light) {
    &.label-#{$name} {
      --label-background: #{$background-color};
      --label-background-hover: #{color.adjust(
          $background-color,
          $lightness: 4%,
          $space: hsl
        )};
      --label-text: var(--text-on-dark);

      &.is-outlined {
        --label-background: transparent;
        --label-border: #{$background-color};
        --label-text: #{$background-color};
      }
    }
  }
}

.label {
  display: inline-block;
  padding: 1px 0.25em;
  line-height: var(--line-short);
  font-size: 0.9em;
  font-weight: var(--font-medium);
  white-space: nowrap;
  background: var(--label-background);
  border: 1px solid var(--label-border);
  color: var(--label-text);
  text-decoration: none !important;
  @include rounded-borders.border-radius;

  &.is-clickable {
    cursor: pointer;

    &:hover {
      background-color: var(--label-background-hover);
    }
  }

  &.is-pulsing {
    animation: pulsing 3s infinite;
  }

  & {
    --label-background: var(--neutral-700);
    --label-border: transparent;
    --label-text: var(--text-on-dark);

    @include theme.variant(light) {
      --label-background: var(--neutral-100);
      --label-text: var(--text-on-light);
    }
  }

  @include variant('blue', colors.$color-blue-3);
  @include variant('blue-2', colors.$color-blue-2);
  @include variant('blue-3', colors.$color-blue-3);
  @include variant('blue-4', colors.$color-blue-4);

  @include variant('red', colors.$color-red-3);
  @include variant('red-2', colors.$color-red-2);
  @include variant('red-3', colors.$color-red-3);
  @include variant('red-4', colors.$color-red-4);

  @include variant('orange-3', colors.$color-orange-3);

  @include variant('green', colors.$color-green-3);
  @include variant('green-3', colors.$color-green-3);

  @include variant('blue-grey-3', colors.$color-blue-grey-3);

  @keyframes pulsing {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.3;
    }
    100% {
      opacity: 1;
    }
  }
}
