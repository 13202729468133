@use 'sass:map';
@use 'styles/vars/margins';
@use 'styles/mixins/responsive';
@use 'styles/vars/palette';
@use 'styles/vars/fonts';
@use 'styles/vars/selectors';
@use 'styles/mixins/rounded-borders';

@forward 'MultipleChoicePopup';

.multiple-selector {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  width: 300px;
  height: selectors.$selector-height;
  padding: margins.$margin-small4;

  background-color: var(--selector-bg);
  cursor: pointer;

  @include rounded-borders.border-radius;

  @include responsive.below(responsive.$breakpoint-mobile-1) {
    max-width: 100%;
  }

  .multiple-selector-hint {
    font-size: fonts.$font-small;
    margin-top: margins.$margin-small3;
  }
}
