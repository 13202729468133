@use 'styles/vars/margins';
@use 'styles/vars/palette';
@use 'styles/mixins/rounded-borders';
@use 'styles/mixins/theme';

.alert {
  display: flex;
  align-items: center;
  gap: var(--space-lg);
  padding: var(--space-lg);
  @include rounded-borders.border-radius();

  a,
  a:hover,
  a:visited {
    color: inherit;
    text-decoration-color: inherit;
  }

  .alert-icon {
    line-height: 0;
    font-size: 1.5em;
  }

  &.is-info {
    background-color: var(--bg-info);
    color: var(--text-on-info);
  }

  &.is-warning {
    background-color: var(--bg-warning);
    color: var(--text-on-warning);
  }

  &.is-error {
    background-color: var(--bg-error);
    color: var(--text-on-error);
  }

  &.is-inline {
    display: inline-flex;
  }

  &.is-spacious {
    margin: var(--space-lg) 0;
  }
}
