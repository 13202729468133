@use 'sass:math';
@use 'styles/vars/margins';
@use 'styles/vars/palette';
@use 'styles/mixins/responsive';

.chart-creator-list {
  .list-charts {
    $gap: margins.$margin-default;

    display: grid;
    grid-template-columns: calc(50% - #{math.div($gap, 2)}) calc(
        50% - #{math.div($gap, 2)}
      );
    gap: $gap;

    @include responsive.below-desktop {
      grid-template-columns: 100%;
    }
  }
}
