@use 'styles/vars/fonts';

.knowledge-base {
  .knowledge-base-video-page {
    .knowledge-base-video-content {
      > h1 {
        font-size: 2.5em;
      }

      > p {
        font-weight: fonts.$font-weight-light;
        font-size: fonts.$font-header;
      }
    }
  }
}
