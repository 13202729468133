@use 'styles/vars/margins';
@use 'styles/mixins/responsive';
@use 'styles/mixins/theme';
@use 'styles/vars/fonts';
@use 'styles/vars/zindex';

@forward 'pages/KnowledgeBaseArticlePage/KnowledgeBaseArticlePage';
@forward 'pages/KnowledgeBaseOverviewPage/KnowledgeBaseOverviewPage';
@forward 'pages/KnowledgeBaseVideoPage/KnowledgeBaseVideoPage';
@forward 'components/RelatedArticlesList/RelatedArticlesList';
@forward 'components/ArticleCard/ArticleCard';

.knowledge-base {
  $knowledge-base-margin: 100px;
  $knowledge-base-narrow-width: 1000px;
  $knowledge-base-wide-width: 1500px;

  min-height: calc(100vh - 300px);

  .knowledge-base-header {
    position: sticky;
    top: 0;
    z-index: zindex.$z-index-above-siblings;
    background-color: var(--base-header-bg);

    .knowledge-base-header-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: var(--space-lg);
      max-width: responsive.$re-analysis-full-1920;
      margin: 0 auto;
      padding: var(--space-md) var(--space-lg);

      .knowledge-base-header-breadcrumbs {
        a {
          color: inherit;
          text-decoration: none;
          font-size: var(--font-sm);
          font-weight: bold;
        }
      }

      .knowledge-base-header-filters {
        display: flex;
        align-items: center;
        gap: var(--space-md);
      }
    }

    @include responsive.below-desktop {
      position: static;

      .knowledge-base-header-content {
        flex-direction: column;
        align-items: flex-start;

        .knowledge-base-header-filters {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
  }

  .knowledge-base-content {
    max-width: responsive.$re-analysis-full-1920;
    padding: var(--space-lg);
    margin: 0 auto;
  }

  .knowledge-base-footer {
    display: flex;
    justify-content: center;
    padding: var(--space-3xl);

    .email-support-card {
      display: flex;
      align-items: center;
      justify-content: center;

      .email-support-card-icon {
        padding-right: var(--space-xxl);
      }

      .tiny-margin {
        margin: var(--space-xs);
      }
    }
  }

  .knowledge-base-container {
    display: flex;
    gap: var(--space-xl);

    .container-navigation {
      width: 450px;

      .container-navigation-sticky {
        position: sticky;
        top: 70px;

        @media screen and (max-height: 900px) {
          position: static;
        }
      }
    }

    .container-content {
      flex: 1;
    }

    @include responsive.below-desktop {
      .container-navigation {
        display: none;
      }
    }
  }

  & {
    --base-header-bg: var(--neutral-925);

    @include theme.variant(light) {
      --base-header-bg: var(--neutral-100);
    }
  }
}
