@use 'styles/mixins/responsive';
@use 'styles/vars/margins';

.curve-push-feed-page {
  .small-top-bar {
    .small-top-bar-content {
      padding-left: 300px;

      @include responsive.below-desktop {
        padding-left: margins.$margin-default;
      }
    }
  }
}
