@use 'styles/vars/margins';
@use 'styles/vars/zindex';
@use 'styles/vars/palette';
@use 'styles/mixins/responsive';
@use 'styles/mixins/shadows';
@use 'styles/mixins/rounded-borders';
@use 'styles/mixins/theme';

.popup.default {
  .popup-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: zindex.$z-index-dialogs + 1;

    padding: margins.$margin-large4;

    overflow-x: hidden;
    overflow-y: auto;
    background: none;

    @include responsive.below-tablet() {
      padding: var(--space-lg);
    }

    > .popup-card {
      max-width: 640px;
      position: relative;

      padding: margins.$margin-large3;
      margin: var(--space-lg) auto;

      background: var(--bg-main-container);
      border-radius: var(--radius-lg);

      // Animation when appearing
      animation: fadeInPopupCard 0.7s ease-in-out;

      // Animation before removing (when is closing)
      transition:
        opacity 0.3s ease-in 0.1s,
        transform 0.4s ease-in 0.1s;

      @keyframes fadeInPopupCard {
        0% {
          opacity: 0;
          transform: translateY(40px);
        }
        60% {
          opacity: 0;
        }
        100% {
          opacity: 1;
          transform: translateY(0);
        }
      }

      @include theme.variant(light) {
        border: none;
      }

      &.is-glowing {
        box-shadow: var(--bg-brand) 0 0 30px 0;
      }

      &.is-wide {
        max-width: 800px;
      }

      > :first-child {
        margin-top: 0 !important;
      }

      &.is-closing {
        opacity: 0;
        transform: translateY(30px);
      }

      .popup-card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: var(--space-md);
        margin-bottom: var(--space-xxl);

        h2 {
          display: flex;
          align-items: center;
          gap: var(--space-md);
          margin: 0;
        }
      }

      .popup-card-content {
        h2 {
          display: flex;
          align-items: center;
          gap: var(--space-md);
          margin: 0 0 var(--space-xxl) 0;
        }
      }

      .popup-card-footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: var(--space-lg);
        margin-top: var(--space-xxl);
      }

      @include responsive.below-tablet() {
        padding: var(--space-lg);
        margin: 0 auto;
      }
    }

    canvas.popup-confetti {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
    }
  }
}
