@use 'styles/vars/margins';
@use 'styles/vars/zindex';
@use 'styles/vars/fonts';
@use 'styles/mixins/rounded-borders';

.multiple-selector {
  .select-input {
    position: relative;

    display: block;

    width: 100%;
    height: 100%;
    padding-right: margins.$margin-large4;

    .multiple-selector-tags {
      position: relative;

      display: flex;
      align-items: center;

      height: 100%;

      overflow: hidden;

      .none-toggled {
        margin-left: margins.$margin-small2;
      }

      .tag-panel {
        display: flex;
        flex-wrap: wrap;

        > .multiple-selector-item {
          margin-left: -2px;
          padding: 0 margins.$margin-small2;

          color: var(--text-disabled);
          font-weight: var(--font-medium);
          text-decoration: none;
          white-space: nowrap;

          line-height: 2em;

          @include rounded-borders.border-radius;

          &.is-selected {
            color: var(--text-default);
            background: var(--selector-accent);
            margin: margins.$margin-small4;

            position: relative;
            z-index: 2;
          }

          &:first-child {
            margin-left: margins.$margin-small4;
          }

          &:last-child {
            margin-right: margins.$margin-small4;
          }

          &.small-boxes {
            display: flex;
            align-items: center;

            height: 14px;
            margin: 1px !important;

            font-size: fonts.$font-small;
          }

          &.invisible {
            display: none;
          }
        }
      }
    }

    .select-input-icon {
      position: absolute;
      right: margins.$margin-default;
      top: 50%;
      margin-top: -0.25em;

      display: block;
      width: 1em;
      height: 0.5em;

      background-color: var(--text-default);
      clip-path: polygon(0 15%, 50% 100%, 100% 15%, 100% 0, 50% 85%, 0 0);

      user-select: none;
    }
  }

  .multiple-selector-card-wrapper {
    position: absolute;
    top: calc(100% + #{margins.$margin-small3});
    right: -1px;
    left: -1px;

    user-select: none;

    .multiple-selector-card {
      position: absolute;
      right: 0;
      z-index: zindex.$z-index-element-mouseovers;

      display: block;
      width: 300px;
      padding: margins.$margin-small1 margins.$margin-default;

      background-color: var(--bg-card);
      box-shadow: var(--shadow-lg);
      @include rounded-borders.border-radius;

      .radio-group {
        display: grid;
        grid-auto-flow: dense;
        grid-template-columns: 1fr 1fr;

        label.radio-input {
          cursor: pointer;
        }
      }
    }
  }
}
