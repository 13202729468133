@use 'styles/vars/margins';
@use 'styles/vars/zindex';
@use 'styles/vars/fonts';
@use 'styles/mixins/shadows';
@use 'styles/mixins/margins' as marginMixin;
@use 'styles/mixins/responsive';
@use 'styles/mixins/rounded-borders';
@use 'styles/vars/selectors';
@use 'styles/mixins/floating-ui';

.button-popover {
}

.button-popover-card-wrapper {
  z-index: zindex.$z-index-element-mouseovers;

  .button-popover-card {
    height: auto;
    min-width: 300px;
    padding: margins.$margin-large2 margins.$margin-large1;
    background: var(--bg-card);
    box-shadow: var(--shadow-lg);
    border-radius: var(--radius-md);
    @include marginMixin.no-margin-first-last-child;

    .arrow {
      margin-bottom: -1px;
      @include floating-ui.arrow(var(--bg-card));

      &.arrow-bottom {
        margin-bottom: auto;
        margin-top: -1px;
      }

      &.arrow-left {
        margin-left: auto;
        margin-right: -1px;
      }
    }

    @include responsive.below-tablet {
      max-width: 100%;
    }
  }
}
