@use 'styles/vars/margins';

.changelog {
  max-width: 700px;
  margin: margins.$margin-large4 * 2 auto;
  padding: margins.$margin-default;

  .ingress {
    font-size: 1.2em;
  }

  .changelog-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--space-xl);

    .changelog-item-card {
      background-color: var(--bg-main-container);
    }
  }
}
