@use 'styles/mixins/responsive';

@include responsive.below-tablet() {
  .responsive-hide-mobile {
    display: none;
  }
}

@include responsive.below-desktop() {
  .responsive-hide-tablet {
    display: none !important;
  }
}

@include responsive.below(responsive.$breakpoint-monitor-1600) {
  .responsive-hide-monitor-1600 {
    display: none;
  }
}

@include responsive.below(responsive.$breakpoint-monitor-1920) {
  .responsive-hide-monitor-1920 {
    display: none;
  }
}

.responsive-show-below-desktop {
  display: none;

  @include responsive.below-desktop() {
    display: block;
  }
}
