@use 'sass:map';
@use 'styles/vars/margins';
@use 'styles/mixins/responsive';

.ranged-calendar {
  .ranged-calendar-calendars {
    display: flex;

    .ranged-calendar-start {
      border-right: 1px solid var(--border-subtle);
      margin-right: margins.$margin-default;
      padding-right: margins.$margin-default;
    }

    @include responsive.below-tablet() {
      flex-direction: column;

      .ranged-calendar-start {
        border-right: none;
        margin-right: 0;
        padding-right: 0;

        border-bottom: 1px solid var(--border-subtle);
        padding-bottom: margins.$margin-default;
        margin-bottom: margins.$margin-default;
      }
    }
  }

  .ranged-calendar-shortcuts {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: margins.$margin-small2;
    border-top: 1px dashed var(--border-subtle);
    margin-top: margins.$margin-default;
    padding-top: margins.$margin-default;

    &.is-top {
      border-top: 0;
      margin-top: 0;
      padding-top: 0;

      border-bottom: 1px solid var(--border-subtle);
      margin-bottom: margins.$margin-default;
      padding-bottom: margins.$margin-default;
    }
  }
}
