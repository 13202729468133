@use 'styles/vars/fonts';

.local-forecasts-blocked {
  max-width: 600px;
  margin: 20px auto;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: fonts.$font-larger;
}
