@use 'sass:color';

@use 'styles/vars/margins';
@use 'styles/vars/colors';
@use 'styles/vars/palette';
@use 'styles/mixins/theme';
@use 'styles/vars/fonts';
@use 'styles/mixins/rounded-borders';

.calendar {
  .calendar-month-year-dropdown {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: var(--space-sm);

    > .calendar-month-year-dropdown-flex-item {
      flex-shrink: 0;

      > * {
        > select {
          padding-top: var(--space-xs);
          padding-bottom: var(--space-xs);
          //height: var(--size-3xl);
        }
      }

      > button {
        width: var(--size-3xl);
        height: var(--size-3xl);
        padding: 0;

        > svg {
          margin: 0;
        }
      }
    }
  }

  table.calendar-table {
    margin: var(--space-md) auto 0 auto;
    border-collapse: collapse;
    border: 0;

    th,
    td {
      text-align: center;
      height: 2rem;
      width: 2rem;

      padding: 0;

      &.cell-week {
        opacity: 0.45;
        font-style: italic;
        width: 3.5rem;
        border-right: 1px solid var(--border-default);
      }

      &.cell-padding {
        width: 0.75em;
      }
    }

    th {
      font-weight: var(--font-regular);
      color: var(--text-subtle);
    }

    td {
      background-color: transparent;
      @include rounded-borders.border-radius;

      &.calendar-cell-selectable {
        cursor: pointer;
      }

      &.calendar-cell-outside, &.calendar-cell-disabled {
        color: var(--text-disabled);
      }

      &.calendar-cell-range {
        @include rounded-borders.border-radius(0);
      }

      &.calendar-cell-range-start {
        @include rounded-borders.border-radius-left;
      }

      &.calendar-cell-range-end {
        @include rounded-borders.border-radius-right;
      }

      &.calendar-cell-selectable {
        &:hover {
          background: var(--calendar-hover-bg);
          color: var(--calendar-hover-text);
        }
      }

      &.calendar-cell-selected {
        background: var(--calendar-selected-bg) !important;
        color: var(--calendar-selected-text) !important;
      }

      &.calendar-cell-today {
        background: var(--calendar-today-bg);
        color: var(--calendar-today-text);
      }

      &.calendar-cell-range {
        background: var(--calendar-range-bg);
        color: colors.$color-white !important;

        &:hover {
          background: var(--calendar-range-hover-bg);
          color: inherit;
        }
      }
    }
  }

  .calendar-shortcuts {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: margins.$margin-small2;
    border-top: 1px dashed var(--border-subtle);
    margin-top: margins.$margin-default;
    padding-top: margins.$margin-default;
  }

  & {
    --calendar-selected-bg: var(--bg-brand);
    --calendar-selected-text: var(--text-on-brand);
    --calendar-today-bg: var(--bg-secondary);
    --calendar-today-text: var(--text-default);
    --calendar-hover-bg: var(--bg-hover);
    --calendar-hover-text: var(--text-default);
    --calendar-range-bg: var(--bg-brand-hover);
    --calendar-range-hover-bg: var(--bg-brand);
  }

  &.calendar--archive {
    --calendar-selected-bg: var(--purple);
  }
}
