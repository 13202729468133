@use 'styles/vars/margins';
@use 'styles/vars/palette';
@use 'styles/vars/zindex';
@use 'styles/mixins/responsive';
@use 'styles/mixins/theme';

footer {
  position: relative;
  z-index: zindex.$z-index-above-siblings;
  display: block;
  border-top: 1px solid var(--border-subtle);
  margin-top: margins.$margin-large4 * 2;
  padding: margins.$margin-large0 margins.$margin-large4 * 2;
  background-color: var(--bg-page-body);

  .footer-content {
    display: flex;
    align-items: center;

    .footer-links {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: margins.$margin-default;
      padding: margins.$margin-large2;
      width: 300px;

      svg {
        height: 40px;
        width: 40px;
        opacity: 0.5;
        margin-bottom: margins.$margin-default;

        path {
          fill: var(--text-default);
        }
      }

      ul {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        gap: margins.$margin-small2;
        margin: 0;
        padding: 0;

        li {
          list-style: none;
          padding: 0;
          margin: 0;

          a {
            color: var(--text-default);
            text-decoration-color: var(--text-disabled);

            &:hover {
              text-decoration-color: var(--text-default);
            }
          }
        }
      }
    }

    .footer-schedule {
      flex: 1;
      padding: margins.$margin-large2;
    }
  }

  @include responsive.below-desktop() {
    padding: margins.$margin-small2;

    .footer-content {
      flex-direction: column;
      align-items: flex-start;

      .footer-links {
        align-items: center;
        width: 100%;
        padding: margins.$margin-small2;
        order: 2;

        ul {
          grid-template-columns: 1fr 1fr 1fr;
          grid-template-rows: 1fr 1fr;

          li {
            text-align: center;
          }
        }
      }

      .footer-schedule {
        width: 100%;
        padding: 0 0 margins.$margin-default 0;
        margin-bottom: margins.$margin-default;
        order: 1;
        border-bottom: 1px solid var(--border-subtle);
      }
    }
  }
}
