@use 'sass:color';

@use 'styles/vars/colors';
@use 'styles/vars/palette';
@use 'styles/vars/fonts';
@use 'styles/mixins/theme';
@use 'styles/vars/margins';
@use 'styles/mixins/shadows';
@use 'styles/mixins/rounded-borders';

@import 'shepherd.js/dist/css/shepherd.css';

.shepherd-modal-overlay-container.shepherd-modal-is-visible {
  opacity: 0.3;

  path {
    fill: var(--shepherd-overlay);
  }
}

.shepherd-element {
  background: var(--shepherd-content-background);
  // Uncomment to enable sliding animation on steps
  //transition: all 500ms;
  border: none;
  @include rounded-borders.border-radius();
  @include shadows.drop-shadow();

  .shepherd-arrow {
    &:before {
      background-color: var(--shepherd-content-background) !important;
    }
  }

  .shepherd-content {
    background-color: var(--shepherd-content-background);
    color: var(--shepherd-content-text);
    font-size: fonts.$font-normal;

    .shepherd-header {
      background-color: transparent;
      padding: margins.$margin-default margins.$margin-default 0 margins.$margin-large2;
      color: var(--shepherd-content-text);
      text-shadow: 0 0 5px rgba(palette.$color-pink-1, 0.5);

      h3 {
        color: inherit;
        font-size: fonts.$font-subheader;
        font-weight: fonts.$font-weight-bold;
      }

      .shepherd-cancel-icon {
        color: var(--shepherd-button-secondary-background) !important;

        &:hover {
          background: inherit;
          box-shadow: none;
        }
      }
    }

    .shepherd-text {
      color: var(--shepherd-content-text);
      font-size: inherit;
      line-height: fonts.$line-height-pararaph;
      padding: margins.$margin-default margins.$margin-large2 margins.$margin-large2
        margins.$margin-large2;
      text-shadow: 0 0 5px var(--shepherd-content-text-background);
    }

    .shepherd-footer {
      display: flex;
      gap: margins.$margin-small2;
      flex-direction: row;
      justify-content: space-between;
      margin: 0;
      padding: margins.$margin-default;
      border-color: var(--shepherd-footer-border);
      background-color: var(--shepherd-footer-background);

      .shepherd-button {
        transition: none;
        border: 0;
        background-color: var(--shepherd-button-background);
        box-shadow: none;
        min-width: 80px;

        // Force the Next button to the right when it's the only
        // button in the footer
        margin: 0 0 0 auto;

        @include rounded-borders.border-radius();

        &.shepherd-button-secondary {
          background-color: var(--shepherd-button-secondary-background);

          // Do not force Previous button to the right!
          margin: 0;
        }

        &.button-primary {
          background-color: var(--shepherd-button-primary-background);
          color: var(--shepherd-button-primary-text) !important;
        }
      }
    }
  }
}

@include theme.variant(dark) {
  $base-color: palette.$color-pink-3;

  --shepherd-shadow: #{rgba(colors.$color-black, 1)};
  --shepherd-overlay: #{palette.$color-blue-grey-7};

  --shepherd-content-background: #{$base-color};
  --shepherd-content-text: #{colors.$color-white};
  --shepherd-content-text-background: #{rgba(palette.$color-pink-1, 0.5)};

  --shepherd-footer-background: #{color.adjust(
      $base-color,
      $lightness: -3%,
      $space: hsl
    )};
  --shepherd-footer-border: #{color.adjust($base-color, $lightness: -4%, $space: hsl)};

  --shepherd-button-background: #{palette.$color-pink-1};
  --shepherd-button-primary-background: #{palette.$color-pink-7};
  --shepherd-button-primary-text: #{palette.$color-pink-1};
  --shepherd-button-secondary-background: #{rgba(palette.$color-pink-1, 0.4)};
}

@include theme.variant(light) {
  $base-color: palette.$color-pink-3;

  --shepherd-shadow: #{rgba(colors.$color-black, 1)};
  --shepherd-overlay: #{palette.$color-blue-grey-3};

  --shepherd-content-background: #{$base-color};
  --shepherd-content-text: #{colors.$color-white};
  --shepherd-content-text-background: #{rgba(palette.$color-pink-1, 0.5)};

  --shepherd-footer-background: #{color.adjust(
      $base-color,
      $lightness: -3%,
      $space: hsl
    )};
  --shepherd-footer-border: #{color.adjust($base-color, $lightness: -4%, $space: hsl)};

  --shepherd-button-background: #{palette.$color-pink-1};
  --shepherd-button-primary-background: #{palette.$color-pink-6};
  --shepherd-button-primary-text: #{palette.$color-pink-1};
  --shepherd-button-secondary-background: #{rgba(palette.$color-pink-1, 0.4)};
}
