@use 'styles/vars/margins';
@use 'styles/vars/fonts';

.chart-creator-chart-options {
  .options-list {
    display: flex;
    gap: margins.$margin-default;

    .list-column {
      label {
        display: block;
        font-size: 0.75em;
        text-transform: uppercase;
        color: var(--text-subtle);
        margin: 0 0 0.25rem 0;
      }
    }
  }
}
