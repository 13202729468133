$-legend-symbol-width: 0.75rem;

.simple-legend-line {
  .line-stroke {
    width: $-legend-symbol-width;
    position: relative;
    height: 0;
    border-bottom-style: solid;

    &.is-dashed {
      border-bottom-style: dotted;
    }
  }

  .line-points {
    position: relative;

    .line-point {
      margin: 0 auto;

      &.is-upper {
        margin-bottom: -1px;
      }

      &.is-lower {
        margin-top: -1px;
        transform: rotate(180deg);
      }
    }
  }
}
