@use 'styles/vars/margins';
@use 'styles/vars/zindex';
@use 'styles/vars/fonts';
@use 'styles/mixins/rounded-borders';
@use 'styles/mixins/responsive';

.drawer {
  .drawer-trigger {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    left: var(--space-md);
    right: var(--space-md);
    bottom: var(--space-md);
    background-color: var(--bg-main-container);
    box-shadow: var(--shadow-lg);
    border-radius: var(--radius-md);
    z-index: zindex.$z-index-element-mouseovers;
    padding: margins.$margin-default;

    .drawer-trigger-hint {
      color: var(--text-brand);
      font-weight: fonts.$font-weight-medium;
    }
  }

  .drawer-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: zindex.$z-index-dialogs - 1;
    background-color: var(--bg-dimmed);
    backdrop-filter: blur(2px);
    opacity: 0;
    pointer-events: none;
    transition: opacity 250ms;
  }

  .drawer-popup {
    position: fixed;
    left: var(--space-md);
    right: var(--space-md);
    bottom: var(--space-md);
    height: 85%;
    z-index: zindex.$z-index-dialogs;
    transform: translateY(calc(100% + 20px));
    transition: transform 250ms;
    overflow-y: auto;
    background-color: var(--bg-main-container);
    border-radius: var(--radius-lg);
    padding: margins.$margin-default;
  }

  &.is-open {
    .drawer-backdrop {
      opacity: 1;
      pointer-events: all;
    }

    .drawer-popup {
      transform: translateY(0);
    }
  }

  @include responsive.above-tablet() {
    .drawer-trigger {
      display: none;
    }
  }
}
