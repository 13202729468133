.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--icon-default);
  color: var(--icon-on-inverse);
  text-transform: uppercase;
  font-size: var(--font-xxs);
  font-weight: var(--font-medium);
  letter-spacing: 0;
  line-height: 0;
  width: var(--size-xxl);
  height: var(--size-xxl);
  border-radius: var(--radius-full);

  &.is-md {
    width: var(--size-4xl);
    height: var(--size-4xl);
    font-size: var(--font-xs);
  }

  &.is-lg {
    width: var(--size-6xl);
    height: var(--size-6xl);
    font-size: var(--font-sm);
  }

  &.is-xl {
    width: var(--size-7xl);
    height: var(--size-7xl);
    font-size: var(--font-md);
  }
}