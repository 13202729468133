@use 'sass:color';

@use 'styles/vars/margins';
@use 'styles/vars/fonts';
@use 'styles/mixins/rounded-borders';
@use 'styles/mixins/shadows';
@use 'styles/mixins/theme';
@use 'styles/mixins/responsive';

.date-slider-selector {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: var(--selector-bg);
  padding: var(--space-md) var(--space-xxl) var(--space-sm);
  @include rounded-borders.border-radius();

  @mixin slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    border: none;
    outline: var(--b1);
    height: 18px;
    width: 5px;
    background-color: var(--text-default);
    @include rounded-borders.border-radius();
  }

  input[type='range'] {
    position: relative;
    width: 100%;
    height: 6px;
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    cursor: pointer;
    transition: opacity 200ms;
    background-color: var(--slider-background);
    z-index: 2;

    &:hover {
      opacity: 1;
    }

    // These cannot be combined, but I don't know why
    &::-webkit-slider-thumb {
      @include slider-thumb;
    }
    &::-moz-range-thumb {
      @include slider-thumb;
    }
  }

  .datalist {
    display: flex;
    align-items: center;
    z-index: 1;
    margin-left: 2px;
    margin-right: 2px;

    .datalist-option {
      cursor: pointer;
      position: relative;
      flex: 1;
      padding-top: 10px;
      height: 2em;
      overflow: visible;
      user-select: none;

      .datalist-option-tick {
        position: absolute;
        top: 0;
        left: 50%;
        height: 8px;
        width: 1px;
        background-color: var(--slider-background);
      }

      .datalist-option-text {
        position: absolute;
        top: 10px;
        left: 0;
        transform: translateX(-50%);
        font-size: 0.8em;
        padding: 2px;
        line-height: 1;
        color: var(--text-default);
        @include rounded-borders.border-radius();
      }

      @include responsive.below(responsive.$re-analysis-monitor-1680) {
        .datalist-option-text {
          display: none;
        }

        &:first-child,
        &:last-child,
        &.is-selected,
        &.is-today {
          .datalist-option-text {
            display: block !important;
          }
        }
      }

      &.is-hidden {
        .datalist-option-text {
          display: none;
        }
      }

      &.is-selected {
        .datalist-option-text {
          display: block;
          font-size: 1em;
          font-weight: var(--fond-bold);
          top: 10px;
          color: var(--text-default);
        }
      }

      &.is-today {
        .datalist-option-text {
          color: var(--text-brand);
          font-weight: var(--fond-bold);
        }
      }
    }
  }

  .forecast-background {
    position: absolute;
    z-index: 0;
    left: 50%;
    right: var(--space-xs);
    top: var(--space-xs);
    bottom: var(--space-xs);
    background: repeating-linear-gradient(
      -45deg,
      var(--slider-forecast-background-1),
      var(--slider-forecast-background-1) 7px,
      var(--slider-forecast-background-2) 7px,
      var(--slider-forecast-background-2) 14px
    );
    @include rounded-borders.border-radius();
  }

  $slider-background: var(--selector-accent);

  & {
    --slider-background: #{$slider-background};
    --slider-forecast-background-1: var(--neutral-800);
    --slider-forecast-background-2: var(--neutral-700);

    @include theme.variant(light) {
      --slider-background: #{$slider-background};
      --slider-forecast-background-1: var(--neutral-50);
      --slider-forecast-background-2: var(--neutral-25);
    }
  }
}
