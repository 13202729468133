@use 'styles/vars/margins';
@use 'styles/mixins/theme';
@use 'styles/mixins/rounded-borders';

.outage-map-chart {
  .chart-loading {
    height: 125px;
    background-color: var(--bg-subtle);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-default);
    margin: margins.$margin-small4 0;
    @include rounded-borders.border-radius();
  }
}
