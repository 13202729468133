@use 'styles/vars/fonts';
@use 'styles/vars/margins';
@use 'styles/mixins/rounded-borders';
@use 'styles/mixins/shadows';
@use 'styles/mixins/responsive';
@use 'styles/mixins/scrollbars';

.colored-europe-map-legend {
  display: flex;
  margin-top: margins.$margin-default;
  overflow: hidden;

  .spot-map-legend-item {
    flex: 1;

    &:first-child .spot-map-legend-item-color {
      @include rounded-borders.border-radius-left();
    }

    &:last-child .spot-map-legend-item-color {
      @include rounded-borders.border-radius-right();
    }

    .spot-map-legend-item-color {
      height: 1em;
    }

    .spot-map-legend-item-label {
      text-align: center;
      margin: margins.$margin-small3 margins.$margin-small3 0;
      font-size: 0.65em;
      white-space: nowrap;
      color: var(--text-default);
    }

    @include responsive.below-tablet {
      .spot-map-legend-item-label {
        display: none;
      }

      &:first-child,
      &:last-child {
        .spot-map-legend-item-label {
          display: block;
        }
      }
    }
  }
}

.colored-europe-map-description {
  display: flex;
  align-items: center;
  gap: 0.5em 1.5em;
  border-top: 1px solid var(--border-subtle);
  margin-top: 0.5em;
  padding-top: 0.5em;
  max-width: 100%;
  overflow-x: auto;
  @include scrollbars.simple();

  .description-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.85em;

    .description-item-icon {
      width: 1rem;
      height: 1rem;
      @include rounded-borders.border-radius();

      &.is-blocked {
        background: var(--map-others-color)
          repeating-linear-gradient(
            -45deg,
            var(--map-others-color),
            var(--map-others-color) 4px,
            var(--bg-default) 4px,
            var(--bg-default) 7px
          );
      }

      &.is-na {
        background-color: var(--map-others-color);
      }
    }

    .description-item-text {
      white-space: nowrap;
    }
  }

  @include responsive.below-desktop {
    gap: margins.$margin-small2;
  }
}
