$-legend-symbol-width: 0.75rem;

.simple-legend-percentile-value {
  width: $-legend-symbol-width;

  %triangle {
    width: 0;
    height: 0;
    border-style: solid;
  }

  .triangle-up {
    @extend %triangle;
    border-width: 0 0 5px 12px;
  }

  .triangle-down {
    @extend %triangle;
    border-width: 0 12px 5px 0;
  }

  .line-mid {
    border-bottom: 1px solid;
  }
}
