@use 'styles/vars/margins';
@use 'styles/vars/fonts';

.chart-creator-curves-search {
  margin-bottom: margins.$margin-large2;

  .chart-creator-curves-search-field {
    margin-bottom: margins.$margin-default;
  }

  .chart-creator-curves-search-table {
    //font-size: 0.85em;

    .column-add {
      text-align: center;
      font-size: fonts.$font-larger * 1.2;
      color: var(--text-brand);
      cursor: pointer;
    }
  }
}
