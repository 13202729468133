@use 'styles/vars/fonts';

.webapp-loading {
  user-select: none;
  position: absolute;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: fonts.$font-family-narrow;
}
