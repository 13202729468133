@use 'styles/vars/margins';

.not-found-page {
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.75;

  > p {
    margin: margins.$margin-small2;
  }

  .button-wrapper {
    display: flex;
    gap: margins.$margin-default;

    > a {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
