@use 'styles/vars/fonts';
@use 'styles/vars/palette';
@use 'styles/vars/margins';
@use 'styles/mixins/theme';
@use 'styles/mixins/rounded-borders';
@use 'styles/mixins/shadows';

main.settings-page {
  display: block;

  //background: var(--bg1);

  padding: var(--space-xl);
  flex-grow: 1;

  box-shadow: var(--shadow-lg);
  @include rounded-borders.border-radius;

  > :first-child {
    margin-top: 0;
  }
  > :last-child {
    margin-bottom: 0;
  }
  .settings-secret-wrapper {
    display: flex;
    align-items: center;
    gap: var(--space-lg);
  }

  pre.settings-secret {
    padding: var(--space-lg);

    background-color: var(--bg-card);
    box-shadow: var(--shadow-lg);
    @include rounded-borders.border-radius;
  }

  #settings-secret-copy {
    display: flex;
    gap: var(--space-sm);
    align-items: center;

    svg {
      transform: scaleX(-1);
      cursor: pointer;
      path.settings-secret-copy-box {
        stroke: var(--text-subtle);
        stroke-width: 1.5;
        fill: none;
      }
    }

    #settings-secret-copy-confirmed {
      font-size: var(--font-xxs);
      color: var(--text-success);
      visibility: hidden;
      opacity: 0;
      transition: opacity 150ms;
      &.is-visible {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
