@use 'styles/vars/margins';

.live-overview-events-card {
  .is-waiting {
    margin: margins.$margin-small2;
    padding: 2rem;
    text-align: center;

    p {
      animation: pulsing 3s infinite;
      color: var(--text-subtle);
    }

    @keyframes pulsing {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0.3;
      }
      100% {
        opacity: 1;
      }
    }
  }
}
