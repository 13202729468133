@use 'styles/vars/margins';
@use 'styles/vars/fonts';

.curve-chart-filter {
  display: flex;
  flex-direction: column;
  gap: margins.$margin-small1;

  .curve-chart-filter-label {
    height: 1em;
    position: relative;

    .curve-chart-filter-label-text {
      font-size: fonts.$font-tiny;
      text-transform: uppercase;
    }

    .curve-chart-filter-tooltip {
      position: absolute;
      top: 0;
      right: 0;
      transform: translateY(-25%);
      z-index: 1;
    }
  }

  .curve-chart-filter-content {
    display: flex;
    gap: margins.$margin-small3;
    align-items: center;

    // @see styles/components/selectors/react-select
    .eq-react-select__control {
      min-width: 90px;
    }
  }
}
