@use 'sass:color';

@use 'styles/vars/zindex';
@use 'styles/vars/margins';
@use 'styles/vars/palette';
@use 'styles/vars/colors';
@use 'styles/mixins/theme';

.subscription-message-bars {
  position: relative;
  z-index: zindex.$z-index-nav-menu + 1;
}

.trial-message-bar {
  display: flex;
  align-items: center;
  background-color: var(--bg-brand);
  color: var(--text-on-brand);
  transition: background-color 150ms;
  padding: var(--space-md);
  gap: var(--space-md);

  .message-bar-icon {
    width: 1.5rem;
    text-align: center;
    font-size: 1.75rem;
    line-height: 0;
  }

  .message-bar-content {
    flex: 1;
  }

  button {
    border-color: var(--text-on-brand) !important;
    color: var(--text-on-brand) !important;
    white-space: nowrap;

    &:hover {
      border-color: var(--bg-brand) !important;
      color: var(--bg-brand) !important;
      background-color: var(--text-on-brand);
    }
  }

  .message-bar-close {
    font-size: 1.25rem;
    cursor: pointer;
    line-height: 0;
  }
}
