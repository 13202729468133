@use 'sass:color';

@use 'styles/vars/margins';
@use 'styles/vars/palette';
@use 'styles/vars/colors';
@use 'styles/vars/fonts';
@use 'styles/mixins/rounded-borders';
@use 'styles/mixins/theme';
@use 'styles/mixins/responsive';

@mixin variant($variant, $background, $text) {
  &.is-#{$variant} {
    --subscription-message-background-1: #{$background};
    --subscription-message-background-2: #{color.adjust(
        $background,
        $lightness: 0.85%,
        $space: hsl
      )};
    --subscription-message-countdown-border: #{color.adjust(
        $background,
        $lightness: -3%,
        $space: hsl
      )};
    --subscription-message-text: #{$text};
    --subscription-message-text-shadow: #{color.adjust(
        $background,
        $lightness: -10%,
        $space: hsl
      )};
  }
}

.message-subscription {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  color: var(--subscription-message-text);
  text-shadow: 0 0 2px var(--subscription-message-text-shadow);
  background: repeating-linear-gradient(
    45deg,
    var(--subscription-message-background-1),
    var(--subscription-message-background-1) 10px,
    var(--subscription-message-background-2) 10px,
    var(--subscription-message-background-2) 20px
  );
  @include rounded-borders.border-radius();

  .message-content {
    padding: var(--space-lg) var(--space-xxl);

    > span {
      display: block;
      font-size: var(--font-sm);
      font-weight: var(--font-bold);
      margin-bottom: var(--space-xs);
    }

    > p {
      margin: 0;
    }

    a {
      color: inherit;
      text-decoration-color: inherit;

      &:hover,
      &:active,
      &:visited {
        color: inherit;
        text-decoration-color: inherit;
      }
    }
  }

  .message-button {
    padding: 0 var(--space-xxl);

    button {
      border-color: inherit !important;
      color: inherit !important;
      opacity: 0.8;

      &:hover {
        border-color: inherit !important;
        color: inherit !important;
        opacity: 1;
      }
    }
  }

  .message-countdown {
    padding: var(--space-xl) var(--space-xxl);
    background-color: var(--subscription-message-background-1);
    border-left: 1px solid var(--subscription-message-countdown-border);

    .message-countdown-label {
      display: block;
      margin-bottom: var(--space-sm);
      font-size: var(--font-xxs);
      text-transform: uppercase;
    }
  }

  @include responsive.below-desktop() {
    flex-direction: column;
    align-items: unset;

    .message-content,
    .message-countdown,
    .message-button {
      padding: var(--space-lg);
    }

    .message-countdown {
      border-left: none;
      border-top: 1px solid var(--subscription-message-countdown-border);
    }
  }

  @include variant(trial, palette.$color-green-4, colors.$color-offwhite);
  @include variant(
    freemium,
    color.adjust(palette.$color-grey-2, $lightness: -2%, $space: hsl),
    colors.$color-offwhite
  );

  @include theme.variant(light) {
    @include variant(freemium, palette.$color-grey-3, colors.$color-offwhite);
  }
}

.subscription-blocked {
  max-width: 500px;
  text-align: center;
  margin: 5rem auto 0 auto;
}
