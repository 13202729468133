@use 'styles/vars/palette';
@use 'styles/mixins/theme';

.planned-indicator-wrapper {
  width: 24px;

  .planned-indicator {
    display: block;
    width: 24px;
    height: 24px;
    border-radius: var(--radius-full);
    text-align: center;
    color: var(--text-default);
    font-weight: var(--font-semibold);
    font-size: var(--font-xxs);
    line-height: 24px;

    &.planned {
      background-color: var(--bg-success);
      color: var(--text-on-success);
    }

    &.unplanned {
      background-color: var(--bg-error);
      color: var(--text-on-error);
    }

    &.cancelled {
      background-color: var(--bg-subtle);
    }

    &.information {
      background-color: var(--bg-warning);
      color: var(--text-on-warning);
    }
  }
}
