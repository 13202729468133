@use 'styles/vars/margins';
@use 'styles/vars/zindex';
@use 'styles/vars/fonts';
@use 'styles/mixins/rounded-borders';

.dropdown-selector {
  .select-input {
    display: flex;
    align-items: center;
    position: relative;
    padding-right: margins.$margin-large4;
    width: 100%;
    height: 100%;

    .select-input-icon {
      user-select: none;
      display: block;
      position: absolute;

      right: margins.$margin-default;
      top: 50%;
      margin-top: -0.25em;

      width: 1em;
      height: 0.5em;
      background-color: var(--text-default);
      clip-path: polygon(0 15%, 50% 100%, 100% 15%, 100% 0, 50% 85%, 0 0);
    }

    .dropdown-selector-text {
      padding: 0 0.5rem;
      font-weight: var(--font-medium);
      text-decoration: none;
      cursor: pointer;
      color: var(--text-default);
    }
  }

  .dropdown-selector-card-wrapper {
    position: absolute;
    top: calc(100% + #{margins.$margin-small3});
    left: -1px;
    right: -1px;

    user-select: none;
    z-index: zindex.$z-index-element-mouseovers;

    .dropdown-selector-card {
      display: block;
      padding: 0;
      overflow-y: auto;
      box-shadow: var(--shadow-lg);
      background-color: var(--bg-card);
      @include rounded-borders.border-radius;

      .dropdown-item {
        padding: margins.$margin-default;

        font-size: fonts.$font-normal;
        font-weight: fonts.$font-weight-medium;
        white-space: nowrap;

        &:hover {
          background: var(--selector-accent);
        }

        &.is-disabled {
          opacity: 0.45;
          cursor: default;
        }
      }
    }
  }
}
