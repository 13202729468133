@use 'styles/vars/margins';
@use 'styles/vars/fonts';

.curve-chart-options-option {
  display: flex;
  flex-direction: column;
  gap: margins.$margin-small2;
  flex-shrink: 0;

  &.is-wrapping {
    flex-shrink: 1;
    flex-wrap: wrap;
  }

  .option-label {
    display: flex;
    align-items: center;
    gap: margins.$margin-small3;
    font-size: fonts.$font-small;
  }

  .option-icon {
    font-size: fonts.$font-large;
    line-height: 0;
  }

  .option-content {
    flex-wrap: wrap;

    > span {
      font-size: fonts.$font-small;
      display: inline-block;
      margin-top: 0.2rem;
    }
  }
}
