@use 'styles/vars/margins';
@use 'styles/vars/fonts';
@use 'styles/mixins/responsive';
@use 'styles/mixins/theme';

.calculator-custom-input {
  background-color: var(--card-bg);

  & {
    --card-bg: var(--bg-main-container);

    @include theme.variant(light) {
      --card-bg: var(--neutral-100);
    }
  }

  .custom-input-data {
    display: flex;

    .input-data-section {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: margins.$margin-large2 margins.$margin-large2 0 margins.$margin-large2;
      gap: margins.$margin-default;

      .section-header {
        display: flex;
        gap: margins.$margin-small2;
        align-items: center;
        justify-content: center;

        .section-header-number {
          background-color: var(--bg-inverse);
          color: var(--text-on-inverse);
          width: 1.5em;
          height: 1.5em;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
        }

        .section-header-text {
          font-weight: fonts.$font-weight-bold;
        }
      }

      .section-content {
      }
    }

    .custom-input-upload {
      width: 25%;
      flex: none;
      border-right: 1px dashed var(--border-subtle);

      .section-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    .custom-input-textarea {
      flex: 1;
      border-right: 1px dashed var(--border-subtle);

      .section-content {
        height: 300px;
        overflow-y: auto;
      }
    }

    .custom-input-summary {
      width: 40%;
      flex: none;

      .section-content {
        position: relative;

        .section-content-overlay {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: var(--card-bg);
          opacity: 0.8;
          font-weight: fonts.$font-weight-bold;
        }
      }

      .summary-items {
        display: flex;
        flex-wrap: wrap;
        gap: margins.$margin-small0 margins.$margin-large1;

        .summary-item {
          .summary-item-label {
            font-size: 0.75em;
            font-weight: fonts.$font-weight-bold;
            text-transform: uppercase;
            margin-bottom: margins.$margin-small2;
            color: var(--text-subtle);
          }
        }
      }

      .custom-input-summary-fill {
        display: flex;
        align-items: center;
        gap: margins.$margin-small2;

        > input {
          width: 50px;
        }
      }
    }

    @include responsive.below-desktop {
      flex-direction: column;

      .input-data-section {
        padding: margins.$margin-default;
      }

      .custom-input-upload,
      .custom-input-summary {
        width: 100%;
      }

      .custom-input-upload,
      .custom-input-textarea {
        border-right: none;
        border-bottom: 1px dashed var(--border-subtle);
      }

      .custom-input-textarea {
        .section-content {
          height: 200px;
        }
      }
    }
  }

  .custom-input-submit {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: margins.$margin-default;
    margin: margins.$margin-default;

    .custom-input-error {
      .alert {
        margin: 0;
      }
    }

    @include responsive.below-desktop {
      flex-direction: column;
    }
  }
}
