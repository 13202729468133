@use 'styles/vars/margins';
@use 'styles/mixins/responsive';
@use 'styles/mixins/rounded-borders';
@use 'styles/vars/palette';
@use 'styles/vars/selectors';
@use 'styles/vars/fonts';

.archive-bar {
  position: relative;

  padding: var(--space-md) var(--space-lg);
  margin: var(--space-md) 0;

  border: 2px solid var(--purple);
  color: var(--text-default);
  background-color: var(--bg-main-container);

  @include rounded-borders.border-radius();

  @include responsive.below-tablet() {
    padding: var(--space-md);
  }

  > .exit-button {
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;

    top: var(--space-sm);
    right: var(--space-sm);
    width: var(--size-xxl);
    height: var(--size-xxl);

    background-color: var(--bg-secondary);
    border-radius: 50%;

    cursor: pointer;

    &:hover {
      background-color: var(--bg-hover);
    }

    @include responsive.above(responsive.$breakpoint-mobile-1) {
      display: none;
    }
  }

  > * {
    margin: 0;
  }

  a,
  a:link {
    color: var(--text-default);
    text-decoration: none;
  }

  > .archive-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--space-sm);
    width: 100%;

    @include responsive.below-tablet() {
      flex-direction: column;
    }

    > .archive-control-item-wrapper {
      flex: 1;
      display: flex;
      align-items: center;
      gap: var(--space-sm);

      > .title-icon {
        line-height: 0;

        > svg {
          width: 1.25em;
          height: 1.25em;
        }
      }

      &.hide-on-phone {
        @include responsive.below-tablet() {
          display: none;
        }
      }

      > .exit-button-float-right {
        margin-left: auto;
        margin-right: 0;
        order: 2;
      }
    }

    > .button-wrapper {
      display: flex;

      > .day-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 var(--space-sm);
        border: 1px solid var(--border-default);

        @include rounded-borders.border-radius;

        > p {
          min-width: 140px;
          margin: 0;
        }

        > .day-arrow {
          display: flex;
          justify-content: center;
          align-items: center;
          height: var(--size-xl);
          width: var(--size-xl);
          border-radius: var(--radius-full);
          cursor: pointer;

          &.is-disabled {
            opacity: 0.3;
            cursor: not-allowed;
          }

          &:hover {
            background-color: var(--bg-hover);
          }
        }
      }

      @include responsive.below-tablet() {
        display: grid;
        grid-row-gap: margins.$margin-small2;
        grid-template-columns: 1fr 1fr;

        > .day-wrapper {
          min-height: var(--size-3xl);
        }

        > * {
          &:nth-child(1) {
            order: 2;
            grid-column: 1/2;
            align-self: start;
            margin-right: margins.$margin-small2;
            margin-left: 0;
          }

          &:nth-child(2) {
            order: 1;
            grid-column: 1/3;
          }

          &:nth-child(3) {
            order: 3;
            grid-column: 2/3;
            margin-left: margins.$margin-small2;
            margin-right: 0;
          }
        }
      }
    }

    .archive-control-item {
      min-width: 110px;
      margin: 0 var(--space-md);

      &.disabled {
        cursor: not-allowed;
      }

      > .archive-control-item-label {
        font-weight: var(--font-bold);
        font-size: smaller;
        opacity: 0.5;
      }

      &.is-selected {
        background: transparent;
        cursor: default;

        margin: 0 var(--space-lg);
      }

      &.is-disabled {
        opacity: 0.3;
        cursor: not-allowed;
      }
    }
  }
}
