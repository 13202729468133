@use 'styles/vars/margins';

.flex {
  display: flex;

  &-inline {
    display: inline-flex !important;
  }

  &-vertical {
    flex-direction: column;
  }

  &-center {
    align-items: center;
  }

  @each $name, $size in margins.$margins {
    &-gap-#{$name} {
      gap: $size;
    }
  }

  &-space-between {
    justify-content: space-between;
  }

  &-justify-center {
    justify-content: center;
  }

  &-justify-end {
    justify-content: flex-end;
  }
}

@each $name, $value in margins.$margins {
  .gap-#{$name} {
    gap: $value;
  }
}

.valign-top {
  vertical-align: top;
}

.valign-middle {
  vertical-align: middle;
}

@for $rotation from 0 through 360 {
  .rotate-#{$rotation} {
    transform: rotate(#{$rotation}deg);
  }
}
