@use 'styles/mixins/rounded-borders';
@use 'styles/vars/palette';

@forward 'symbols/LegendSymbolBar/LegendSymbolBar';
@forward 'symbols/LegendSymbolLine/LegendSymbolLine';
@forward 'symbols/LegendSymbolArea/LegendSymbolArea';
@forward 'symbols/LegendSymbolValue/LegendSymbolValue';
@forward 'symbols/LegendSymbolPercentileValue/LegendSymbolPercentileValue';
@forward 'symbols/LegendSymbolMinMaxValue/LegendSymbolMinMaxValue';
@forward 'symbols/LegendSymbolScenarioValue/LegendSymbolScenarioValue';

.u-select {
  background: var(--bg-selected) !important;
}

.u-cursor-x {
  border-right: 1px solid var(--text-default) !important;
}

.u-cursor-y {
  border-bottom: 1px dashed var(--text-default) !important;
}

.simple-legend {
  .legend-label {
    margin: 10px 0;
  }

  .legend-table {
    width: 100%;
    margin-top: 10px;

    .legend-table-symbol {
      padding-right: 0.5rem;
    }

    .legend-table-name {
      width: 100%;
    }

    .legend-table-value {
      padding-left: 0.5rem;
      text-align: right;
    }
  }
}
