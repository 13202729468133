.empty-checker {
  .empty-checker-fallback {
    display: none !important;
  }

  [data-empty-checker='ignore'] {
    display: block;
  }

  [data-empty-checker='empty'] {
    display: none;
  }

  &[data-empty-checker='empty'] {
    .empty-checker-fallback {
      display: block !important;
    }

    [data-empty-checker='ignore'] {
      display: none;
    }
  }
}
