@use 'sass:map';
@use 'sass:list';
@use 'sass:math';
@use 'styles/vars/breakpoints';
@use 'styles/utils/list' as list-utils;

@forward 'styles/vars/breakpoints';

@mixin below($breakpoint) {
  @media (max-width: $breakpoint) {
    @content;
  }
}

@mixin above($breakpoint) {
  @media (min-width: $breakpoint) {
    @content;
  }
}

@mixin between($min, $max) {
  @media (min-width: $min) and (max-width: $max) {
    @content;
  }
}

@mixin below-desktop() {
  @include below(breakpoints.$breakpoint-tablet) {
    @content;
  }
}

@mixin below-tablet() {
  @include below(breakpoints.$breakpoint-mobile) {
    @content;
  }
}

@mixin above-mobile() {
  @include above(breakpoints.$breakpoint-mobile) {
    @content;
  }
}

@mixin above-tablet() {
  @include above(breakpoints.$breakpoint-tablet) {
    @content;
  }
}

@mixin analysis($width-map) {
  & {
    width: map.get($width-map, 4k);
  }

  $breakpoints-map: breakpoints.$re-analysis-monitor-map;
  $breakpoints: list-utils.reverse(map.keys($breakpoints-map));
  $breakpoints-count: list.length($breakpoints);

  @for $i from 2 through $breakpoints-count {
    $breakpoint: list.nth($breakpoints, $i);
    $breakpoint-next: list.nth($breakpoints, math.min($i + 1, $breakpoints-count));

    $max-width: map.get($breakpoints-map, $breakpoint);
    $width: map.get($width-map, $breakpoint-next);

    @media (max-width: $max-width) {
      // Last (smallest) breakpoint is a special case
      @if $i == $breakpoints-count {
        width: 100%;
        display: block;
        overflow-x: hidden;
      } @else {
        width: $width;
      }
    }
  }
}
