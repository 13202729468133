@use 'styles/vars/margins';
@use 'styles/vars/fonts';
@use 'styles/vars/zindex';
@use 'styles/mixins/responsive';
@use 'styles/mixins/theme';
@use 'styles/mixins/rounded-borders';
@use 'styles/mixins/shadows';

.outages-message-wrapper {
  max-width: responsive.$re-analysis-full-1920;
  padding: 0 margins.$margin-default;
  margin: 0 auto;

  @include responsive.below-tablet() {
    padding: margins.$margin-small2;
  }
}

.outage-single-message {
  .outage-message-chart {
    margin: margins.$margin-large0 0;
  }

  .outage-message-columns {
    display: flex;
    align-items: center;
    gap: margins.$margin-large5;

    .column {
      .column-label {
        text-transform: uppercase;
        font-size: fonts.$font-small;
        color: var(--text-subtle);
        margin-bottom: margins.$margin-small3;
      }
    }

    @include responsive.below-tablet {
      &.is-responsive {
        gap: margins.$margin-small2;
        flex-direction: column;
        align-items: flex-start;

        .column {
          display: flex;
          align-items: center;

          .column-label {
            margin-bottom: 0;
            margin-right: margins.$margin-small2;
          }
        }
      }
    }
  }

  .toggle-button {
    display: flex;
    justify-content: center;
  }

  .older-versions {
    .older-versions-list {
      position: relative;
      max-height: 300px;
      overflow: hidden;
      opacity: var(--old-versions-opacity);

      .older-versions-gradient {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 80%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, var(--bg-page-body) 100%);
      }

      .outage-message-card {
        margin-bottom: margins.$margin-large2;

        .big-card {
          margin: 0;
        }
      }
    }

    &.is-open {
      .older-versions-list {
        max-height: none;
        opacity: 1;

        .older-versions-gradient {
          display: none;
        }
      }
    }
  }

  .outage-message-card {
    .outage-message-card-remark {
      margin-top: margins.$margin-large2;
    }
  }

  & {
    --old-versions-opacity: 0.5;

    @include theme.variant(light) {
      --old-versions-opacity: 0.65;
    }
  }
}
