@use 'sass:color';

@use 'styles/vars/fonts';
@use 'styles/vars/colors';
@use 'styles/vars/palette';
@use 'styles/mixins/theme';
@use 'styles/mixins/rounded-borders';

//
// Styles for inputs, textarea, select.
//
// Also contains specific overrides each component.
//

// Default styles

input[type='text'],
input[type='password'],
input[type='email'],
input[type='tel'],
input[type='date'],
input[type='datetime-local'],
input[type='number'],
input[type='time'],
input[type='url'],
input[type='color'],
textarea,
select {
  font: var(--body-2);
  color: var(--text-default);
  outline: 0 !important;
  border: 1px solid var(--border-default);
  background-color: var(--transparent);
  padding: var(--space-sm) var(--space-md);
  @include rounded-borders.border-radius(var(--radius-sm));

  &::placeholder {
    font: var(--placeholder-2);
    opacity: 0.5;
  }

  &::-webkit-calendar-picker-indicator {
    opacity: 0.4;
  }

  &:focus,
  &:active,
  &:hover {
    border-color: var(--border-hover);
  }

  &:-webkit-autofill,
  &:autofill {
    border-color: var(--border-hover);
    background-color: var(--transparent);
  }

  &:disabled {
    border-color: var(--border-disabled);
    color: var(--text-disabled);

    user-select: none;
    cursor: not-allowed;

    &:focus,
    &:active {
      border-color: var(--border-disabled) !important;
    }
  }

  @include theme.variant(dark) {
    // Automatically make native popups (like for date inputs) dark
    color-scheme: dark;
  }
}

// Overrides for select. Note: div.select-input-icon MUST be placed
// after <select> for focus to work.

// Usage:
//  <div class="select">
//    <select> ... </select>
//    <div class="select-input-icon"></div>
//  </div>

select {
  background: var(--selector-bg);

  > option {
    line-height: var(--line-tallest);
    font: var(--body-2);
  }

  &:not([multiple]) {
    appearance: none;
    overflow: auto;
  }

  &[multiple] {
    appearance: none;
    overflow-y: auto;
    padding: 0;

    & > option {
      padding: var(--space-sm) var(--space-md);
    }
  }
}

// Overrides for number-inputs

input[type='number'] {
  text-align: left;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin-right: 0;
  }
}

// Overrides for color-inputs
input[type='color'] {
  padding: 0;
  min-height: 32px;
  background: transparent;
  border: transparent;
  vertical-align: middle;

  &::-webkit-color-swatch {
    border: 0;
    @include rounded-borders.border-radius();
  }

  &::-moz-color-swatch {
    border: 0;
    @include rounded-borders.border-radius();
  }
}

// Override for textarea

textarea {
  line-height: var(--line-tallest);
}

// Overrides for checkboxes and radio buttons

input[type='radio'] {
  appearance: none;
  position: relative;
  width: var(--size-lg);
  height: var(--size-lg);
  border: 1px solid var(--icon-default);
  background-color: var(--transparent);
  color: var(--icon-default);
  border-radius: var(--radius-full);
  cursor: pointer;

  &::before {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: var(--radius-full);
    width: var(--size-sm);
    height: var(--size-sm);
    content: ' ';
  }

  &:checked::before {
    background-color: var(--icon-default);
  }

  &:disabled {
    border-color: var(--border-disabled);

    &:checked:before {
      background-color: var(--bg-disabled);
    }
  }
}

input[type='checkbox'] {
  appearance: none;
  position: relative;
  width: var(--size-lg);
  height: var(--size-lg);
  border: 1px solid var(--icon-default);
  background-color: var(--transparent);
  color: var(--icon-default);
  border-radius: var(--radius-sm);
  cursor: pointer;

  &::before {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: var(--radius-sm);
    color: var(--text-on-inverse);
    width: var(--size-lg);
    height: var(--size-lg);
    content: ' ';
  }

  &:checked::before {
    background-color: var(--icon-default);
    line-height: 1.2;
    text-align: center;
    content: '\2714';
  }

  &:disabled {
    border-color: var(--border-disabled);

    &:checked:before {
      background-color: var(--bg-disabled);
    }
  }
}
