@use 'styles/vars/margins';
@use 'styles/vars/fonts';
@use 'styles/mixins/responsive';
@use 'styles/mixins/theme';

.calculator-presets-card {
  display: grid;
  grid-template-columns: 250px 200px auto 1fr;
  grid-template-rows: auto;
  align-items: center;
  gap: margins.$margin-small2 margins.$margin-default;
  padding: margins.$margin-default;
  background-color: var(--card-bg);

  .inputs-header {
    font-weight: fonts.$font-weight-bold;
    padding: margins.$margin-small4 0;
  }

  .inputs-col {
    white-space: nowrap;

    label {
      display: none;
    }

    button,
    .button-popover {
      width: 100%;
    }
  }

  .inputs-divider {
    grid-column: span 4;
    border-top: 1px dashed var(--border-subtle);
  }

  @include responsive.below-tablet {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;

    .inputs-header,
    .inputs-divider {
      display: none;
    }

    .inputs-col {
      label {
        display: block;
        margin: 0 0 margins.$margin-small2 0;
      }
    }
  }

  & {
    --card-bg: var(--bg-main-container);

    @include theme.variant(light) {
      --card-bg: var(--neutral-100);
    }
  }
}
