@use 'styles/vars/zindex';
@use 'styles/vars/borders';

.input-group {
  display: flex;

  > * {
    border-radius: 0 !important;
    margin: 0 !important;
    margin-right: -1px !important;

    position: relative;
    z-index: zindex.$z-index-default-siblings;

    &:focus,
    &:hover {
      z-index: zindex.$z-index-above-siblings;
    }

    &:first-child {
      border-top-left-radius: var(--radius-md) !important;
      border-bottom-left-radius: var(--radius-md) !important;
    }

    &:last-child {
      border-top-right-radius: var(--radius-md) !important;
      border-bottom-right-radius: var(--radius-md) !important;
      margin-right: 0 !important;
    }
  }
}
