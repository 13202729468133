@use 'sass:meta';
@use 'styles/mixins/rounded-borders';
@use 'styles/mixins/shadows';
@use 'styles/mixins/theme';

.code-tabs {
  pre,
  code {
    margin: 0;

    &.hljs {
      background-color: transparent !important;
    }
  }

  @include theme.variant(dark) {
    @include meta.load-css('highlight.js/styles/stackoverflow-dark');
  }

  @include theme.variant(light) {
    @include meta.load-css('highlight.js/styles/stackoverflow-light');
  }
}
