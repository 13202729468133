@use 'styles/mixins/theme';

@include theme.variant(light) {
  // Backgrounds
  --bg-page-body: var(--neutral-50);
  --bg-main-container: var(--neutral-white);
  --bg-card: var(--neutral-white);
  --bg-grid: var(--transparent-950-5);
  --bg-dimmed: var(--transparent-950-50);

  --bg-default: var(--neutral-white);
  --bg-subtle: var(--neutral-50);
  --bg-secondary: var(--neutral-200);
  --bg-hover: var(--transparent-950-7);
  --bg-selected: var(--transparent-pine-12);
  --bg-disabled: var(--neutral-100);
  --bg-inverse: var(--neutral-900);
  --bg-light: var(--neutral-50);
  --bg-dark: var(--neutral-900);

  --bg-brand: var(--pine);
  --bg-brand-hover: var(--dark-pine);

  --bg-buy: var(--blue);
  --bg-sell: var(--pink);
  --bg-success: var(--green);
  --bg-warning: var(--dark-orange);
  --bg-error: var(--pink);
  --bg-error-hover: var(--red);
  --bg-info: var(--payne);
  --bg-data-up: var(--light-green);
  --bg-data-down: var(--light-pink);

  --bg-row-maingroup: var(--transparent-950-12);
  --bg-row-subgroup: var(--transparent-950-5);

  // Borders
  --border-card: var(--neutral-100);
  --border-default: var(--neutral-300);
  --border-subtle: var(--neutral-200);
  --border-hover: var(--neutral-500);
  --border-active: var(--neutral-500);
  --border-disabled: var(--neutral-100);
  --border-focus: var(--dark-orange);

  --border-brand: var(--pine);

  --border-success: var(--green);
  --border-warning: var(--dark-orange);
  --border-error: var(--pink);

  --border-graphics-purple: var(--purple);

  // Icons
  --icon-default: var(--neutral-900);
  --icon-subtle: var(--neutral-600);
  --icon-disabled: var(--neutral-400);
  --icon-on-inverse: var(--neutral-50);
  --icon-on-light: var(--neutral-900);
  --icon-on-dark: var(--neutral-50);

  --icon-brand: var(--pine);
  --icon-brand-static: var(--pine);
  --icon-brand-inverse: var(--pine);
  --icon-on-brand: var(--neutral-50);

  --icon-success: var(--green);
  --icon-success-static: var(--light-green);
  --icon-on-success: var(--neutral-50);
  --icon-error: var(--pink);
  --icon-on-error: var(--neutral-50);
  --icon-alert: var(--red);
  --icon-on-info: var(--neutral-50);

  // Texts
  --text-default: var(--neutral-900);
  --text-subtle: var(--neutral-600);
  --text-disabled: var(--neutral-400);
  --text-on-inverse: var(--neutral-50);
  --text-on-light: var(--neutral-900);
  --text-on-dark: var(--neutral-50);
  --text-urls: var(--pine);

  --text-brand: var(--pine);
  --text-brand-inverse: var(--pine);
  --text-on-brand: var(--neutral-50);

  --text-success: var(--green);
  --text-on-success: var(--neutral-50);
  --text-error: var(--pink);
  --text-on-error: var(--neutral-50);
  --text-on-info: var(--neutral-50);
  --text-on-warning: var(--neutral-50);
  --text-data-up: var(--green);
  --text-data-down: var(--pink);

  --text-graphics-purple: var(--purple);
}

@include theme.variant(dark) {
  // Backgrounds
  --bg-page-body: var(--neutral-950);
  --bg-main-container: var(--neutral-900);
  --bg-card: var(--neutral-800);
  --bg-grid: var(--transparent-white-5);
  --bg-dimmed: var(--transparent-950-50);

  --bg-default: var(--neutral-900);
  --bg-subtle: var(--neutral-800);
  --bg-secondary: var(--neutral-700);
  --bg-hover: var(--transparent-white-7);
  --bg-selected: var(--transparent-white-12);
  --bg-disabled: var(--neutral-700);
  --bg-inverse: var(--neutral-50);
  --bg-light: var(--neutral-50);
  --bg-dark: var(--neutral-900);

  --bg-brand: var(--pine);
  --bg-brand-hover: var(--dark-pine);

  --bg-buy: var(--blue);
  --bg-sell: var(--pink);
  --bg-success: var(--light-green);
  --bg-warning: var(--orange);
  --bg-error: var(--pink);
  --bg-error-hover: var(--red);
  --bg-info: var(--payne);
  --bg-data-up: var(--green);
  --bg-data-down: var(--pink);

  --bg-row-maingroup: var(--transparent-white-12);
  --bg-row-subgroup: var(--transparent-white-5);

  // Border
  --border-card: var(--transparent);
  --border-default: var(--neutral-600);
  --border-subtle: var(--neutral-700);
  --border-hover: var(--neutral-300);
  --border-active: var(--neutral-300);
  --border-disabled: var(--neutral-700);
  --border-focus: var(--orange);

  --border-brand: var(--pine);

  --border-success: var(--light-green);
  --border-warning: var(--orange);
  --border-error: var(--light-pink);

  --border-graphics-purple: var(--light-purple);

  // Icons
  --icon-default: var(--neutral-50);
  --icon-subtle: var(--neutral-400);
  --icon-disabled: var(--neutral-600);
  --icon-on-inverse: var(--neutral-900);
  --icon-on-light: var(--neutral-900);
  --icon-on-dark: var(--neutral-50);

  --icon-brand: var(--light-pine);
  --icon-brand-static: var(--pine);
  --icon-brand-inverse: var(--neutral-50);
  --icon-on-brand: var(--neutral-50);

  --icon-success: var(--light-green);
  --icon-success-static: var(--light-green);
  --icon-on-success: var(--neutral-900);
  --icon-error: var(--pink);
  --icon-on-error: var(--neutral-50);
  --icon-alert: var(--red);
  --icon-on-info: var(--neutral-50);

  // Texts
  --text-default: var(--neutral-50);
  --text-subtle: var(--neutral-400);
  --text-disabled: var(--neutral-600);
  --text-on-inverse: var(--neutral-900);
  --text-on-light: var(--neutral-900);
  --text-on-dark: var(--neutral-50);
  --text-urls: var(--light-pine);

  --text-brand: var(--light-pine);
  --text-brand-inverse: var(--neutral-50);
  --text-on-brand: var(--neutral-50);

  --text-success: var(--light-green);
  --text-on-success: var(--neutral-900);
  --text-error: var(--light-pink);
  --text-on-error: var(--neutral-50);
  --text-on-info: var(--neutral-50);
  --text-on-warning: var(--neutral-900);
  --text-data-up: var(--light-green);
  --text-data-down: var(--light-pink);

  --text-graphics-purple: var(--light-purple);
}
