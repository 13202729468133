@use 'styles/vars/fonts';
@use 'styles/vars/margins';
@use 'styles/mixins/scrollbars';
@use 'styles/mixins/rounded-borders';
@use 'styles/mixins/responsive';

.production-donut-chart {
  position: relative;

  .chart-label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    display: flex;
    flex-direction: column;

    .label-name {
      line-height: 1.5;
      color: var(--text-default);
      font-size: fonts.$font-small;
      margin-bottom: margins.$margin-default;
    }

    .label-value {
      color: var(--text-default);
      font-size: fonts.$font-title;
      font-weight: fonts.$font-weight-bold;
      margin-bottom: margins.$margin-small0;
    }

    .label-unit {
      color: var(--text-subtle);
    }

    @include responsive.below-desktop() {
      .label-value {
        font-size: fonts.$font-normal;
      }
    }
  }

  .recharts-layer {
    cursor: pointer;

    g,
    path {
      outline: none;
    }
  }
}

.production-donut-table {
  max-height: 150px;
  overflow-y: scroll;
  margin-top: margins.$margin-default;
  @include scrollbars.simple();

  table {
    border-collapse: separate;
    border-spacing: 0;

    thead {
      tr {
        position: sticky;
        top: 0;
        background: var(--data-table-row-odd);
        border-bottom: 0 !important;
        scroll-margin-top: 1rem;

        th {
          border-bottom: 3px solid var(--data-table-separator) !important;
        }
      }
    }

    tbody {
      tr {
        cursor: pointer;
      }
    }
  }

  .table-color {
    width: 0.8em;
    height: 0.8em;
    @include rounded-borders.border-radius();
  }
}
