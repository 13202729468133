@use 'styles/vars/margins';
@use 'styles/mixins/rounded-borders';
@use 'styles/mixins/shadows';
@use 'styles/mixins/theme';
@use 'styles/vars/colors';
@use 'styles/vars/palette';
@use 'styles/mixins/responsive';

.big-card {
  width: 100%;
  max-width: responsive.$breakpoint-monitor-1920;
  margin: margins.$margin-large2 0;

  background-color: var(--bg-main-container);
  border-radius: var(--radius-lg);
  overflow: hidden;

  > h2,
  > h3 {
    margin-top: 0;
    margin-bottom: margins.$margin-small3;
  }

  .big-card-header {
    width: 100%;
    padding: margins.$margin-large2;
    background-color: var(--neutral-800);

    @include theme.variant(light) {
      background-color: var(--neutral-100);
    }

    h2 {
      padding: 0;
      margin: 0;
    }

    h3 {
      padding: 0;
      margin: margins.$margin-small2 0 0 0;
    }
  }

  .big-card-content {
    padding: margins.$margin-large2;

    > :first-child {
      margin-top: 0;
    }

    > :last-child {
      margin-bottom: 0;
    }

    &.size-default {
      height: 250px;
    }

    &.size-xs {
      height: 100px;
    }

    &.size-small {
      height: 150px;
    }

    &.size-large {
      height: 375px;
    }

    &.size-mega {
      height: 550px;
    }
  }
}
