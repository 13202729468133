@use 'styles/vars/margins';
@use 'styles/mixins/responsive';

.power-overview-live-page {
  .push-hero {
    padding: margins.$margin-default;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .push-hero-content {
      position: relative;
      width: 65%;

      .push-hero-icon {
        position: absolute;
        top: -2rem;
        left: -6rem;
        opacity: 0.2;
        font-size: 12rem;
        color: var(--bg-subtle);
        z-index: -1;
      }

      h3 {
        margin-top: 0;
      }

      p {
        margin-bottom: 0;
      }

      @include responsive.below-desktop {
        width: 100%;

        .push-hero-icon {
          top: 0;
          left: 0;
          font-size: 4rem;
        }
      }
    }
  }
}
