@use 'styles/vars/margins';
@use 'styles/vars/palette';
@use 'styles/vars/fonts';
@use 'styles/vars/zindex';
@use 'styles/mixins/rounded-borders';
@use 'styles/mixins/shadows';
@use 'styles/mixins/theme';
@use 'styles/mixins/floating-ui';
@use 'styles/mixins/responsive';

.emission-profile-selector {
  .emission-profile-selector-button {
    min-width: 220px;
  }
}

.emission-profile-selector-popover {
  display: flex;
  gap: var(--space-lg);
  width: 800px;

  .popover-profiles {
    width: 250px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }

  .popover-description {
    flex: 1;

    h4 {
      margin-top: 0;
    }

    p {
      margin: 0 0 var(--space-lg) 0;
    }
  }

  .emission-profile-option-card {
    cursor: pointer;
    margin-bottom: var(--space-xxs);
    padding: var(--space-sm) var(--space-md);
    border-radius: var(--radius-md);

    &:hover {
      background-color: var(--bg-hover);
    }

    &.is-selected {
      background-color: var(--bg-brand);
      color: var(--text-on-brand);
    }
  }

  @include responsive.below-desktop {
    width: auto;

    .popover-profiles {
      display: block;
      width: 100%;
    }

    .popover-description {
      display: none;
    }
  }
}
