@use 'styles/vars/margins';
@use 'styles/vars/fonts';
@use 'styles/mixins/rounded-borders';
@use 'styles/mixins/shadows';
@use 'styles/mixins/responsive';

.curve-push-feed-list {
  .curve-push-feed-list-info {
    display: flex;
    flex-basis: 50%;
    gap: margins.$margin-large5;
    margin: margins.$margin-large3 0;

    .info-column {
      display: flex;
      gap: margins.$margin-large0;
      align-items: center;

      .info-column-icon {
        font-size: 4em;
        line-height: 0;
        opacity: 0.5;

        svg {
          width: 1em;
          height: 1em;
        }
      }

      .info-column-content {
        h2 {
          margin-top: 0;
          font-size: fonts.$font-large;
        }
      }
    }

    @include responsive.below-tablet {
      flex-direction: column;
      gap: margins.$margin-large2;
      margin: margins.$margin-large2 0;

      .info-column .info-column-icon {
        font-size: 3em;
      }
    }
  }

  .curve-push-feed-list-stats {
    display: grid;
    grid-template-columns: auto 400px;
    grid-template-rows: auto;
    margin: margins.$margin-large4 0;

    .stats-label {
      text-transform: uppercase;
      color: var(--text-subtle);
      font-size: fonts.$font-small;
      text-align: center;
      margin-bottom: margins.$margin-default;
    }

    .stats-left {
      overflow: hidden;
    }

    .stats-right {
      margin-left: margins.$margin-large4;
      padding-left: margins.$margin-large4;
      //border-left: 1px solid var(--border-subtle);

      .stats-counter {
        text-align: center;
        font-size: 3.5rem;
        padding: margins.$margin-large3 0;
      }
    }

    @include responsive.below-tablet {
      grid-template-columns: auto;

      .stats-right {
        display: none;
      }
    }
  }
}
