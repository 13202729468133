@use 'styles/vars/margins';

.navigate-to {
  display: inline-flex;
  align-items: center;
  gap: margins.$margin-small2;
  white-space: nowrap;

  .navigate-to-icon {
    font-size: 1.5em;
    line-height: 0;
  }
}
