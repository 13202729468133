@use 'styles/vars/margins';
@use 'styles/mixins/rounded-borders';

.message {
  background: var(--bg-main-container);

  padding: var(--space-6xl);
  margin: var(--space-xxl) 0;

  @include rounded-borders.border-radius();

  a,
  a:link,
  a:visited {
    color: var(--bg-main-container);
  }

  a:focus,
  a:hover {
    color: var(--bg-main-container);
  }

  &.message-expandable {
    padding: 0 !important;

    > .message-head {
      position: relative;

      user-select: none;
      cursor: pointer;

      padding: var(--space-xl);

      background: transparent;

      > svg {
        transform: scale(1.3) rotate(-90deg);
        transition: transform 0.3s;
        fill: var(--text-default);

        position: absolute;
        right: var(--space-xl);
        top: 50%;
        margin-top: -8px;

        display: block;

        &.up {
          transform: scale(1.3) rotate(90deg);
        }
      }
    }

    > .message-body {
      border-top: 1px solid rgba(255, 255, 255, 0.3);

      padding: var(--space-xl);

      > :first-child {
        margin-top: 0 !important;
      }

      > :last-child {
        margin-bottom: 0 !important;
      }
    }
  }
}
