@use 'styles/mixins/rounded-borders';
@use 'styles/vars/fonts';

.eq-react-select-container {
  background-color: var(--transparent);

  &.eq-react-select--is-disabled {
    .eq-react-select__value-container,
    .eq-react-select__indicators {
      opacity: 0.5;
    }
  }

  .eq-react-select__control {
    outline: 0 !important;
    border: 1px solid var(--border-default);
    min-height: 36px;
    background-color: var(--transparent);
    transition: none;
    @include rounded-borders.border-radius(var(--radius-sm));

    &:hover {
      border-color: var(--border-hover)
    }

    &.eq-react-select__control--is-focused {
      // Fake a 2px border with inset shadow
      border-color: var(--border-hover) !important;
      background-color: var(--transparent);
      box-shadow: none;
    }

    .eq-react-select__value-container {
      .eq-react-select__single-value {
        color: var(--text-default) !important;
      }

      .eq-react-select__multi-value {
        background-color: var(--selector-accent) !important;
        border-radius: var(--radius-sm);

        .eq-react-select__multi-value__label {
          color: var(--text-default) !important;
          line-height: var(--line-short);
        }

        .eq-react-select__multi-value__remove {
          border-radius: var(--radius-sm);
        }
      }

      .eq-react-select__placeholder {
        color: var(--text-default) !important;
        opacity: 0.2 !important;
      }

      .eq-react-select__input-container {
        input.eq-react-select__input {
          border: 0 !important;
          outline: 0 !important;
          box-shadow: none !important;
          color: var(--text-default) !important;

          &:hover,
          &:focus,
          &:active {
            border: 0 !important;
            outline: 0 !important;
            box-shadow: none !important;
          }
        }
      }
    }

    .eq-react-select__indicators {
      svg {
        color: var(--text-default);
        opacity: 0.4 !important;

        &:hover {
          opacity: 0.8 !important;
        }
      }

      .eq-react-select__indicator-separator {
        opacity: 0.5 !important;
        background-color: var(--border-subtle);
      }

      .eq-react-select__indicator {
        padding: 6px 8px;
      }
    }

    &.eq-react-select__control--is-focused {
      .eq-react-select__indicators {
        svg {
          color: var(--text-default);
          opacity: 1 !important;
        }
      }
    }
  }

  .eq-react-select__menu {
    background-color: var(--transparent);
    box-shadow: var(--shadow-lg);

    .eq-react-select__menu-list {
      background-color: var(--bg-card);
      border-radius: var(--radius-lg);

      .eq-react-select__option {
        background-color: var(--transparent);

        &.eq-react-select__option--is-focused {
          background-color: var(--selector-accent);
        }

        &.eq-react-select__option--is-selected {
          background-color: var(--selector-accent);
          color: var(--text-default);
        }
      }
    }
  }
}
