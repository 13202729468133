@use 'sass:map';
@use 'styles/vars/margins';

.no-margin-top {
  margin-top: 0 !important;
}

.no-margin-right {
  margin-right: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.no-margin-left {
  margin-left: 0 !important;
}

.margin-none {
  margin: 0 !important;
}

$-directions: (top, right, bottom, left);

@each $name, $value in margins.$margins {
  .margin-#{$name} {
    margin: $value;
  }

  @each $direction in $-directions {
    .margin-#{$direction}-#{$name} {
      margin-#{$direction}: $value !important;
    }
  }
}

@each $direction in $-directions {
  .margin-#{$direction}-auto {
    margin-#{$direction}: auto;
  }
}
