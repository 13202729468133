// Dialogs and overlays on top
$z-index-dialogs: 1000;

// Navbar second highest
$z-index-nav-menu: 900;
$z-index-menu: 800;

// Snackbars
$z-index-snackbars: 700;

// Popups on the page
$z-index-element-floating: 300;
$z-index-element-mouseovers: 200;

// Forms
$z-index-default-siblings: 1;
$z-index-above-siblings: 2;
