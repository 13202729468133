@use 'sass:color';
@use 'styles/vars/margins';
@use 'styles/vars/palette';
@use 'styles/vars/colors';
@use 'styles/vars/fonts';
@use 'styles/mixins/theme';
@use 'styles/mixins/rounded-borders';

@mixin render() {
  hr {
    border-top: 4px solid var(--border-brand);
    width: var(--size-11xl);
    text-align: left;
    margin: 0 0 var(--space-4xl) 0;
  }

  .article-content {
    width: 100%;

    h2 {
      font-size: var(--font-md);
      margin-bottom: 0.875em;
      margin-top: 1.875em;
    }

    h3 {
      font-size: 15px;
      margin-bottom: 0.875em;
      margin-top: 1.125em;
    }

    &.article-content-larger {
      font-size: 16px;

      p {
        margin-top: 0.875em;
        margin-bottom: 0.875em;
      }

      h2 {
        font-size: var(--font-lg);
        margin-bottom: 0.875em;
        margin-top: 1.875em;
      }

      h3 {
        font-size: var(--font-md);
        margin-bottom: 0.875em;
        margin-top: 1.125em;
      }
    }

    blockquote {
      font-size: 1em;

      padding: 13px 15px;
      margin: 18px 0;

      border-left: 3px solid var(--border-brand);
      background: var(--element-bg);
      border-radius: var(--radius-md);
    }

    img {
      max-width: 100%;
      height: auto;
      margin: 20px auto;
      display: block;

      @include rounded-borders.border-radius();
    }

    video,
    iframe {
      display: block;

      max-width: 100%;
      margin: 20px auto;

      @include rounded-borders.border-radius(5px);
    }

    pre {
      display: block;
      background: var(--element-bg);
      border: none;

      color: var(--text-default);

      margin-bottom: 20px;
      margin-top: 20px;
      padding: 0.75em 1em;
      white-space: pre;

      font-size: 1em;
      font-family: fonts.$font-family-mono;
      letter-spacing: -0.33px;
      line-height: 1.5;

      max-width: 100%;

      overflow-y: auto;

      @include rounded-borders.border-radius(4px);
    }

    code {
      background: var(--element-bg);
      color: var(--text-brand);

      font-size: 0.9em;
      font-weight: var(--font-bold);

      padding-left: 4px;
      padding-right: 4px;
      @include rounded-borders.border-radius();
    }
  }

  & {
    --element-bg: var(--bg-main-container);

    @include theme.variant(light) {
      --element-bg: var(--neutral-100);
    }
  }
}
