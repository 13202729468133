@use 'styles/vars/margins';
@use 'styles/vars/palette';
@use 'styles/vars/fonts';
@use 'styles/mixins/theme';

// Prettier text rendering

body {
  text-rendering: optimizeLegibility;
  font: var(--body-2);
}

// Defaults

button,
input,
textarea,
select {
  line-height: fonts.$line-height-pararaph;
}

// Headers

h1,
h2,
h3,
h4 {
  color: var(--text-default);
}

h1 {
  font-size: fonts.$font-title;
  font-weight: fonts.$font-weight-bold;
  line-height: fonts.$line-height-headers;

  margin-top: margins.$margin-large3;
  margin-bottom: margins.$margin-small0;
}

h2 {
  font-size: fonts.$font-header;
  font-weight: fonts.$font-weight-bold;
  line-height: fonts.$line-height-headers;

  margin-top: margins.$margin-large2;
  margin-bottom: margins.$margin-small0;
}

h3 {
  font-size: fonts.$font-subheader;
  font-weight: fonts.$font-weight-bold;

  margin-top: margins.$margin-large1;
  margin-bottom: margins.$margin-small1;
}

h4 {
  font-size: fonts.$font-normal;
  font-weight: fonts.$font-weight-bold;

  margin-top: margins.$margin-large0;
  margin-bottom: margins.$margin-small1;
}

// Paragraphs and general text styling

p,
p > *,
label,
blockquote,
pre {
  line-height: fonts.$line-height-pararaph;
}

p,
label,
blockquote,
pre {
  margin-top: margins.$margin-small1;
  margin-bottom: margins.$margin-small1;
}

strong,
b,
strong *,
b * {
  font-weight: fonts.$font-weight-bold;
}

em,
i,
em *,
i * {
  font-style: italic;
}

pre {
  font-family: fonts.$font-family-mono;
  font-weight: fonts.$font-weight-normal;
}

// Lists

ul,
ol {
  padding-left: 2em;
}

li {
  line-height: fonts.$line-height-pararaph;

  margin-top: margins.$margin-small2;
  margin-bottom: margins.$margin-small2;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

ul.list-slim,
ol.list-slim {
  li {
    margin-top: 0;
    margin-bottom: 0;
  }
}

// Ruler

hr {
  border: 0;
  border-top: 1px solid var(--border-subtle);

  margin-top: margins.$margin-large2;
  margin-bottom: margins.$margin-large2;

  &.spacer {
    border: 0;
    margin-top: margins.$margin-large4;
    margin-bottom: margins.$margin-large4;
  }

  &.is-dashed {
    border-style: dashed;
  }
}

// Utils
.line-height-0 {
  line-height: 0;
}
