// Default blue links

a,
a:link,
a:visited,
a:active {
  color: var(--text-urls);
  text-decoration-line: underline;
  text-decoration-color: var(--text-urls);
  text-decoration-thickness: 1px;

  &:hover,
  &:focus {
    color: var(--text-urls);
    text-decoration-color: var(--text-urls);
    text-decoration-thickness: 1px;
  }
}
