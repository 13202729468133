@use 'styles/vars/borders';

@mixin border-radius($r: var(--radius-md)) {
  & {
    border-radius: $r;
  }
}

@mixin border-radius-top($r: var(--radius-md)) {
  border-top-left-radius: $r;
  border-top-right-radius: $r;
}

@mixin border-radius-bottom($r: var(--radius-md)) {
  border-bottom-left-radius: $r;
  border-bottom-right-radius: $r;
}

@mixin border-radius-left($r: var(--radius-md)) {
  border-top-left-radius: $r;
  border-bottom-left-radius: $r;
}

@mixin border-radius-right($r: var(--radius-md)) {
  border-top-right-radius: $r;
  border-bottom-right-radius: $r;
}
