@use 'sass:math';
@use 'styles/vars/margins';
@use 'styles/mixins/responsive';
@use 'styles/vars/fonts';
@use 'styles/vars/palette';

$breakpoint-large: 1750px;

.remit-calculator-page {
  .calc-subheader {
    font-size: fonts.$font-subheader;
    margin-bottom: margins.$margin-large3;
    max-width: 700px;
  }

  .calc-inputs {
    display: grid;
    grid-template-columns: 250px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 5em;
    grid-template-rows: auto;
    align-items: center;
    gap: margins.$margin-small2;
    margin-bottom: margins.$margin-small2;

    .inputs-header {
      font-weight: fonts.$font-weight-bold;
      padding: margins.$margin-small4 0;
    }

    .inputs-col {
      white-space: nowrap;

      &.inputs-col-value {
        text-align: right;
      }

      &.inputs-col-icons {
        display: flex;
        gap: margins.$margin-default;
        align-items: center;
        padding-left: margins.$margin-default;
      }

      &.inputs-col-error {
        grid-column: span 4;
        color: var(--text-error);
        text-align: center;
      }

      label {
        display: none;
      }

      input {
        width: 100%;
      }

      .icon-delete {
        font-size: 1.35em;
        color: var(--text-error);
        cursor: pointer;
      }

      .icon-copy {
        font-size: 1.35em;
        cursor: pointer;
      }
    }

    .inputs-divider {
      grid-column: span 9;
      border-top: 1px dashed var(--border-subtle);
    }

    @include responsive.below($breakpoint-large) {
      display: grid;
      grid-template-columns: 1fr 1fr;

      .inputs-header {
        display: none;
      }

      .inputs-col {
        label {
          display: block;
          margin-top: 0;
        }

        &.inputs-col-value {
          text-align: left;
        }

        &.inputs-col-icons {
          grid-column: span 2;
          justify-content: flex-end;
        }
      }

      .inputs-divider {
        grid-column: span 2;
        margin: margins.$margin-small2 0;
        border-top-style: solid;
      }

      // Hide divider after headers, which is also hidden at this point,
      // but keep all other dividers
      .inputs-header + .inputs-divider {
        display: none;
      }
    }

    @include responsive.below-tablet {
      display: block;

      .inputs-col {
        margin-bottom: margins.$margin-small0;
      }
    }
  }

  .calc-charts {
    $gap: margins.$margin-default;
    $gap-half: math.div($gap, 2);

    display: grid;
    grid-template-columns: 40% 40% 19%;
    gap: $gap;

    .calc-charts-summary {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .summary-mw {
        font-weight: fonts.$font-weight-bold;
        color: var(--text-subtle);
      }

      .summary-dates {
        color: var(--text-subtle);
      }

      .summary-cols {
        display: flex;
        flex-wrap: wrap;

        .summary-col {
          width: 50%;
        }
      }

      .summary-col {
        padding: margins.$margin-small2;

        .summary-col-value {
          display: block;
          font-size: 1.5em;
          margin-bottom: margins.$margin-small4;
        }

        .summary-col-label {
          display: block;
          color: var(--text-subtle);
          text-transform: uppercase;
          font-size: 0.75em;
        }
      }
    }

    @include responsive.below($breakpoint-large) {
      grid-template-columns: 100%;
      //grid-template-rows: min-content min-content;
      gap: margins.$margin-small2;
    }
  }
}
