@use 'styles/vars/margins';
@use 'styles/mixins/responsive';
@use 'styles/vars/palette';
@use 'styles/vars/colors';
@use 'styles/vars/fonts';
@use 'styles/vars/selectors';
@use 'styles/vars/borders';
@use 'styles/mixins/rounded-borders';

.area-selector-simple {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  width: 300px;
  height: selectors.$selector-height;
  padding: margins.$margin-small4;

  background-color: var(--selector-bg);

  cursor: pointer;

  @include rounded-borders.border-radius;

  @include responsive.below(responsive.$breakpoint-mobile-1) {
    max-width: 100%;
  }

  .area-selector-main-simple {
    position: relative;

    display: block;

    width: 100%;
    height: 100%;
    padding-right: margins.$margin-large4;

    > .area-selector-button {
      position: relative;

      display: flex;
      align-items: center;

      margin-left: margins.$margin-small2;
      height: 100%;

      overflow: hidden;
    }

    .area-selector-related-wrapper {
      overflow-y: auto;
      gap: margins.$margin-default;

      scrollbar-width: none;

      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-grow: 1;

      padding-top: margins.$margin-small3;
      padding-bottom: margins.$margin-small3;

      &::-webkit-scrollbar {
        display: none;
      }

      > .area-selector-related-group {
        display: flex;
        flex-direction: column;

        margin-left: margins.$margin-small0;

        > p {
          margin: 0;
          padding-left: margins.$margin-small2;
          font-size: margins.$margin-small2;
        }

        > div {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          .area-selector-related {
            display: block;

            color: var(--text-default);
            font-weight: fonts.$font-weight-medium;
            text-decoration: none;
            white-space: nowrap;

            line-height: 2em;
            padding: 0 margins.$margin-small2;

            &:hover {
              color: colors.$color-white;
              background: var(--text-brand);
              border-radius: borders.$border-radius;
            }
          }
        }
      }
    }

    .select-input-icon {
      position: absolute;

      display: block;

      width: 1em;
      height: 0.5em;

      margin-top: -0.25em;
      right: margins.$margin-default;
      top: 50%;

      background-color: var(--text-subtle);
      clip-path: polygon(0 15%, 50% 100%, 100% 15%, 100% 0, 50% 85%, 0 0);

      user-select: none;
    }
  }

  .dropdown-wrapper {
    position: absolute;
    margin-top: margins.$margin-large2;
    padding-left: -#{margins.$margin-small4};

    @include responsive.below(responsive.$breakpoint-mobile-1) {
      max-width: 100%;
    }
  }
}
