@use 'styles/mixins/responsive';

.analysis-section-wrapper {
  main {
    // Variables
    $main-gap: responsive.$re-gap;
    $main-gap-small: responsive.$re-gap-small;

    // Flexing
    flex-grow: 1;

    // Styles
    background: transparent;
    margin-left: $main-gap;

    // Tablet/mobile: Left menu becomes closed at this point
    @include responsive.below(responsive.$re-analysis-monitor-1280) {
      margin-left: $main-gap-small;
      margin-right: $main-gap-small;
    }
  }
}
