@use 'styles/vars/margins';
@use 'styles/vars/fonts';
@use 'styles/vars/zindex';
@use 'styles/mixins/responsive';
@use 'styles/mixins/rounded-borders';

.frequency-with-date-selector {
  .date-items-wrapper {
    display: flex;
    align-items: center;

    .date-items {
      display: flex;
      align-items: center;
    }

    .date-icon {
      font-size: 1.25em;
      line-height: 0;
      cursor: pointer;
      margin-left: margins.$margin-small2;
      margin-right: margins.$margin-small2;
    }
  }

  @include responsive.below-tablet() {
    height: auto;
    flex-direction: column;
    padding: margins.$margin-small3;
    padding-bottom: 0;
  }
}

.frequency-date-selector-dropdown-wrapper {
  background-color: var(--bg-card);
  box-shadow: var(--shadow-lg);
  align-self: flex-end;
  z-index: zindex.$z-index-above-siblings;
  min-width: 150px;
  padding: margins.$margin-small2;
  @include rounded-borders.border-radius();

  .item {
    cursor: pointer;
    padding: 5px 8px;
    border-radius: var(--radius-md);
    color: var(--text-subtle);

    &.is-selected {
      background: var(--selector-accent);
      color: var(--text-default);
    }
  }

  .inner {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    font-size: 13px;
    width: 100%;

    .item-wrapper {
      width: 50%;
      .item {
        cursor: pointer;
        padding: 2px 0;
      }
    }
  }
}

.outages-date-mover {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  color: var(--text-default);
  margin: 5px;
  padding-left: 10px;
  border-left: 1px solid var(--border-subtle);

  @include responsive.below-tablet() {
    width: 100%;
    border-left: none;
    border-top: 1px solid var(--border-subtle);
    padding-top: 5px;
    padding-right: 10px;
  }

  > * {
    margin: 0 !important;

    > select {
      padding: margins.$margin-small4 margins.$margin-large4 margins.$margin-small4
        margins.$margin-default;
    }
  }

  > button {
    padding: 0;
    padding-left: margins.$margin-small3;
    padding-right: margins.$margin-small3;
    background: transparent !important;
    border: 0 !important;

    &:hover,
    &:focus {
      background: transparent !important;
      border: 0 !important;
      box-shadow: none !important;
    }

    > svg {
      margin: 0 !important;
      color: var(--text-subtle);
    }
  }

  .date-text {
    min-width: 120px;
    text-align: center;
    font-size: fonts.$font-small;
    font-weight: fonts.$font-weight-bold;

    @include responsive.below-tablet() {
      font-size: fonts.$font-normal;
      line-height: 200%;
    }
  }
}
