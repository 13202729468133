@use 'styles/vars/margins';
@use 'styles/vars/fonts';

@use 'styles/components/article-content';

.operational-message-popup {
  h2 {
    margin-top: 0;
  }

  .operational-message-type {
    font-size: fonts.$font-header;
    margin-bottom: margins.$margin-large1;
  }

  .operational-message-summary {
    font-size: fonts.$font-header;
    font-weight: fonts.$font-weight-light;
    margin: margins.$margin-large1 0;
  }

  .operational-message-updated {
    margin: margins.$margin-large1 0;
  }

  .operational-message-summary,
  .operational-message-content {
    @include article-content.render();

    // Override article styling from Knowledge base
    .article-content {
      > *:first-child {
        margin-top: 0;
      }
    }
  }
}
