@use 'styles/vars/margins';
@use 'styles/mixins/responsive';
@use 'styles/vars/fonts';

.smhi-logo {
  @include responsive.above(responsive.$breakpoint-monitor-1280) {
    display: none;
  }

  > .logo-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    > p {
      margin: 0 0 margins.$margin-small4 0;
      font-size: fonts.$font-small;
    }

    .smhi-logo-path {
      fill: var(--text-default);
      fill-opacity: 1;
      fill-rule: nonzero;
      stroke: none;
    }
  }
}
