@use 'styles/vars/margins';
@use 'styles/vars/fonts';
@use 'styles/vars/zindex';
@use 'styles/mixins/responsive';
@use 'styles/mixins/rounded-borders';

.outage-map-legend-positioner {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;

  padding: var(--space-md);
  display: flex;
  justify-content: center;
  pointer-events: none;

  @include responsive.below-tablet {
    left: 0;
  }
}

.outage-map-legend {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-xs) var(--space-lg);
  font-size: var(--font-xxs);
  background-color: var(--bg-main-container);
  padding: var(--space-sm) var(--space-md);
  z-index: 4;
  box-shadow: var(--shadow-lg);
  @include rounded-borders.border-radius();

  .icon-description {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    gap: margins.$margin-small3;
    line-height: fonts.$line-height-pararaph;
  }
}
