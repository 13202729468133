@use 'styles/vars/margins';
@use 'styles/vars/palette';

.debug-zigzag {
  width: 100px;
  margin: 3px;
  background: palette.$color-blue-6;

  &:nth-child(2n + 1) {
    background: palette.$color-blue-6;
  }
}

.debug-margin-small4 {
  height: margins.$margin-small4;
  content: #{margins.$margin-small4};
}
.debug-margin-small3 {
  height: margins.$margin-small3;
}
.debug-margin-small2 {
  height: margins.$margin-small2;
}
.debug-margin-small1 {
  height: margins.$margin-small1;
}
.debug-margin-small0 {
  height: margins.$margin-small0;
}
.debug-margin-default {
  height: margins.$margin-default;
  &:before {
    font-size: 8px;
    color: white;
    line-height: 0;
  }
}
.debug-margin-large0 {
  height: margins.$margin-large0;
}
.debug-margin-large1 {
  height: margins.$margin-large1;
}
.debug-margin-large2 {
  height: margins.$margin-large2;
}
.debug-margin-large3 {
  height: margins.$margin-large3;
}
.debug-margin-large4 {
  height: margins.$margin-large4;
}
.debug-margin-large5 {
  height: margins.$margin-large5;
}

.bg1 {
  padding: 60px 30px 30px 30px;
  background: var(--bg1);
  margin-top: 20px;

  border: 1px solid var(--border);
  border-radius: 3px;
}

.bg2 {
  background: var(--bg2);
  padding: 30px;
  margin-top: 20px;

  border: 1px solid var(--border);
  border-radius: 3px;
}

.bg1-hover {
  border-top: 1px solid var(--border);
  padding: 5px 20px;
  cursor: pointer;

  &:last-child {
    border-bottom: 1px solid var(--border);
  }

  &:hover {
    background: var(--bg1-hover);
  }
}

.bg2-hover {
  background: var(--bg2);
  border-top: 1px solid var(--border);
  padding: 5px 20px;
  cursor: pointer;

  &:last-child {
    border-bottom: 1px solid var(--border);
  }

  &:hover {
    background: var(--bg2-hover);
  }
}
