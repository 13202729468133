.eq-uplot-wrapper {
  touch-action: none !important;
}

.uplot {
  animation: -anim-uplot-fadeIn 0.4s forwards;
  touch-action: none !important;
}

@keyframes -anim-uplot-fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
