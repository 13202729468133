@use 'styles/mixins/theme';

@include theme.variant(light) {
  --selector-bg: var(--neutral-white);
  --selector-accent: var(--neutral-100);
  --selector-wrapper-bg: var(--bg-page-body);
}

@include theme.variant(dark) {
  --selector-bg: var(--neutral-800);
  --selector-accent: var(--neutral-600);
  --selector-wrapper-bg: var(--transparent);
}
