.bento {
  display: flex;
  align-items: center;

  .bento-icon {
    line-height: 0;
    cursor: pointer;

    svg {
      width: var(--size-xxl);
      height: var(--size-xxl);
    }
  }

  .bento-popup {
    background-color: var(--bg-subtle);
    box-shadow: var(--shadow-lg);
    border-radius: var(--radius-sm);
    width: 216px;

    .bento-link,
    .bento-logo {
      display: flex;
      align-items: center;
      height: var(--size-6xl);
      padding: 0 var(--space-lg);
      font: var(--placeholder-1);
      color: inherit;
      text-decoration: none;
    }

    .bento-links {
      padding: var(--space-md) 0;
    }

    .bento-logo {
      border-bottom: 1px solid var(--border-default);
      height: var(--size-8xl);

      svg {
        height: var(--size-xl);
      }
    }
  }
}
