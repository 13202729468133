@use 'styles/vars/margins';
@use 'styles/vars/fonts';
@use 'styles/mixins/rounded-borders';
@use 'styles/mixins/shadows';

.card {
  background-color: var(--bg-main-container);
  padding: var(--space-md);
  @include rounded-borders.border-radius;

  > h2,
  > h3 {
    margin-top: 0;
    margin-bottom: margins.$margin-small3;
  }

  > h3 {
    font-size: fonts.$font-normal;
  }

  &.card-overflow-x {
    overflow-x: auto;
  }

  &.is-shadow {
    box-shadow: var(--shadow-lg);
  }

  .card-content {
    //overflow-x: hidden;
  }
}
