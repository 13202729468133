.list-overview-chart {
  h4 {
    margin: 0;
    font-size: 12px;
    color: var(--text-subtle);
  }

  hr {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  &.is-loading {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2px;
  }
}
