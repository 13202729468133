$-legend-symbol-height: 0.75rem;
$-legend-symbol-width: 0.75rem;

.simple-legend-area {
  width: $-legend-symbol-width;

  .line-upper {
    border-width: 1px;
    border-style: solid;

    &.is-dashed {
      border-style: dashed;
    }
  }

  .line-lower {
    height: calc(#{$-legend-symbol-height} - 1px); // subtract border
  }
}
