:root {
  --heading-display: var(--font-regular) var(font-xxl) / var(--line-short)
    var(--font-sans);
  --headline-1: var(--font-regular) var(--font-xl) / var(--line-base) var(--font-sans);
  --heading-2: var(--font-regular) var(--font-lg) / var(--line-tall) var(--font-sans);
  --heading-3: var(--font-bold) var(--font-md) / var(--line-taller) var(--font-sans);
  --heading-4: var(--font-semibold) var(--font-sm) / var(--line-tallest)
    var(--font-sans);
  --heading-5: var(--font-semibold) var(--font-xs) / var(--font-taller) var(--font-sans);

  --body-1: var(--font-regular) var(--font-sm) / var(--line-tallest) var(--font-sans);
  --body-1-medium: var(--font-medium) var(--font-sm) / var(--line-tallest)
    var(--font-sans);
  --body-2: var(--font-regular) var(--font-xs) / var(--line-taller) var(--font-sans);
  --body-2-medium: var(--font-meidum) var(--font-xs) / var(--line-taller)
    var(--font-sans);
  --body-3: var(--font-regular) var(--font-xxs) / var(--line-tall) var(--font-sans);
  --body-3-medium: var(--font-medium) var(--font-xxs) / var(--line-tall)
    var(--font-sans);

  --caption: var(--font-regular) var(--font-xxs) / var(--line-tall) var(--font-sans);
  --tags: var(--font-semibold) var(--font-xxs) / var(--line-none) var(--font-sans);
  --counter: var(--font-medium) var(--font-3xs) / var(--line-none) var(--font-sans);

  --placeholder-1: var(--font-regular) var(--font-sm) / var(--line-tallest) var(--font-sans);
  --placeholder-2: var(--font-regular) var(--font-xs) / var(--line-taller) var(--font-sans);
}
