$-legend-symbol-width: 0.75rem;

.simple-legend-scenario-value {
  width: $-legend-symbol-width;
  display: flex;
  flex-direction: column;
  gap: 1px;

  .line-upper,
  .line-lower {
    border-bottom: 2px solid;
  }
}
