@use 'styles/vars/margins';
@use 'styles/vars/palette';
@use 'styles/vars/fonts';
@use 'styles/mixins/rounded-borders';
@use 'styles/mixins/shadows';
@use 'styles/mixins/floating-ui';
@use 'styles/mixins/theme';

.tooltip-button {
  display: inline-block;
  transform: scale(0.7);
  cursor: pointer;
  color: var(--text-default);
  transition: transform 150ms;
  opacity: 0.7;
  vertical-align: middle;
  line-height: 1;

  &:hover {
    transform: scale(0.8);
    opacity: 1;
  }

  > svg {
    font-size: 25px;
  }
}

.tooltip-popup {
  max-width: 400px;
  padding: var(--space-sm) var(--space-lg);
  font-size: var(--font-xs);
  font-weight: var(--font-regular);
  background-color: var(--bg-info);
  color: var(--text-on-info);
  z-index: 999;

  p {
    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin: 0;
    }
  }

  @include rounded-borders.border-radius(var(--radius-lg));

  .tooltip-arrow {
    @include floating-ui.arrow(var(--bg-info));
  }

  // Warning tooltip is never used ?
  &.tooltip-popup-warning {
    background-color: var(--bg-warning);
    color: var(--text-on-warning);

    .tooltip-arrow {
      @include floating-ui.arrow(var(--bg-warning));
    }
  }
}
