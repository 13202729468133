@use 'sass:math';
@use 'styles/vars/margins';
@use 'styles/vars/zindex';
@use 'styles/mixins/responsive';
@use 'styles/mixins/rounded-borders';
@use 'styles/mixins/shadows';
@use 'styles/vars/fonts';

@use './NavigationProps';

.navigation-menu {
  display: flex;
}

.navigation-links {
  display: flex;
  flex-grow: 1;
  height: NavigationProps.$top-bar-height;

  @include responsive.below(responsive.$breakpoint-tablet) {
    display: none;
  }

  a,
  a:link,
  .navigation-link-with-submenu {
    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--text-default);
    font-weight: var(--font-medium);
    text-decoration-color: transparent;

    height: NavigationProps.$top-bar-height;
    padding: 0 NavigationProps.$top-bar-horizontal-padding * 1;
    margin-left: NavigationProps.$top-bar-horizontal-padding * 0.125;
    margin-right: NavigationProps.$top-bar-horizontal-padding * 0.125;

    border-bottom: 2px solid transparent;
    border-top: 2px solid transparent;

    position: relative;

    transition:
      0.15s color,
      opacity 0.25s,
      visibility 0.25s;

    > .navigation-menu-title {
      display: flex;
      align-items: center;
      justify-content: center;

      height: NavigationProps.$top-bar-height;

      cursor: pointer;
    }

    > svg,
    > .navigation-menu-title > svg {
      height: 1.125em;
      width: 1.125em;
      margin-left: -2px;
      margin-right: math.div(NavigationProps.$top-bar-horizontal-padding, 2);

      opacity: 0.4;

      transition: 0.15s opacity;

      &.is-larger {
        font-size: 1.5em;
      }
    }

    > span,
    > .navigation-menu-title > span {
      opacity: 0.8;

      > svg {
        margin-left: 1px;
        margin-bottom: -1px;
        height: 0.7em;
        width: 0.7em;
      }
    }

    &:hover,
    &:focus,
    &.is-open {
      opacity: 1;
      color: var(--text-default);

      > svg,
      > .navigation-menu-title > svg {
        opacity: 1;
      }

      > span,
      > .navigation-menu-title > span {
        opacity: 1;
      }
    }

    &.active {
      opacity: 1;

      border-bottom: 2px solid var(--text-brand);

      > svg,
      > .navigation-menu-title > svg {
        opacity: 1;
        color: var(--text-brand);

        position: relative;
        transform: scale(1.25);
      }

      > span,
      > .navigation-menu-title > span {
        opacity: 1;
        color: var(--text-brand);
      }
    }

    &:last-child {
      margin-left: auto;
    }

    > .navigation-submenu {
      position: absolute;
      top: NavigationProps.$top-bar-height - 6px;
      left: 0; // calc($top-bar-horizontal-padding - 16px);
      z-index: zindex.$z-index-nav-menu;

      min-width: 200px;
      padding: var(--space-sm) 0;
      background: var(--bg-card);
      box-shadow: var(--shadow-lg);

      transition:
        opacity 0.2s,
        visibility 0.2s;

      opacity: 0;
      visibility: hidden;

      @include rounded-borders.border-radius;

      &.is-open {
        opacity: 1;
        visibility: visible;
      }

      > a,
      a:link {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        height: auto;
        padding: var(--space-md) var(--space-lg);

        border-top: none;
        border-bottom: none;

        color: var(--text-default);
        font-weight: fonts.$font-weight-medium;
        text-decoration-color: transparent;

        > svg {
          height: 1em;
          width: 1em;
          margin-left: -2px;
          margin-right: math.div(NavigationProps.$top-bar-horizontal-padding, 2);

          opacity: 0.4;

          transition: 0.15s opacity;
          transform: scale(1);
        }

        > span {
          opacity: 0.8;

          transition: 0.15s opacity;
        }

        &:hover,
        &:focus {
          > svg:first-child {
            opacity: 1;
          }

          > span {
            opacity: 1;

            &:first-child {
              margin-left: 2px;
            }
          }
        }

        &.active {
          opacity: 1;

          margin-left: -1px;
          margin-right: -1px;
          border-left: 3px solid var(--text-brand);

          > svg:first-child {
            opacity: 1;
            color: var(--text-brand);
            //margin-left: -4px;
          }

          > span {
            opacity: 1;
            color: var(--text-brand);
          }

          &:hover,
          &:focus {
            > span {
              opacity: 1;
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}

// Menu link for mobile edition

div.navigation-menu-link {
  display: none;
  margin-left: 0;
  margin-right: 0;

  @include responsive.below(responsive.$breakpoint-tablet) {
    display: flex;
    align-items: center;
  }

  > a {
    opacity: 0.8;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--space-xs) 0;
    border-right: 1px solid var(--border-subtle);
    border-left: 1px solid var(--border-subtle);
    width: NavigationProps.$top-bar-height;
    color: var(--text-default);

    > svg {
      height: 1.75em;
      width: 1.75em;
      opacity: 0.75;
    }

    &:hover {
      > svg {
        opacity: 1;
      }
    }

    &.active {
      color: var(--text-brand);
      opacity: 1;

      > svg {
        opacity: 1;
      }
    }
  }
}

// Mobile dropdown navigation

.navigation-menu-toggle {
  align-items: center;
  line-height: var(--line-none);
  cursor: pointer;
  display: none;

  svg {
    width: var(--size-xxl);
    height: var(--size-xxl);
  }

  @include responsive.below-desktop {
    display: flex;
  }
}

div.navigation-links-dropdown {
  height: auto;
  min-width: 300px;
  z-index: zindex.$z-index-nav-menu;
  max-width: 360px;
  background-color: var(--bg-card);
  box-shadow: var(--shadow-lg);

  @include rounded-borders.border-radius();

  > a,
  > a:link,
  > div.navigation-link-with-submenu {
    display: flex;
    align-items: center;
    justify-content: start;

    font-weight: var(--font-medium);
    text-decoration-color: transparent;

    margin: 0;
    padding: var(--space-md) var(--space-lg);

    color: var(--text-default);

    border-top: 1px solid var(--border-subtle);

    position: relative;

    &:first-child {
      border-top: none;
    }

    &.navigation-link-with-submenu {
      display: block;

      height: auto;

      padding: 0 0 NavigationProps.$top-bar-horizontal-padding * 0.5 0;
    }

    > .navigation-menu-title {
      display: flex;
      align-items: center;
      justify-content: start;

      padding: var(--space-md) var(--space-lg);

      cursor: pointer;

      > span > svg {
        display: none !important;
      }
    }

    > svg,
    > .navigation-menu-title > svg {
      height: 1.25em;
      width: 1.25em;
      margin-left: -2px;
      margin-right: var(--space-sm);
      opacity: 0.5;
    }

    > .navigation-menu-title > svg {
      opacity: 1;
    }

    &:hover,
    &:focus {
      color: var(--text-default);

      > svg,
      > .navigation-menu-title > svg {
        opacity: 1;
      }
    }

    &.active {
      color: var(--text-brand);

      > svg,
      > .navigation-menu-title > svg {
        opacity: 1;

        position: relative;
        transform: scale(1.25);
      }
    }

    > .navigation-submenu {
      display: block;
      position: relative;

      > a,
      a:link {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        padding: var(--space-sm) var(--space-lg) var(--space-sm) var(--space-xxl);

        color: var(--text-default);
        font-weight: var(--font-medium);
        text-decoration-color: transparent;

        > svg {
          height: 1.2em;
          width: 1.2em;
          margin-left: 0;
          margin-right: var(--space-md);
          opacity: 0.4;
          transition: 0.15s opacity;
        }

        > span {
          opacity: 0.8;

          transition: 0.15s opacity;
        }

        &:hover,
        &:focus {
          > svg,
          > span {
            opacity: 1;
          }
        }

        &.active {
          opacity: 1;

          margin-left: -1px;
          margin-right: -1px;
          border-left: 3px solid var(--text-brand);

          > svg:first-child {
            opacity: 1;
            color: var(--text-brand);
            margin-left: -2px;
          }

          > span {
            opacity: 1;
            color: var(--text-brand);
          }

          &:hover,
          &:focus {
            > span {
              opacity: 1;
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}
