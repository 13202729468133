@use 'styles/vars/colors';
@use 'styles/vars/palette';
@use 'styles/vars/fonts';
@use 'styles/vars/margins';
@use 'styles/mixins/theme';

.europe-clickable-map {
  position: relative;
  width: 100%;

  user-select: none;

  div.europe-clickable-map-label {
    position: absolute;
    top: 10px;
    left: 0;
    display: block;
    font-weight: var(--font-medium);
    opacity: 0.5;
  }

  div.selected {
    position: absolute;

    bottom: 8px;
    left: 0;
    right: 0;

    background: transparent;

    font-size: var(--font-xxs);
    font-weight: bold;
    text-align: center;
  }

  svg.europe {
    position: relative;

    pattern {
      rect {
        fill: var(--map-others-color);
      }
    }

    path {
      fill: var(--map-others-color);
      stroke: var(--bg-default);

      &.selectable:not(.is-colored) {
        fill: var(--map-selectable-color);

        &:hover,
        &.is-hovered {
          cursor: pointer;
          fill: var(--map-hover-color);
        }

        &.selected {
          fill: var(--map-selected-color);
        }
      }

      &.selectable.is-colored {
        &:hover,
        &.is-hovered {
          cursor: pointer;
          stroke-width: 0.75px;
          stroke: var(--map-colored-hover-stroke);

          @include theme.variant(dark) {
            filter: drop-shadow(0 1px 1px rgb(0 0 0 / 0.6));
          }
        }

        &.selected {
          stroke-width: 1.5px;
          stroke: var(--map-colored-hover-stroke);

          @include theme.variant(dark) {
            filter: drop-shadow(0 3px 3px rgb(0 0 0 / 0.6));
          }
        }
      }
    }

    text {
    }
  }
}

@include theme.variant(dark) {
  --map-selected-color: var(--dark-pine);
  --map-selectable-color: var(--light-pine);
  --map-others-color: var(--neutral-700);
  --map-hover-color: var(--pine);
  --map-colored-hover-stroke: var(--neutral-50);
}

@include theme.variant(light) {
  --map-selected-color: var(--dark-pine);
  --map-selectable-color: var(--light-pine);
  --map-others-color: var(--neutral-100);
  --map-hover-color: var(--pine);

  --map-colored-hover-stroke: var(--neutral-800);
}
