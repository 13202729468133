.key-carbon-intensity {
  .postfix {
    font-size: 0.85em;
    text-align: center;

    .postfix-unit {
      border-bottom: 1px solid var(--text-subtle);
      margin-bottom: 0;
      padding-bottom: 0.25rem;
    }
  }
}
