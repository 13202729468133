@use 'sass:math';
@use 'sass:map';
@use 'styles/vars/margins';
@use 'styles/mixins/responsive';
@use 'styles/utils/list';

$grid-gap: responsive.$re-gap;
$grid-gap-small: responsive.$re-gap-small;
$grid-columns: 24;

$breakpoints: (
  mobile: responsive.$re-analysis-mobile,
  tablet: responsive.$re-analysis-tablet,
  xxs: responsive.$re-analysis-monitor-1280,
  xs: responsive.$re-analysis-monitor-1366,
  sm: responsive.$re-analysis-monitor-1440,
  md: responsive.$re-analysis-monitor-1600,
  lg: responsive.$re-analysis-monitor-1680,
  xl: responsive.$re-analysis-monitor-1920
);

.grid.content {
  display: grid;
  grid-template-columns: repeat($grid-columns, 1fr);
  grid-template-rows: auto;
  gap: $grid-gap;

  margin-top: margins.$margin-default;
  margin-bottom: margins.$margin-default;

  // Grid-in-grid
  .grid.content {
    margin-top: 0;
    margin-bottom: 0;
  }

  // Grid widths
  @include responsive.analysis(responsive.$re-analysis-content-map);

  // Reduce gap on smaller screens
  @include responsive.below(responsive.$re-analysis-monitor-1440) {
    gap: $grid-gap-small;
  }

  // Override properties for smallest breakpoint
  // Left menu becomes closed at this point
  // Todo: Is this necessary?
  @include responsive.below(responsive.$re-analysis-monitor-1280) {
    display: grid;
  }

  // Tablet/mobile: Left menu becomes closed at this point
  @include responsive.below(responsive.$re-analysis-tablet) {
    width: 100%;
  }

  &.grid-fluid {
    width: 100%;
  }

  &.grid-reverse {
    direction: rtl;

    > * {
      direction: ltr;
    }
  }

  // Columns
  .col {
    @for $size from 1 through $grid-columns {
      &.col-#{$size} {
        grid-column: span $size;
      }

      @for $offset from 1 through $grid-columns {
        $colum-end: $grid-columns - $size;

        @if $colum-end > 0 {
          &.col-#{$size}-offset-#{$offset} {
            grid-column: $offset / span $colum-end;
          }
        }
      }
    }

    @each $breakpoint, $max-width in list.reverse($breakpoints) {
      @include responsive.below($max-width) {
        @for $size from 1 through $grid-columns {
          &.col-#{$breakpoint}-#{$size} {
            grid-column: span $size;
          }

          @for $offset from 1 through $grid-columns {
            $colum-end: $grid-columns - $size;

            @if $colum-end > 0 {
              &.col-#{$breakpoint}-#{$size}-offset-#{$offset} {
                grid-column: $offset / span $colum-end;
              }
            }
          }
        }
      }
    }

    &.col-greedy {
      @include responsive.below(map.get($breakpoints, tablet)) {
        grid-column: span $grid-columns !important;
      }
    }

    &.col-allow-overflow {
      overflow: visible;
    }

    &.col-nowrap {
      white-space: nowrap;
    }
  }
}
