@use 'styles/vars/margins';
@use 'styles/vars/fonts';

.power2sim-table-card {
  .table-info {
    display: flex;
    flex-direction: column;
    gap: margins.$margin-small2;
    margin-top: margins.$margin-default;

    .table-info-row {
      display: flex;
      align-items: center;
      gap: margins.$margin-small2;
      color: var(--text-subtle);

      svg {
        font-size: 1.25em;
      }

      p {
        margin: 0;
      }
    }
  }
}
