@use 'styles/vars/margins';
@use 'styles/vars/fonts';
@use 'styles/mixins/responsive';
@use 'styles/mixins/rounded-borders';
@use 'styles/mixins/shadows';
@use 'styles/mixins/margins' as margins-utils;

.area-selector-card-wrapper {
  position: relative;
  z-index: 1;

  > .area-selector-card {
    position: relative;
    width: 100%;
    padding: margins.$margin-small2;
    background: var(--selector-bg);

    @include margins-utils.no-margin-first-last-child;
    @include rounded-borders.border-radius();

    @include responsive.below(responsive.$re-analysis-tablet) {
      padding: margins.$margin-small3;
    }

    .areas-lists {
      width: 100%;

      max-width: calc(192px * 5 + 400px);

      display: flex;
      justify-content: space-between;

      @include responsive.below(responsive.$re-analysis-tablet) {
        justify-content: space-around;
      }

      > .areas-column {
        margin-right: margins.$margin-large2;
        min-width: 96px;
        max-width: 192px;

        @include responsive.below(responsive.$re-analysis-monitor-1280) {
          margin-right: margins.$margin-large0;
        }

        @include responsive.below(responsive.$re-analysis-tablet) {
          display: none;
        }

        > h4 {
          > a {
            color: var(--text-default);
            text-decoration: none;
          }
        }

        > ul {
          list-style: none;
          padding-left: 0;

          > li {
            > a {
              color: var(--text-default);
              text-decoration: none;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }

      > .area-selector-map {
        position: relative;

        justify-self: flex-start !important;
        align-self: center;

        max-width: 400px;
        min-width: 350px;

        @include responsive.below(responsive.$re-analysis-monitor-1280) {
          margin-right: margins.$margin-large2;
        }

        @include responsive.below(responsive.$re-analysis-tablet) {
          margin-right: 0;
          width: 100%;
        }
      }
    }

    .toggle-button-position {
      position: absolute;
      bottom: margins.$margin-default;
      right: 50%;
      transform: translateX(50%);
      opacity: 0.7;

      .toggle-button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: fonts.$font-small;
        padding: 0 margins.$margin-small2;
        border: 1px solid var(--border-default);
        cursor: pointer;
        opacity: 0.7;
        @include rounded-borders.border-radius();

        .toggle-button-icon {
          font-size: 2em;
          color: var(--text-subtle);
        }

        > span {
          line-height: 1;
        }

        &:hover {
          opacity: 1;
        }
      }

      @include responsive.below(responsive.$re-analysis-tablet) {
        position: relative;
        display: flex;
        bottom: auto;
        right: auto;
        justify-content: center;
        transform: translateX(0);
      }
    }
  }
}
