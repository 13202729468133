@use 'styles/vars/margins';
@use 'styles/vars/fonts';
@use 'styles/mixins/responsive';

.curves-list {
  display: flex;
  flex-direction: column;
  gap: margins.$margin-default;

  .curves-list-curve-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: margins.$margin-small2;
    padding: margins.$margin-small2;

    .actions-columns {
      display: flex;
      align-items: center;
      gap: margins.$margin-large2;
      margin-right: auto;

      .column {
        font-size: fonts.$font-small;

        .column-label {
          font-size: 0.85em;
          text-transform: uppercase;
          color: var(--text-subtle);
          margin-bottom: margins.$margin-small3;
        }
      }

      @include responsive.below-tablet {
        gap: margins.$margin-default;
      }
    }

    .actions-access {
      font-size: fonts.$font-small;
      margin-right: auto;
    }
  }
}
