@use 'styles/vars/margins';
@use 'styles/vars/fonts';

.plain-tabs {
  .plain-tabs-tabs-wrapper {
    position: relative;
    border-bottom: 1px solid var(--border-subtle);
  }

  .plain-tabs-tabs {
    position: relative;
    top: 1px;
    display: flex;
    flex-wrap: nowrap;
    flex-grow: 1;
    padding: 0;
    overflow-y: auto;
    scrollbar-width: none;
    white-space: nowrap;

    > div {
      color: var(--text-default);
      font-weight: fonts.$font-weight-medium;
      text-decoration: none;
      opacity: 0.6;
      cursor: pointer;
      padding: 0 margins.$margin-default margins.$margin-small2;

      &:hover {
        opacity: 0.8;
      }

      &.is-active {
        opacity: 1;
        color: var(--text-brand);
        border-bottom: 3px solid var(--text-brand);
      }

      &.is-right {
        margin-left: auto;
      }
    }
  }

  .plain-tabs-content {
    > div {
      display: none;

      &.is-active {
        display: block;
      }
    }
  }
}
