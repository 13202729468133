@use 'styles/vars/margins';
@use 'styles/vars/zindex';
@use 'styles/vars/fonts';
@use 'styles/mixins/responsive';
@use 'styles/mixins/rounded-borders';
@use 'styles/mixins/shadows';
@use 'styles/mixins/theme';

@forward 'components/CurvesList/CurvesList';
@forward 'components/CurvesListTableToggle/CurvesListTableToggle';

@forward 'sections/chart-creator/pages/ChartCreatorPage/ChartCreatorPage';
@forward 'sections/chart-creator/pages/ChartCreatorListPage/ChartCreatorListPage';
@forward 'sections/chart-creator/components/ChartCreator/ChartCreator';
@forward 'sections/chart-creator/components/ChartCreatorChart/ChartCreatorChart';
@forward 'sections/chart-creator/components/ChartCreatorColor/ChartCreatorColor';
@forward 'sections/chart-creator/components/ChartCreatorCurves/ChartCreatorCurves';
@forward 'sections/chart-creator/components/ChartCreatorCurve/ChartCreatorCurve';
@forward 'sections/chart-creator/components/ChartCreatorCurvesSearch/ChartCreatorCurvesSearch';
@forward 'sections/chart-creator/components/ChartCreatorList/ChartCreatorList';
@forward 'sections/chart-creator/components/ChartCreatorOptions/ChartCreatorOptions';

@forward 'sections/push-feed/pages/PushFeedPage/PushFeedPage';
@forward 'sections/push-feed/components/PushFeedList/PushFeedList';
@forward 'sections/push-feed/components/PushFeedListCard/PushFeedListCard';

@forward 'sections/integrations/pages/IntegrationsPage/IntegrationsPage';
@forward 'sections/integrations/pages/ExcelIntegratorPage/ExcelIntegratorPage';

@forward 'sections/places/pages/PlacesDetailsPage/PlacesDetailsPage';
@forward 'sections/places/components/PlacesDetailsFacilities/PlacesDetailsFacilities';
@forward 'sections/places/components/PlacesDetailsMap/PlacesDetailsMap';

@forward 'sections/curves/components/CurveTimeSeries/CurveTimeSeries';
@forward 'sections/curves/components/CurveChartFilter/CurveChartFilter';
@forward 'sections/curves/components/CurveChartOption/CurveChartOption';
@forward 'sections/curves/components/CurveEnsemblesChips/CurveEnsemblesChips';
@forward 'sections/curves/components/CurveFetchExamples/CurveFetchExamples';

.data-section {
  width: 100%;
  min-height: calc(100vh - 50px);

  .data-section-with-sidebar {
    display: grid;
    gap: var(--space-md);
    grid-template-columns: 300px auto;

    @include responsive.below-desktop() {
      grid-template-columns: 100%;
    }
  }

  .data-sidebar-wrapper {
    padding: var(--space-md);
  }

  .data-sidebar {
    width: 300px;
    padding: margins.$margin-default;
    background: var(--bg-main-container);
    border-radius: var(--radius-lg);

    h2 {
      font-size: 1.4em;
    }

    h3 {
      font-size: 1.2em;
    }

    label {
      font-size: fonts.$font-normal;
    }

    // Override sidebar button options in the sidebar
    // See global properties below
    .data-sidebar-buttons {
      .data-sidebar-close {
        display: none;
      }

      .data-sidebar-count {
        display: block;
        color: var(--text-brand);
        font-weight: fonts.$font-weight-medium;
      }

      &:not(.is-top) {
        display: none;
      }

      &.hide-on-widescreen {
        @include responsive.above-tablet() {
          display: none;
        }
      }
    }

    @include theme.variant(light) {
      .selector-wrapper {
        background-color: var(--selector-wrapper-bg);
        padding: var(--space-sm);
        border-radius: var(--radius-sm);
      }
    }

    @include responsive.below-desktop() {
      display: none;
    }
  }

  // Global properties for sidebar buttons, used for
  // both the sidebar and the drawer
  .data-sidebar-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .data-sidebar-count {
      display: none;
    }
  }

  .data-main {
    padding: margins.$margin-default margins.$margin-large2;

    &.is-max-width {
      width: 100%;
      max-width: responsive.$re-analysis-content-1366;
    }

    &.is-center {
      justify-self: center;

      > h1 {
        text-align: center;
      }
    }

    > h1 {
      margin-top: margins.$margin-default !important;
    }

    @include responsive.below-tablet {
      padding: margins.$margin-small2;
    }
  }

  .data-content {
    max-width: responsive.$re-analysis-full-1920;
    padding: margins.$margin-small4 margins.$margin-default;
    margin: 0 auto;

    > h1 {
      margin-bottom: margins.$margin-large4;
    }

    .data-chart-section {
      margin: var(--space-md) 0;

      .data-chart-section-empty {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 230px;
      }
    }

    .data-column-section {
      display: flex;
      justify-content: stretch;
      align-items: flex-start;
      gap: margins.$margin-large4;
      margin-bottom: margins.$margin-large1;

      > * {
        width: calc((100% / 2) - (((2 - 1) / 2) * #{margins.$margin-large4}));
        flex-grow: 1;
        flex-shrink: 0;
      }

      .data-column {
        display: flex;
        flex-direction: column;
        gap: margins.$margin-large0;
      }

      @include responsive.below-desktop {
        flex-direction: column;
        gap: margins.$margin-default;

        > * {
          width: 100%;
        }

        .data-column {
          gap: margins.$margin-default;
        }
      }
    }
  }
}
