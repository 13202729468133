@use 'sass:map';

// Breakpoint helper vars

$--br-dev: 4px; // Breakpoint deviation from actual screen width
$--br-margin: 32px; // The margin from full content width to viewport width

// Default breakpoints

$breakpoint-mobile: calc(960px - $--br-dev);
$breakpoint-tablet: calc(1280px - $--br-dev);

$breakpoint-mobile-1: $breakpoint-mobile; // 960ish or smaller
$breakpoint-mobile-2: 760px;

$breakpoint-monitor-map: (
  1280: calc(1280px - $--br-dev),
  1366: calc(1366px - $--br-dev),
  1440: calc(1440px - $--br-dev),
  1600: calc(1600px - $--br-dev),
  1680: calc(1680px - $--br-dev),
  1920: calc(1920px - $--br-dev),
  4k: calc(2240px - $--br-dev)
);

$breakpoint-monitor-1280: map.get($breakpoint-monitor-map, 1280);
$breakpoint-monitor-1366: map.get($breakpoint-monitor-map, 1366);
$breakpoint-monitor-1440: map.get($breakpoint-monitor-map, 1440);
$breakpoint-monitor-1600: map.get($breakpoint-monitor-map, 1600);
$breakpoint-monitor-1680: map.get($breakpoint-monitor-map, 1680);
$breakpoint-monitor-1920: map.get($breakpoint-monitor-map, 1920);
$breakpoint-monitor-4k: map.get($breakpoint-monitor-map, 4k);

// Responsive analysis content pages

$--re-left-menu-small: 164px;
$--re-left-menu-default: 180px;
$--re-left-menu-large: 192px;

// The gap between columns in the content grid

$re-gap: 12px;
$re-gap-small: 6px;

// Breakpoints

$re-analysis-monitor-map: (
  1280: calc(1280px - $--br-dev),
  1366: calc(1366px - $--br-dev),
  1440: calc(1440px - $--br-dev),
  1600: calc(1600px - $--br-dev),
  1680: calc(1680px - $--br-dev),
  1920: calc(1920px - $--br-dev),
  4k: calc(2240px - $--br-dev)
);

$re-analysis-mobile: calc(768px - $--br-dev);
$re-analysis-tablet: calc(1024px - $--br-dev);
$re-analysis-monitor-1280: map.get($re-analysis-monitor-map, 1280);
$re-analysis-monitor-1366: map.get($re-analysis-monitor-map, 1366);
$re-analysis-monitor-1440: map.get($re-analysis-monitor-map, 1440);
$re-analysis-monitor-1600: map.get($re-analysis-monitor-map, 1600);
$re-analysis-monitor-1680: map.get($re-analysis-monitor-map, 1680);
$re-analysis-monitor-1920: map.get($re-analysis-monitor-map, 1920);
$re-analysis-monitor-4k: map.get($re-analysis-monitor-map, 4k);

// Content width (divisable by 2, 3)

$re-analysis-content-map: (
  1280: 1068px,
  1366: 1152px,
  1440: 1224px,
  1600: 1392px,
  1680: 1452px,
  1920: 1692px,
  4k: 2004px
);

$re-analysis-content-1280: map.get($re-analysis-content-map, 1280);
$re-analysis-content-1366: map.get($re-analysis-content-map, 1366);
$re-analysis-content-1440: map.get($re-analysis-content-map, 1440);
$re-analysis-content-1600: map.get($re-analysis-content-map, 1600);
$re-analysis-content-1680: map.get($re-analysis-content-map, 1680);
$re-analysis-content-1920: map.get($re-analysis-content-map, 1920);
$re-analysis-content-4k: map.get($re-analysis-content-map, 4k);

// Left menu width

$re-analysis-left-menu-map: (
  1280: $--re-left-menu-small,
  1366: $--re-left-menu-small,
  1440: $--re-left-menu-small,
  1600: $--re-left-menu-small,
  1680: $--re-left-menu-default,
  1920: $--re-left-menu-default,
  4k: $--re-left-menu-large
);

$re-analysis-left-menu-1280: map.get($re-analysis-left-menu-map, 1280);
$re-analysis-left-menu-1366: map.get($re-analysis-left-menu-map, 1366);
$re-analysis-left-menu-1440: map.get($re-analysis-left-menu-map, 1440);
$re-analysis-left-menu-1600: map.get($re-analysis-left-menu-map, 1600);
$re-analysis-left-menu-1680: map.get($re-analysis-left-menu-map, 1680);
$re-analysis-left-menu-1920: map.get($re-analysis-left-menu-map, 1920);
$re-analysis-left-menu-4k: map.get($re-analysis-left-menu-map, 4k);

// Full width

$--g: $re-gap;

$re-analysis-full-map: (
  1280: $re-analysis-left-menu-1280 + $re-analysis-content-1280 + $--g,
  1366: $re-analysis-left-menu-1366 + $re-analysis-content-1366 + $--g,
  1440: $re-analysis-left-menu-1440 + $re-analysis-content-1440 + $--g,
  1600: $re-analysis-left-menu-1600 + $re-analysis-content-1600 + $--g,
  1680: $re-analysis-left-menu-1680 + $re-analysis-content-1680 + $--g,
  1920: $re-analysis-left-menu-1920 + $re-analysis-content-1920 + $--g,
  4k: $re-analysis-left-menu-4k + $re-analysis-content-4k + $--g
);

$re-analysis-full-1280: map.get($re-analysis-full-map, 1280);
$re-analysis-full-1366: map.get($re-analysis-full-map, 1366);
$re-analysis-full-1440: map.get($re-analysis-full-map, 1440);
$re-analysis-full-1600: map.get($re-analysis-full-map, 1600);
$re-analysis-full-1680: map.get($re-analysis-full-map, 1680);
$re-analysis-full-1920: map.get($re-analysis-full-map, 1920);
$re-analysis-full-4k: map.get($re-analysis-full-map, 4k);

// Stats from 1 June – 15 August
//
//      - 1023:  5.5 %
// 1024 - 1279:  1.6 %  -- Too low?
// 1280 - 1365: 10.0 %
// 1366 - 1439:  1.8 %  -- Too low?
// 1440 - 1599: 14.8 %
// 1600 - 1679:  2.3 %  -- Too low?
// 1680 - 1919:  5.8 %
// 1920 - 2047: 36.0 %
// 2048 - 2560:  3.9 %  -- Weird?
// 2560 -     : 18.5 %
//
// Breakpoints
//
// < 1280  -- 1276
// < 1366
// < 1440
// < 1680
// < 1920
// < 2240
//
// Dividable by 2 and 3. Checking function:
//
// def check(r, menu = 164, margin_left = 12, gap = 12, reduce = 32):
//     content = (r - menu - reduce)
//     col1 = content - margin_left
//     col2 = (content - margin_left - gap * 1) / 2.0
//     col3 = (content - margin_left - gap * 2) / 3.0
//     col4 = (content - margin_left - gap * 3) / 4.0
//     print(r, col1, col2, col3, col4)
//
//
// The results:
//
//   check(1276, gap = 6); check(1360, gap = 6); check(1432, gap = 6);
//   check(1600); check(1676, menu = 180); check(1916, menu = 180);
//   check(2240, menu = 192);
//
