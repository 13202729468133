@use 'styles/vars/margins';
@use 'styles/vars/fonts';
@use 'styles/mixins/responsive';

@forward 'components/SMHILogo/SMHILogo';
@forward 'components/CommentList/CommentList';
@forward 'pages/DailyCommentsPage/DailyCommentsPage';
@forward 'pages/WeatherMapsPage/WeatherMapsPage';

.weather-section-wrapper {
  padding: margins.$margin-large3;

  @include responsive.below-desktop() {
    padding: margins.$margin-default;
  }

  .weather-section {
    width: 100%;
    max-width: responsive.$re-analysis-full-1920;
    margin: 0 auto;

    > .weather-section-logo {
      // SMHI Wrapper in Weather section only
      margin: margins.$margin-large4;

      @include responsive.below(responsive.$breakpoint-monitor-1280) {
        display: none;
      }

      > .logo-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include responsive.below(responsive.$breakpoint-monitor-1280) {
          display: none;
        }

        > p {
          margin: 0 0 margins.$margin-small4 0;
          font-size: fonts.$font-small;
        }

        .smhi-logo-path {
          fill: var(--text-default);
          fill-opacity: 1;
          fill-rule: nonzero;
          stroke: none;
        }
      }
    }
  }
}
