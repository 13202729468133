$-legend-symbol-width: 0.75rem;

.simple-legend-value {
  width: $-legend-symbol-width;
  display: flex;
  flex-direction: column;
  gap: 1px;

  .line {
    border-bottom: 1px solid;
  }
}
