@use 'sass:color';

@use 'styles/vars/margins';
@use 'styles/vars/fonts';
@use 'styles/vars/palette';
@use 'styles/mixins/rounded-borders';
@use 'styles/mixins/shadows';
@use 'styles/mixins/responsive';
@use 'styles/mixins/theme';

.event-box {
  position: relative;
  margin-bottom: margins.$margin-default;
  transition: transform 100ms;
  animation: colorChange 3s ease-in-out;
  border: 2px solid var(--transparent);
  @include rounded-borders.border-radius;

  .event-box-header {
    display: flex;
    align-items: center;
    gap: var(--space-sm);
    margin-bottom: var(--space-lg);

    .published-status {
      margin-left: auto;
    }

    @include responsive.below-tablet {
      flex-wrap: wrap-reverse;

      .published-status {
        margin-left: 0;
      }
    }
  }

  .event-box-link {
    a {
      font-family: var(--font-mono), monospace;
      font-weight: var(--font-bold);
      text-decoration: none;
    }
  }

  .event-box-columns {
    display: flex;
    align-items: center;
    gap: var(--space-3xl);
    margin-top: var(--space-xl);

    .column {
      .column-label {
        font-size: 0.85em;
        text-transform: uppercase;
        color: var(--text-subtle);
        margin-bottom: var(--space-xs);
      }
    }

    @include responsive.below-tablet {
      flex-wrap: wrap;
      gap: var(--space-lg);
    }
  }

  @keyframes colorChange {
    0% {
      border-color: var(--border-brand);
    }

    30% {
      border-color: var(--transparent);
    }
  }
}
