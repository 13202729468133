:root {
  --neutral-950: rgb(13, 15, 15);
  --neutral-925: rgb(23, 27, 28);
  --neutral-900: rgb(29, 35, 36);
  --neutral-800: rgb(39, 47, 48);
  --neutral-700: rgb(54, 65, 66);
  --neutral-600: rgb(89, 108, 110);
  --neutral-500: rgb(122, 148, 150);
  --neutral-400: rgb(154, 173, 175);
  --neutral-300: rgb(185, 199, 201);
  --neutral-200: rgb(211, 221, 222);
  --neutral-100: rgb(225, 231, 232);
  --neutral-50: rgb(235, 239, 240);
  --neutral-25: rgb(240, 243, 244);
  --neutral-white: rgb(255, 255, 255);

  --dark-pine: rgb(0, 97, 89);
  --pine: rgb(1, 121, 110);
  --light-pine: rgb(80, 168, 160);
  --green: rgb(32, 122, 58);
  --light-green: rgb(148, 229, 171);

  --dark-orange: rgb(156, 93, 0);
  --orange: rgb(255, 168, 36);
  --light-orange: rgb(255, 229, 190);

  --red: rgb(175, 29, 56);
  --pink: rgb(187, 66, 88);
  --light-pink: rgb(230, 122, 142);

  --purple: rgb(107, 48, 255);
  --light-purple: rgb(194, 189, 255);

  --blue: rgb(48, 79, 254);
  --payne: rgb(70, 108, 134);
  --mint: rgb(163, 237, 227);

  --transparent-950-50: rgba(13, 15, 15, 0.5);
  --transparent-950-12: rgba(13, 15, 15, 0.12);
  --transparent-950-7: rgba(13, 15, 15, 0.07);
  --transparent-950-5: rgba(13, 15, 15, 0.05);

  --transparent-white-12: rgba(255, 255, 255, 0.12);
  --transparent-white-7: rgba(255, 255, 255, 0.07);
  --transparent-white-5: rgba(255, 255, 255, 0.05);
  --transparent: rgba(0, 0, 0, 0);

  --transparent-pine-12: rgba(1, 121, 110, 0.12);
}
