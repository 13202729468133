@use 'styles/vars/fonts';
@use 'styles/vars/margins';
@use 'styles/mixins/responsive';

// This table is used for mini tables inside another table cell
.mini-table {
  tbody {
    tr {
      background: transparent !important;

      td {
        padding-right: margins.$margin-default;
        border: 0;
        white-space: nowrap;

        &.name-column {
          //min-width: 200px;
        }

        &.date-column {
          @include responsive.below-tablet {
            min-width: auto;
            padding-right: margins.$margin-default;
          }
        }
      }
    }
  }
}
