@use 'styles/mixins/theme';

// Drop shadow outside

@mixin drop-shadow(
  $color: rgba(0, 0, 0, 0.1),
  $x: 0px,
  $y: 1px,
  $blur: 4px,
  $spread: 0px
) {
  & {
    box-shadow: $x $y $blur $spread $color;
  }
}

@mixin drop-shadow-default() {
  & {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 1);

    @include theme.variant(light) {
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    }
  }
}

@mixin drop-shadow-large($color: #000) {
  & {
    box-shadow: 0 3px 35px 5px rgba($color, 1);

    @include theme.variant(light) {
      box-shadow: 0 3px 35px 5px rgba($color, 0.2);
    }
  }
}

// Drop shadow inside

@mixin inner-shadow($color: rgba(0, 0, 0, 0.1), $x: 0, $y: 1, $blur: 4px, $spread: 0) {
  box-shadow: inset $x $y $blur $spread $color;
}

@mixin text-shadow($color: rgba(0, 0, 0, 0.6)) {
  text-shadow: 0 0 5px $color;
}
