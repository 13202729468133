@use 'sass:color';

@use 'styles/vars/colors';
@use 'styles/vars/palette';
@use 'styles/vars/breakpoints';
@use 'styles/vars/margins';
@use 'styles/vars/fonts';
@use 'styles/mixins/theme';
@use 'styles/mixins/responsive';
@use 'styles/mixins/shadows';

.data-table-1 {
  width: 100%;
  border-collapse: collapse;
  background-color: var(--transparent);
  cursor: default;

  @include responsive.below(responsive.$re-analysis-monitor-1600) {
    font-family: fonts.$font-family-narrow;
  }

  &.is-fluid {
    width: auto;
  }

  &.is-padded {
    th,
    td {
      padding: var(--space-sm) var(--space-md);
    }
  }

  th,
  td {
    border: 0;
    padding: 4px;

    @include responsive.below(responsive.$re-analysis-monitor-1600) {
      padding: 4px 3px;
    }

    &.separator-left {
      border-left: 1px solid var(--data-table-separator) !important;
    }

    &.separator-left-thick {
      border-left: 4px solid var(--data-table-separator) !important;
    }

    &.separator-right {
      border-right: 1px solid var(--data-table-separator) !important;
    }

    &.separator-right-thick {
      border-right: 4px solid var(--data-table-separator) !important;
    }

    &:last-child {
      border-right: 0 !important;
    }

    &:first-child {
      border-left: 0 !important;
    }
  }

  > thead {
    td {
      border: 0;
    }

    > tr {
      &:last-child {
        border-bottom: 3px solid var(--data-table-separator);
      }

      > th {
        border-bottom: 1px solid var(--data-table-separator);
        border-right: 1px dotted var(--data-table-border);
      }
    }

    > tr.data-table-1-column-groups {
      > th {
        text-align: center;
        font-weight: var(--font-regular);
        color: var(--text-subtle);
        background: var(--data-table-row-odd);
        border-right: 0;

        &.is-border {
          border-top: 1px solid var(--data-table-separator);
          border-left: 1px solid var(--data-table-separator);
          border-right: 1px solid var(--data-table-separator);
        }

        &.is-highlighted {
          background-color: var(--data-table-highlighted-column-odd);
        }

        &.is-highlighted-strong {
          background-color: var(--data-table-highlighted-column-odd-strong);
        }
      }
    }

    > tr.data-table-1-columns > th {
      text-align: right;
      color: var(--text-subtle);

      &.is-highlighted {
        background-color: var(--data-table-highlighted-column-odd);
      }

      &.is-highlighted-strong {
        background-color: var(--data-table-highlighted-column-odd-strong);
      }
    }
  }

  > tbody {
    //border-bottom: 1px solid var(--data-table-separator);

    > tr {
      &:nth-child(2n) {
        background: var(--data-table-row-even);

        .is-highlighted {
          background-color: var(--data-table-highlighted-column-even);
        }

        .is-highlighted-strong {
          background-color: var(--data-table-highlighted-column-even-strong);
        }
      }

      &:nth-child(2n + 1) {
        background: var(--data-table-row-odd);

        .is-highlighted {
          background-color: var(--data-table-highlighted-column-odd);
        }

        .is-highlighted-strong {
          background-color: var(--data-table-highlighted-column-odd-strong);
        }
      }

      > th {
        text-align: left;
        padding-right: 10px;
        border-right: 1px dotted var(--data-table-border);

        &.row-indent {
          font-weight: var(--font-regular);

          &::before {
            content: '... ';
            font-weight: var(--font-regular);
            opacity: 0.5;
          }

          &.row-indent-2 {
            &::before {
              content: '... ... ';
              font-weight: var(--font-regular);
              opacity: 0.5;
            }
          }
        }
      }

      > td {
        text-align: right;
        font-weight: var(--font-medium);
        border-right: 1px dotted var(--data-table-border);

        @include responsive.below(responsive.$re-analysis-monitor-1600) {
          font-weight: var(--font-bold);
        }

        &.value-sideways {
          color: var(--data-table-value-sideways);
          font-weight: var(--font-light);
        }

        &.value-up {
          color: var(--data-table-value-up);
          font-weight: var(--font-regular);

          &::before {
            content: '+';
          }
        }

        &.value-down {
          color: var(--data-table-value-down);
          font-weight: var(--font-regular);
        }

        &.value-percent {
          &::before {
            content: '';
          }
        }

        &.is-highlighted-up {
          animation: blink-up 1s linear 3;
          background-color: var(--data-table-highlight-up-default);

          @keyframes blink-up {
            0% {
              background-color: var(--data-table-highlight-up-default);
            }

            50% {
              background-color: var(--data-table-highlight-up-blink);
            }

            100% {
              background-color: var(--data-table-highlight-up-default);
            }
          }
        }

        &.is-highlighted-down {
          animation: blink-down 1s linear 3;
          background-color: var(--data-table-highlight-down-default);

          @keyframes blink-down {
            0% {
              background-color: var(--data-table-highlight-down-default);
            }

            50% {
              background-color: var(--data-table-highlight-down-blink);
            }

            100% {
              background-color: var(--data-table-highlight-down-default);
            }
          }
        }
      }

      &.row-separator-top {
        border-top: 1px solid var(--data-table-separator) !important;

        > td,
        th {
          border-top: 1px solid var(--data-table-separator) !important;
        }
      }

      &.row-separator-bottom {
        border-bottom: 1px solid var(--data-table-separator) !important;

        > td,
        th {
          border-bottom: 1px solid var(--data-table-separator) !important;
        }
      }

      &.row-spacing-top {
        th,
        td {
          padding-top: margins.$margin-small0;
        }
      }

      &.row-hover {
        &:hover {
          background: var(--data-table-row-hover);

          .is-highlighted,
          .is-highlighted-strong {
            background: var(--data-table-row-hover);
          }
        }

        > th,
        td {
          &:hover {
            background: var(--data-table-cell-hover);

            &.is-highlighted,
            &.is-highlighted-strong {
              background: var(--data-table-cell-hover);
            }
          }
        }
      }
    }
  }

  & {
    --data-table-border: var(--border-subtle);
    --data-table-separator: var(--border-subtle);

    --data-table-value-down: var(--text-data-down);
    --data-table-value-up: var(--text-data-up);
    --data-table-value-sideways: var(--text-subtle);

    --data-table-highlight-up-default: var(--bg-data-up);
    --data-table-highlight-down-default: var(--bg-data-down);
  }

  @include theme.variant(light) {
    --data-table-row-odd: var(--neutral-white);
    --data-table-row-even: var(--neutral-25);

    --data-table-row-hover: var(--neutral-50);
    --data-table-cell-hover: var(--neutral-100);

    --data-table-highlighted-column-even: var(--neutral-50);
    --data-table-highlighted-column-odd: var(--neutral-100);

    --data-table-highlighted-column-even-strong: var(--neutral-50);
    --data-table-highlighted-column-odd-strong: var(--neutral-100);

    --data-table-highlight-up-blink: var(--light-green);
    --data-table-highlight-down-blink: var(--light-pink);
  }

  @include theme.variant(dark) {
    --data-table-row-odd: var(--neutral-900);
    --data-table-row-even: var(--neutral-925);

    --data-table-row-hover: var(--neutral-800);
    --data-table-cell-hover: var(--neutral-700);

    --data-table-highlighted-column-even: var(--neutral-800);
    --data-table-highlighted-column-odd: var(--neutral-700);

    --data-table-highlighted-column-even-strong: var(--neutral-800);
    --data-table-highlighted-column-odd-strong: var(--neutral-700);

    --data-table-highlight-up-blink: var(--green);
    --data-table-highlight-down-blink: var(--pink);
  }
}
