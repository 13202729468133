@use 'sass:color';
@use 'styles/vars/palette';

@forward 'styles/vars/palette';

// Black and white
$color-black: rgba(8, 8, 8, 1);

$color-white: rgba(255, 255, 255, 1);
$color-offwhite: rgba(251, 247, 245, 1);

// Elements
$bg1-dark-mode: #101113;
$bg2-dark-mode: #15171b;
