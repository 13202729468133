// Grey
$color-grey-1: rgba(51, 51, 51, 1);
$color-grey-2: rgba(79, 79, 79, 1);
$color-grey-3: rgba(130, 130, 130, 1);
$color-grey-4: rgba(189, 189, 189, 1);
$color-grey-5: rgba(200, 200, 200, 1);
$color-grey-6: rgba(224, 224, 224, 1);
$color-grey-7: rgba(242, 242, 242, 1);

// Blue-grey from Refactoring UI
$color-blue-grey-1: hsl(209, 61%, 16%);
$color-blue-grey-2: hsl(211, 39%, 23%);
$color-blue-grey-3: hsl(209, 34%, 30%);
$color-blue-grey-4: hsl(209, 28%, 39%);
$color-blue-grey-5: hsl(210, 22%, 49%);
$color-blue-grey-6: hsl(209, 23%, 60%);
$color-blue-grey-7: hsl(211, 27%, 70%);
$color-blue-grey-8: hsl(210, 31%, 80%);
$color-blue-grey-9: hsl(212, 33%, 89%);
$color-blue-grey-0: hsl(210, 36%, 96%);

// Blue
$color-blue-1: hsla(206, 100%, 15.1%, 1);
$color-blue-2: hsla(206, 100%, 22.2%, 1);
$color-blue-3: hsla(206, 100%, 31.6%, 1);
$color-blue-4: hsla(206, 100%, 38.2%, 1);
$color-blue-5: hsla(206, 100%, 46.7%, 1);
$color-blue-6: hsla(206, 85.7%, 58.8%, 1);
$color-blue-7: hsla(206, 79%, 68.2%, 1);
$color-blue-8: hsla(206, 84.8%, 79.4%, 1);
$color-blue-9: hsla(206, 96.9%, 87.5%, 1);

// Green
$color-green-1: hsla(166, 100%, 12.5%, 1);
$color-green-2: hsla(166, 100%, 17.5%, 1);
$color-green-3: hsla(166, 98.3%, 22.5%, 1);
$color-green-4: hsla(166, 93.3%, 29.2%, 1);
$color-green-5: hsla(165, 87.7%, 35.1%, 1);
$color-green-6: hsla(166, 85.4%, 40.4%, 1);
$color-green-7: hsla(166, 57.5%, 56.7%, 1);
$color-green-8: hsla(166, 61.1%, 71.8%, 1);

// Red
$color-red-1: hsla(0, 84.6%, 22.9%, 1);
$color-red-2: hsla(0, 57.4%, 35.9%, 1);
$color-red-3: hsla(0, 52%, 44.1%, 1);
$color-red-4: hsla(0, 56.9%, 51.8%, 1);
$color-red-5: hsla(0, 61.8%, 55.9%, 1);
$color-red-6: hsla(0, 78.7%, 63.1%, 1);
$color-red-7: hsla(0, 87%, 72.9%, 1);
$color-red-8: hsla(0, 95.2%, 83.7%, 1);
$color-red: $color-red-6;

// Purple
$color-purple-1: hsla(271, 85.6%, 21.8%, 1);
$color-purple-2: hsla(271, 58%, 30.8%, 1);
$color-purple-3: hsla(271, 42.7%, 41.8%, 1);
$color-purple-4: hsla(271, 48.8%, 51%, 1);
$color-purple-5: hsla(271, 69.8%, 59.8%, 1);
$color-purple-6: hsla(271, 77.5%, 70.4%, 1);
$color-purple-7: hsla(272, 85.1%, 81.6%, 1);
$color-purple-8: hsla(271, 92.7%, 89.2%, 1);

// Pink
$color-pink-1: hsla(327, 96.3%, 21.4%, 1);
$color-pink-2: hsla(327, 81%, 37.1%, 1);
$color-pink-3: hsla(328, 62.1%, 49.6%, 1);
$color-pink-4: hsla(328, 59.1%, 63.5%, 1);
$color-pink-5: hsla(327, 54.5%, 71.6%, 1);
$color-pink-6: hsla(328, 54.2%, 81.2%, 1);
$color-pink-7: hsla(327, 68.6%, 90%, 1);

// Orange
$color-orange-1: hsla(28, 100%, 25.4%, 1);
$color-orange-2: hsla(28, 100%, 32.9%, 1);
$color-orange-3: hsla(28, 98.3%, 45.9%, 1);
$color-orange-4: hsla(28, 86.6%, 62%, 1);
$color-orange-5: hsla(28, 79.4%, 69.6%, 1);
$color-orange-6: hsla(28, 78.6%, 78%, 1);
$color-orange-7: hsla(28, 82.9%, 86.3%, 1);
$color-orange-8: hsla(28, 85%, 90%, 1);
$color-orange: $color-orange-4;

// Yellow
$color-yellow-1: hsla(45, 100%, 22.9%, 1);
$color-yellow-2: hsla(45, 100%, 35.9%, 1);
$color-yellow-3: hsla(45, 93.6%, 49%, 1);
$color-yellow-4: hsla(45, 86.5%, 62.4%, 1);
$color-yellow-5: hsla(45, 87.6%, 68.4%, 1);
$color-yellow-6: hsla(45, 91.8%, 80.8%, 1);
$color-yellow-7: hsla(45, 95.7%, 90.8%, 1);
$color-yellow: $color-yellow-4;

// Cyan
$color-cyan-1: hsla(179, 93.6%, 18.4%, 1);
$color-cyan-2: hsla(179, 97.1%, 27.5%, 1);
$color-cyan-3: hsla(179, 96.9%, 37.8%, 1);
$color-cyan-4: hsla(179, 93.5%, 42.5%, 1);
$color-cyan-5: hsla(178, 74.7%, 61.2%, 1);
$color-cyan-6: hsla(178, 67.6%, 73.3%, 1);
$color-cyan-7: hsla(180, 63.8%, 81.6%, 1);
