@use 'styles/vars/margins';
@use 'styles/mixins/responsive';

.chart-creator {
  display: flex;

  .chart-creator-left {
    flex: 1.5;
    padding-right: margins.$margin-default;
    margin-right: margins.$margin-default;
    border-right: 1px dashed var(--border-subtle);
  }

  .chart-creator-right {
    flex: 1;
  }

  @include responsive.below-desktop {
    flex-direction: column;

    .chart-creator-left {
      padding-right: 0;
      margin-right: 0;
      border-right: none;
    }
  }
}
