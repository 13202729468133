@use 'styles/vars/margins';
@use 'styles/vars/palette';
@use 'styles/vars/fonts';
@use 'styles/vars/selectors';
@use 'styles/mixins/rounded-borders';
@use 'styles/mixins/theme';

.default-selector {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: selectors.$selector-height;
  padding: 0 calc((#{selectors.$selector-height} - 2em) / 2);
  background-color: var(--selector-bg);
  border-radius: var(--radius-md);

  .default-selector-item {
    display: block;
    padding: 0 margins.$margin-small2;
    color: var(--text-subtle);
    font-weight: fonts.$font-weight-medium;
    text-decoration: none;
    white-space: nowrap;
    line-height: 2em;
    cursor: pointer;
    border-radius: var(--radius-md);

    &:hover {
      color: var(--text-default);
      position: relative;
      z-index: 2;
    }

    &.is-selected {
      color: var(--text-default);
      background: var(--selector-accent);
    }

    &.is-disabled {
      color: var(--text-disabled);
      opacity: 0.45;
      cursor: default;
    }

    &.invisible {
      display: none;
    }
  }

  &.is-auto {
    display: inline-flex;
  }
}
