@use 'styles/vars/margins';
@use 'styles/mixins/responsive';
@use 'styles/mixins/theme';

.small-top-bar {
  background-color: var(--small-top-bar-bg);
  color: var(--text-subtle);

  .small-top-bar-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: responsive.$re-analysis-full-1920;
    margin: 0 auto;
    gap: var(--space-md);
    padding: var(--space-md) var(--space-lg);
    min-height: 3em;
  }

  &.is-center {
    .small-top-bar-content {
      justify-content: center;
    }
  }

  @include responsive.below-tablet() {
    .small-top-bar-content {
      padding: var(--space-md);
      align-items: flex-start;
      flex-direction: column;
    }
  }

  & {
    --small-top-bar-bg: var(--neutral-925);

    @include theme.variant(light) {
      --small-top-bar-bg: var(--neutral-100);
    }
  }
}
