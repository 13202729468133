@use 'styles/mixins/theme';
@use 'styles/vars/palette';

// @see https://10015.io/tools/css-loader-generator

.pulse-map-spinner {
  width: 44.8px;
  height: 44.8px;
  position: relative;
  transform: rotate(45deg);

  &:before,
  &:after {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 50% 50% 0 50%;
    background: var(--bg-brand);
    -webkit-mask: radial-gradient(circle 11.2px at 50% 50%, #0000 94%, #000);
  }

  &:after {
    animation: pulse-ytk0dhmd 1s infinite;
    transform: perspective(336px) translateZ(0px);
  }

  @keyframes pulse-ytk0dhmd {
    to {
      transform: perspective(336px) translateZ(168px);
      opacity: 0;
    }
  }
}
