@use 'styles/vars/margins';
@use 'styles/vars/fonts';

.code-block {
  position: relative;

  &.code-block-wrap {
    pre,
    code {
      white-space: pre-wrap;
      word-break: break-all;
    }
  }

  .code-block-note {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: margins.$margin-small2;
    border-top: 1px solid var(--border-subtle);
    padding-top: margins.$margin-default;

    p {
      margin: 0;
      color: var(--text-subtle);
    }
  }

  .code-block-copy-icon {
    display: flex;
    gap: margins.$margin-small3;
    align-items: center;
    cursor: pointer;
    color: var(--text-subtle);

    &:hover {
      color: var(--text-default);
    }

    > span {
      font-size: fonts.$font-small;
    }
  }
}

.raw-block {
  .raw-block-content {
    padding: margins.$margin-default;
  }
}
