.video-js {
  button.vjs-big-play-button {
    height: 64px !important;
    width: 64px !important;
    border-radius: 32px !important;

    top: 50%;
    left: 50%;

    margin-top: -32px;
    margin-left: -32px;

    padding: 0 !important;

    border: 2px solid rgba(255, 255, 255, 0.7) !important;

    &:hover {
      border: 2px solid rgba(255, 255, 255, 0.95) !important;
    }

    > span.vjs-icon-placeholder {
      &::before {
        width: 60px !important;
        height: 60px !important;

        font-size: 30px !important;
        line-height: 60px !important;
      }
    }
  }

  .vjs-playback-rate {
    .vjs-playback-rate-value {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 11px;
    }
  }
}
