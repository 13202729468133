@use 'sass:math';
@use 'styles/vars/margins';
@use 'styles/vars/fonts';
@use 'styles/vars/zindex';
@use 'styles/vars/palette';
@use 'styles/mixins/responsive';
@use 'styles/mixins/theme';
@use 'styles/mixins/rounded-borders';
@use 'styles/mixins/shadows';

$-sidebar-width: 300px;
$-header-height: 50px;

.outages-map-page {
  position: relative;
  height: 100vh;

  .mapboxgl-map {
    position: absolute !important;
    width: auto !important;
    height: auto !important;
    top: var(--space-md);
    left: var(--space-md);
    right: var(--space-md);
    bottom: var(--space-10xl);
    @include rounded-borders.border-radius();

    @include responsive.below(responsive.$breakpoint-mobile) {
      top: 0;
      left: 0;
      right: 0;
      bottom: var(--space-10xl);
      width: 100% !important;
      margin-left: 0;
    }
  }

  .selector-wrapper-wide-screen {
    position: absolute;
    top: var(--space-xl);
    left: var(--space-xl);
    padding: margins.$margin-default;
    width: $-sidebar-width;
    background: var(--bg-main-container);
    box-shadow: var(--shadow-lg);
    z-index: 4;
    overflow-y: auto;

    @include rounded-borders.border-radius();

    h2 {
      font-size: 1.4em;
    }

    h3 {
      font-size: 1.2em;
    }

    label {
      font-size: var(--font-xs);
    }
  }

  .selector-wrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: margins.$margin-small3;
    width: 100%;
    padding: margins.$margin-default;
    z-index: 4;

    .area-selector-simple {
      width: 100%;

      .area-selector-card-wrapper {
        position: absolute;
        margin-top: margins.$margin-small2;
      }

      @include responsive.below(responsive.$breakpoint-mobile) {
        // Overwrite default values
        .dropdown-wrapper {
          width: 100%;
          z-index: 5;

          .area-selector-card-wrapper {
            width: 100%;
            margin-left: -3px;
          }
        }
      }
    }

    @include responsive.below(responsive.$breakpoint-mobile) {
      // Overwrite default values
      .multiple-selector {
        width: 100%;

        .multiple-selector-card-wrapper {
          .multiple-selector-card {
            width: 100%;
          }
        }
      }
    }
  }

  .error-popover {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    gap: margins.$margin-default;
    padding: margins.$margin-default margins.$margin-large2;
    background-color: var(--bg-error);
    color: var(--text-on-error);
    font-size: var(--font-xs);
    @include rounded-borders.border-radius();

    > svg {
      flex-shrink: 0;
      font-size: 1.75em;
    }

    > span {
      flex: 1;
    }
  }
}
