@use 'styles/vars/margins';
@use 'styles/vars/zindex';
@use 'styles/vars/colors';
@use 'styles/vars/palette';
@use 'styles/mixins/rounded-borders';
@use 'styles/mixins/theme';
@use 'styles/mixins/floating-ui';
@use 'styles/mixins/shadows';

.forecast-schedule-bar-instance {
  position: absolute;
  height: 1rem;
  white-space: nowrap;
  transition: transform 100ms;
  z-index: 2;
  @include rounded-borders.border-radius();

  @for $lane from 1 through 10 {
    &.lane-#{$lane} {
      top: (1.2rem + 0.09rem) * $lane;
    }
  }

  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
}

.forecast-schedule-bar-instance-popover {
  background-color: var(--popover-background);
  padding: var(--space-xl);
  z-index: zindex.$z-index-element-mouseovers;
  min-width: 20rem;
  max-width: 30rem;
  box-shadow: var(--shadow-lg);
  @include rounded-borders.border-radius();

  h4 {
    margin: 0 0 var(--space-md) 0;
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  .arrow {
    @include floating-ui.arrow(var(--popover-background));
  }

  & {
    --popover-background: var(--neutral-700);

    @include theme.variant(light) {
      --popover-background: var(--neutral-white);
    }
  }
}
