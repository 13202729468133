@use 'styles/vars/margins';
@use 'styles/vars/fonts';
@use 'styles/mixins/rounded-borders';
@use 'styles/mixins/shadows';
@use 'styles/vars/palette';

nav.settings-page {
  display: block;
  overflow: hidden;
  min-width: 200px;

  // TODO Use card style as basis?
  //background: var(--bg1);
  box-shadow: var(--shadow-lg);

  @include rounded-borders.border-radius;

  ul,
  li {
    display: block;
    margin: 0;
    padding: 0;
  }

  h4 {
    font-size: var(--font-xxs);
    padding-left: var(--space-lg);

    margin-bottom: var(--space-sm);
    color: var(--text-default);

    text-transform: uppercase;
  }

  a,
  a:link {
    display: block;
    padding: var(--space-md) var(--space-lg);
    text-decoration: none;

    color: var(--text-default);

    &:hover {
      background: var(--bg-hover);
      color: var(--text-urls);
    }

    &.active {
      color: var(--text-urls);
      font-weight: var(--font-bold);
      background: var(--bg-selected);
    }
  }
}
