.curve-fetch-examples-table {
  border: 1px solid var(--border-subtle);

  th {
    user-select: none;
  }

  tbody {
    th {
      width: 20%;
    }
  }
}
