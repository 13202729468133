@mixin -selector($selector) {
  @at-root #{$selector} #{if(&, '&', '')} {
    @content;
  }
}

@mixin variant($variant) {
  //@media (prefers-color-scheme: $variant) {
  //  @include -selector(':root') {
  //    @content;
  //  }
  //}

  // User selected
  @include -selector('html[color-scheme=#{$variant}]') {
    @content;
  }
}
