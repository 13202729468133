@use 'styles/vars/fonts';
@use 'styles/vars/palette';
@use 'styles/mixins/rounded-borders';
@use 'styles/mixins/theme';

.tln-active,
.tln-wrapper,
.tln-line {
  margin: 0;
  //border: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  vertical-align: middle;
  list-style: none;
}

.tln-active {
  display: inline-block;
  padding: 0.625em;
  width: calc(100% - 4em);
  height: 100%;
  font-size: 1em;
  line-height: 1.5;
  font-family: fonts.$font-family-mono;
  word-break: break-all;
  resize: none;
  overflow-wrap: normal;
  overflow-x: auto;
  white-space: pre;
}

.tln-wrapper {
  width: 4em;
  padding: 0.6875em 0 2.1875em;
  height: 100%;
  word-break: break-all;
  overflow: hidden;
  display: inline-block;
  counter-reset: line;
  font-family: fonts.$font-family-mono;
}

.tln-line {
  width: 100%;
  display: block;
  text-align: right;
  line-height: 1.5;
  font-size: 1em;
  color: var(--text-subtle);
  padding-right: 0.3125em;

  &::before {
    counter-increment: line;
    content: counter(line);
    font-size: 1em;
    user-select: none;
  }

  &.tln-line-error {
    background-color: var(--bg-error);
    color: var(--text-on-error);
    @include rounded-borders.border-radius();
  }
}
