@use 'styles/vars/margins';

.production-stacked-legend {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.75em 1.25em;
  font-size: 0.75em;
  padding: var(--space-md);

  .legend-item {
    display: flex;
    gap: 0.35rem;
    align-items: center;

    .item-color {
      flex-shrink: 0;
      width: 1em;
      height: 1em;
      border-radius: 2px;
    }

    .item-label {
      opacity: 0.75;
      line-height: 0;
    }
  }
}
