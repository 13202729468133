@use 'styles/vars/margins';

.chart-creator-chart {
  margin-bottom: margins.$margin-default;

  .card-content {
    //min-height: 225px;
  }

  .chart-error,
  .chart-message {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--text-subtle);
  }

  .chart-error {
    color: var(--text-error);
  }

  .chart-divider {
    border-top: 1px dashed var(--border-subtle);
    margin: 0 0 margins.$margin-small2 0;
  }
}
