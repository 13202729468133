@use 'styles/vars/margins';

@forward 'settings-header';
@forward 'settings-nav';
@forward 'settings-main';

.settings-page-width {
  max-width: 800px;

  padding-left: var(--space-lg);
  padding-right: var(--space-lg);

  margin: 0 auto;
}

div.settings-page {
  display: flex;
  align-items: flex-start;

  gap: var(--space-lg);
  margin-top: var(--space-xl);
  margin-bottom: var(--space-xl);
}
