@use 'styles/vars/margins';
@use 'styles/mixins/responsive';

.outage-map-popover {
  max-width: 100%;
  width: 600px;
  background-color: var(--bg-card);
  box-shadow: var(--shadow-lg);

  .outage-map-popover-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--space-lg);
    padding: var(--space-sm) var(--space-md) var(--space-lg) var(--space-md);

    h3 {
      margin: 0;
    }

    .header-actions {
      display: flex;
      align-items: center;
      gap: var(--space-md);

      > svg {
        opacity: 0.5;
        cursor: pointer;
        font-size: 2em;
        width: 1em;
        height: 1em;
      }
    }
  }

  .outage-map-popover-body {
    padding: var(--space-lg);
    max-height: 70vh;
    overflow-y: auto;
  }

  @include responsive.below-desktop {
    max-width: none;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;

    .outage-map-popover-header {
      flex-direction: column;

      .header-actions-close {
        position: absolute;
        top: 1em;
        right: 1em;
      }
    }

    .outage-map-popover-body {
      flex: 1;
      max-height: none;
    }
  }
}
