@mixin arrow($background-color, $border-color: null) {
  fill: $background-color;

  @if $border-color {
    > path {
      &:first-of-type {
        stroke: $border-color;
      }

      &:last-of-type {
        stroke: $background-color;
      }
    }
  }
}
