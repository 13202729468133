@use 'styles/vars/margins';
@use 'styles/mixins/responsive';

.local-forecasts-page {
  .local-forecasts-content,
  .local-forecasts-tabs {
    $margin: margins.$margin-default;

    max-width: responsive.$re-analysis-full-1920;
    padding: margins.$margin-small4 margins.$margin-default;
    margin: 0 auto;

    .eq-react-select__control {
      min-width: 200px;
    }
  }
}
