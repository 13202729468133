@use 'styles/vars/margins';
@use 'styles/vars/fonts';

.local-forecasts-card {
  hr {
    margin: margins.$margin-small2 0;
  }

  .local-forecasts-card-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 225px;
  }

  .local-forecasts-card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .footer-labels {
      display: flex;
      flex-wrap: wrap;
      gap: margins.$margin-small2 margins.$margin-default;
      font-size: fonts.$font-small;
    }
  }

  .local-forecasts-card-curves {
    margin: 0;
    padding: 0;
    list-style: none;

    a,
    a:hover,
    a:active {
      color: inherit;
      text-decoration-color: inherit;
    }
  }
}
