@use 'styles/vars/margins';

.available-capacities-selector {
  .available-capacity {
    display: flex;
    align-items: center;
    gap: margins.$margin-small2;

    .capacity-dot {
      height: 10px;
      width: 10px;
      border-radius: 50%;
      flex-shrink: 0;
    }
  }
}
