@use 'sass:color';

@use 'styles/mixins/rounded-borders';
@use 'styles/mixins/theme';
@use 'styles/vars/colors';
@use 'styles/vars/palette';
@use 'styles/vars/fonts';

.curve-ensembles-chips {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5em;

  .curve-ensembles-chips-chip {
    flex-shrink: 0;
    border-radius: 10rem;
    padding: 0.25rem 0.75rem;
    font-size: fonts.$font-small;
    background-color: var(--chip-background);
    cursor: pointer;

    &:hover {
      background-color: var(--chip-hover-background);
    }

    &.is-selected {
      background-color: var(--bg-brand);
      color: var(--text-on-brand);
    }

    &.is-disabled {
      opacity: 0.5;
      background-color: var(--chip-background);
      color: var(--text-default);
      pointer-events: none;
      cursor: default;
    }
  }

  @include theme.variant(light) {
    --chip-background: var(--neutral-50);
    --chip-hover-background: var(--neutral-100);
  }

  @include theme.variant(dark) {
    --chip-background: var(--neutral-800);
    --chip-hover-background: var(--neutral-700);
  }
}
