@use 'sass:math';
@use 'styles/vars/margins';
@use 'styles/vars/zindex';
@use 'styles/mixins/shadows';
@use 'styles/vars/colors';
@use 'styles/mixins/responsive';

@use './NavigationProps';

@forward 'NavigationMenu';
@forward 'ProfileMenu';

.top-bar {
  display: flex;
  align-items: center;
  height: NavigationProps.$top-bar-height;
  background: var(--bg-main-container);
  padding: 0 var(--space-md);

  .top-bar-bento {
    margin-right: var(--space-md);
  }

  .top-bar-logo-menu {
    display: flex;
    align-items: center;
    flex-grow: 1;

    .top-bar-logo {
      align-items: center;
      flex-shrink: 0;
      font-size: var(--font-md);
      color: var(--text-default);
      margin-right: var(--space-md);
      line-height: 0;

      svg {
        height: var(--size-xxl)
      }
    }

    .top-bar-menu {
      flex-grow: 1;
    }
  }

  .top-bar-profile {
    margin-left: auto;
  }

  @include responsive.below-desktop {
    .top-bar-logo-menu {
      .top-bar-menu {
        flex-grow: initial;
      }
    }
  }
}
