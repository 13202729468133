@use 'sass:color';

@use 'sass:math';
@use 'styles/vars/margins';
@use 'styles/vars/colors';
@use 'styles/mixins/responsive';
@use 'styles/mixins/rounded-borders';
@use 'styles/mixins/shadows';

.dialog-page > .dialog-card {
  background: var(--neutral-white);
  padding: var(--space-5xl) var(--space-7xl);
  max-width: 640px;
  box-shadow: var(--shadow-lg);
  @include rounded-borders.border-radius;

  @include responsive.below(responsive.$breakpoint-mobile-1) {
    padding: var(--space-xxl) var(--space-4xl);
  }

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  &.dialog-small {
    max-width: 480px;
  }

  &.dialog-two-columns {
    padding: 0;
    max-width: 1200px;

    overflow: hidden;

    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;

    .show-in-mobile {
      display: none;
    }

    @include responsive.below(responsive.$breakpoint-mobile-2) {
      flex-direction: column-reverse;

      .show-in-mobile {
        display: block;
      }
    }

    > .dialog-column {
      flex: 0.5;
      flex-basis: 100%;

      display: flex;

      flex-direction: column;
      justify-content: center;

      padding: var(--space-7xl);

      @include responsive.below(responsive.$breakpoint-mobile-1) {
        padding: var(--space-4xl);
      }

      > *:first-child {
        margin-top: 0;
      }

      > *:last-child {
        margin-bottom: 0;
      }
    }

    > .dialog-column:first-child {
      background: rgba(4, 30, 57, 1);

      @include responsive.below(responsive.$breakpoint-mobile-1) {
        padding-top: var(--space-7xl);
      }

      * {
        color: rgba(colors.$color-white, 0.9);
      }

      ul {
        padding-left: 0;

        li {
          list-style: none;
          padding: var(--space-lg);
          background-color: color.adjust(
            rgba(4, 30, 57, 1),
            $lightness: -2.5%,
            $space: hsl
          );
          @include rounded-borders.border-radius(8px);
        }
      }
    }
  }

  img.illustration {
    width: 70%;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &.width-90 {
      width: 90%;
    }

    &.margin-bottom {
      margin-bottom: var(--space-xxl);
    }

    &.margin-top {
      margin-top: var(--space-xxl);
    }
  }
}

.dialog-page > .dialog-double-card {
  background: var(--neutral-white);
  padding: var(--space-7xl);

  box-shadow: var(--shadow-lg);
  @include shadows.drop-shadow-default;
}
