@use 'styles/mixins/rounded-borders';

@mixin simple() {
  &::-webkit-scrollbar {
    height: 10px;
    width: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--border-subtle);
    @include rounded-borders.border-radius-right();
    @include rounded-borders.border-radius-left();
  }
}
