@use 'styles/vars/margins';
@use 'styles/mixins/rounded-borders';

.map-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  display: inline-flex;
  flex-direction: column;
  gap: margins.$margin-large2;
  align-items: center;
  justify-content: center;
  opacity: 0;

  // Fade in content after 300ms,
  // to prevent flickering when content loads fast
  animation: 300ms ease-in 300ms 1 forwards appear;

  @keyframes appear {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}
