@use 'styles/vars/colors';
@use 'styles/vars/palette';
@use 'styles/vars/fonts';
@use 'styles/vars/margins';
@use 'styles/mixins/rounded-borders';

.live-badge {
  display: inline-flex;
  align-items: center;
  background: var(--bg-error);
  color: var(--text-on-error);
  font-weight: var(--font-medium);
  padding: var(--size-xs) var(--size-sm);
  @include rounded-borders.border-radius();

  > span {
    padding-left: var(--size-sm);
  }

  > svg {
    position: relative;
    stroke-width: 0.6px;
    transform: scale(1.3);
  }
}
