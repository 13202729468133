@use 'styles/vars/margins';
@use 'styles/vars/palette';
@use 'styles/vars/fonts';
@use 'styles/mixins/rounded-borders';
@use 'styles/mixins/theme';

.trial-message-popup {
  h2 {
    margin-top: 0;
  }

  .contract {
    padding: margins.$margin-default;
    border: 2px solid var(--contract-border);
    background-color: var(--contract-background);
    margin-bottom: margins.$margin-default;
    @include rounded-borders.border-radius(6px);

    .duration {
      margin-bottom: margins.$margin-default;
    }

    .accesses {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: margins.$margin-small3;
    }
  }

  @include theme.variant(dark) {
    --contract-border: #{palette.$color-green-2};
  }

  @include theme.variant(light) {
    --contract-border: #{rgba(palette.$color-green-2, 0.6)};
  }
}
