@use 'styles/vars/margins';
@use 'styles/vars/palette';
@use 'styles/vars/fonts';
@use 'styles/mixins/rounded-borders';
@use 'styles/mixins/responsive';

.key-figure-card {
  position: relative;
  padding: margins.$margin-large4;
  overflow: hidden;

  .figure-intro {
    margin-left: margins.$margin-large4;
    margin-bottom: margins.$margin-default;
  }

  .figure-value {
    display: flex;
    align-items: flex-end;
    gap: 1rem;
    margin-left: margins.$margin-large4;

    .figure-value-number {
      font-size: 5em;
      font-weight: 500;
      line-height: 1;
    }

    .figure-value-postfix {
      font-size: 1.5em;
      color: var(--text-subtle);
      margin-bottom: 0.25rem;
    }
  }

  .figure-value-loading {
    width: 50%;
    height: 4.5rem;
    background: linear-gradient(270deg, var(--border-default), var(--border-subtle));
    background-size: 200%;
    animation: LoadingGradient 1.5s ease infinite;
    @include rounded-borders.border-radius();
  }

  .figure-icon {
    position: absolute;
    z-index: -1;
    top: 50%;
    left: margins.$margin-default;
    transform: translateY(-50%);
    font-size: 10rem;
    opacity: 0.15;
    color: var(--text-subtle);
    line-height: 0;

    > svg {
      width: 1em;
      height: 1em;
      line-height: 0;
    }
  }

  @include responsive.below-tablet {
    padding: margins.$margin-large2;

    .figure-intro {
      width: 100%;
    }

    .figure-value {
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;

      .figure-value-number {
        font-size: 3rem;
      }
    }

    .figure-icon {
      font-size: 6rem;
    }
  }

  @keyframes LoadingGradient {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
}
