@use 'styles/vars/margins';
@use 'styles/vars/palette';
@use 'styles/mixins/theme';

.changelog-item-card {
  background-color: var(--changelog-item-bg);
  border-radius: var(--radius-lg);
  padding: var(--space-lg);

  .changelog-card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .changelog-date {
      color: var(--text-subtle);
      font-weight: var(--font-semibold);
    }

    .changelog-tag {
      padding: var(--space-xxs) var(--space-md);
      border-radius: var(--radius-full);

      &.new-feature {
        background-color: var(--bg-success);
        color: var(--text-on-success);
      }
      &.improved {
        background-color: var(--bg-warning);
        color: var(--text-on-warning);
      }
      &.fixed {
        background-color: var(--changelog-item-fixed-bg);
        color: var(--changelog-item-fixed-text);
      }
      &.information {
        background-color: var(--bg-info);
        color: var(--text-on-info);
      }
      &.default {
        background-color: var(--bg-subtle);
      }
    }
  }

  .changelog-card-description {
    :last-child {
      margin-bottom: 0 !important;
    }

    img {
      max-width: 100%;
    }
  }

  & {
    --changelog-item-bg: var(--neutral-800);
    --changelog-item-fixed-bg: var(--purple);
    --changelog-item-fixed-text: var(--text-on-dark);

    @include theme.variant(light) {
      --changelog-item-bg: var(--neutral-50);
    }
  }
}
