@use 'styles/vars/selectors';
@use 'styles/vars/palette';

@forward 'SingleDropdownPopup';

.dropdown-selector {
  $dropdown-min-width: 140px;
  $dropdown-min-width-large: 200px;

  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  min-width: $dropdown-min-width;
  height: selectors.$selector-height;

  background-color: var(--selector-bg);
  border-radius: var(--radius-md);

  cursor: pointer;

  &.dropdown-auto {
    min-width: auto;
  }

  &.dropdown-large {
    min-width: $dropdown-min-width-large;
  }

  &.is-disabled {
    opacity: 0.45;
    cursor: default;
  }
}
