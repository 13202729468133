@use 'styles/mixins/responsive';
@use 'styles/vars/margins';
@use 'styles/vars/fonts';
@use 'styles/vars/palette';

.latest-market-comment {
  padding: 0 margins.$margin-default;
  max-width: 600px;

  > h3 {
    margin: 0 0 margins.$margin-large0 0;
  }

  @include responsive.below(responsive.$re-analysis-tablet) {
    padding: margins.$margin-large3 margins.$margin-default;
    max-width: none;
  }

  .comment-content {
    h1,
    h2,
    h3,
    h4 {
      margin: margins.$margin-default 0;
    }

    h2,
    h3 {
      font-size: fonts.$font-large;
    }
  }
}
