@use 'styles/vars/margins';

.weathermaps-wrapper {
  max-width: 1400px;
  height: 750px;
  margin: auto;

  margin-top: margins.$margin-large3;

  border-radius: 10px;

  overflow-y: hidden;
  overflow-x: auto;

  .weathermaps {
    display: block;

    height: 1000px;
    width: 1400px;

    padding: 0;
    margin: 50px auto;
    margin-top: -40px;

    background: #414b56;
    border: 0;
  }
}
