@use 'styles/vars/margins';
@use 'styles/mixins/responsive';

.selectors-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: margins.$margin-small1;
  margin: margins.$margin-default 0 margins.$margin-large1 0;

  &.border-bottom {
    padding-bottom: margins.$margin-default;
    border-bottom: 1px solid var(--border-subtle);
  }

  &.is-center {
    justify-content: center;
  }

  ~ .selectors-wrapper {
    margin-top: calc(var(--space-md) * -1);
  }

  .selectors-group {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: margins.$margin-small3;
  }
}
