@use 'styles/vars/margins';
@use 'styles/vars/breakpoints';
@use 'styles/vars/palette';
@use 'styles/mixins/theme';
@use 'styles/mixins/rounded-borders';
@use 'styles/mixins/responsive';

.river-temperatures-wrapper {
  $margin: margins.$margin-default;

  max-width: calc(#{responsive.$re-analysis-full-1920} + (#{$margin * 2}));
  padding: $margin;
  margin: 0 auto;

  @include responsive.below-tablet() {
    padding: margins.$margin-small2;
  }

  .river-temperatures-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include responsive.below-tablet() {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .mapboxgl-map-wrapper {
    margin-top: margins.$margin-default;
    position: relative;

    @include responsive.below-tablet() {
      margin-left: -#{margins.$margin-small2};
      margin-right: -#{margins.$margin-small2};
    }

    .mapboxgl-map {
      overflow: visible;

      .mapboxgl-marker {
        cursor: pointer;

        > div {
          display: flex;
          flex-direction: column;
          align-items: center;
          transform: scale(2);

          &.is-selected {
            border: 2px solid var(--text-brand);
            border-radius: 50%;
          }

          .warning-triangle {
            position: absolute;
            transform: scale(0.5) translate(10px, -10px);
          }

          .warning-triangle-outline {
            position: absolute;
            transform: scale(0.5714285714285714) translate(9px, -9px);
            color: var(--bg-warning);
          }
        }
      }

      .popup-window {
        z-index: 999 !important;
        background-color: transparent !important;

        .popup-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: margins.$margin-default;

          h3 {
            margin: 0;
          }

          svg {
            font-size: 1.5rem;
            cursor: pointer;
          }

          .map-popup-window-close-icon {
            margin-left: 10px;

            &:hover {
              color: var(--text-brand);
            }
          }
        }

        .mapboxgl-popup-tip {
          width: 10px;
          height: 10px;
          background-color: transparent;
          border: 0 !important;
        }

        .mapboxgl-popup-content {
          background-color: var(--bg-card) !important;
          border: none;
          box-shadow: var(--shadow-lg);
          @include rounded-borders.border-radius;
        }
      }
    }
  }

  .selector-wrapper {
    position: relative;
    display: flex;
    justify-content: center;

    top: -50px;
    z-index: 100;

    .default-selector {
      box-shadow: var(--shadow-lg);
    }
  }

  .explanation-legend-desktop {
    .explanation-legend {
      position: absolute;
      display: flex;
      flex-direction: column;

      padding: var(--space-lg);

      top: var(--space-md);
      left: var(--space-md);

      background-color: var(--bg-main-container);
      box-shadow: var(--shadow-lg);
      z-index: 3;

      @include rounded-borders.border-radius();

      > h4 {
        margin-top: 0;
      }

      .legend-text {
        display: flex;
        align-items: center;
        gap: margins.$margin-default;

        > div {
          margin: margins.$margin-small3 0;
        }

        &:last-child {
          > div {
            margin-bottom: 0;
          }
        }

        .indicator-dot {
          height: 10px;
          width: 10px;
          border-radius: 50%;
          display: inline-block;
          flex-shrink: 0;
        }
      }
    }
  }

  .explanation-legend-mobile {
    .explanation-legend {
      position: static;
      display: none;

      top: margins.$margin-default;
      left: margins.$margin-default;

      background-color: var(--bg-main-container);
      border-radius: var(--radius-md);
      z-index: 3;

      column-gap: margins.$margin-large4;

      padding: 0 margins.$margin-small2;
      margin-left: margins.$margin-small4;
      margin-right: margins.$margin-small4;

      transform: translateY(0px);

      .legend-text {
        display: flex;
        align-items: center;
        gap: margins.$margin-default;
        margin: 0;

        > div {
          margin: margins.$margin-small3 0;
        }

        .indicator-dot {
          height: 10px;
          width: 10px;
          border-radius: 50%;
          display: inline-block;
          flex-shrink: 0;
        }
      }
    }
  }

  @include responsive.below-tablet {
    .explanation-legend-desktop {
      .explanation-legend {
        display: none;
      }
    }
    .explanation-legend-mobile {
      .explanation-legend {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        > h4 {
          grid-column: span 2;
        }
      }
    }
  }
}
