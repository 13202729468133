@use 'styles/vars/fonts';

// Font type

.font-monospace {
  font-family: fonts.$font-family-mono;
}

// Font weight helpers

.font-light {
  font-weight: fonts.$font-weight-light !important;
}

.font-normal {
  font-weight: fonts.$font-weight-normal !important;
}

.font-medium {
  font-weight: fonts.$font-weight-medium !important;
}

.font-bold {
  font-weight: fonts.$font-weight-bold !important;
}

// Text adjustment helpers

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-nowrap {
  white-space: nowrap;
}

.font-larger {
  font-size: fonts.$font-larger !important;
}

.font-tiny {
  font-size: fonts.$font-smaller !important;
}

.font-smaller {
  font-size: fonts.$font-smaller !important;
}

.font-small {
  font-size: fonts.$font-small !important;
}

.font-subheader {
  font-size: fonts.$font-subheader !important;
}

// Font styles

.font-style-normal {
  font-style: normal !important;
}

.font-style-italic {
  font-style: italic !important;
}

// Text subtle

.text-default {
  color: var(--text-default);
}

.text-subtle {
  color: var(--text-subtle);
}

// Text states

.text-error {
  color: var(--text-error) !important;
}

.text-success {
  color: var(--text-success);
}

// Text short
.text-short,
span[title] {
  text-decoration: underline;
  text-decoration-style: dotted;
  text-decoration-thickness: 1px;
}
