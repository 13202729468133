@use 'sass:color';
@use 'styles/mixins/theme';
@use 'styles/vars/palette';

.outages-section-wrapper {
  .mapboxgl-marker {
    cursor: pointer;

    .outage-map-marker {
      display: flex;
      flex-direction: column;
      align-items: center;

      > svg {
        width: 1.5rem;
        height: 1.5rem;
        color: var(--text-subtle);
        filter: drop-shadow(0 0 1px rgb(0 0 0 / 0.8));

        &:hover {
          color: var(--text-default);
        }

        @include theme.variant('light') {
          filter: drop-shadow(0 0 1px rgb(255 255 255 / 0.8));
        }
      }
    }
  }
}
