@use 'styles/vars/margins';
@use 'styles/vars/colors';
@use 'styles/vars/palette';
@use 'styles/mixins/responsive';
@use 'styles/vars/fonts';
@use 'styles/vars/borders';

@forward '../AreaMapWithTree/AreaMapWithTree';

.area-selector {
  //padding: var(--space-md);
  //background-color: var(--neutral-925);
  //border-radius: var(--radius-md);

  .area-selector-main {
    display: inline-flex;
    justify-content: flex-start;
    align-items: stretch;

    @include responsive.below-desktop {
      display: flex;
    }

    > .area-selector-button {
      position: relative;
      background-color: transparent;

      cursor: pointer;

      padding-right: margins.$margin-large4;

      display: flex;
      align-items: center;
      justify-content: center;

      font-size: fonts.$font-normal;
      white-space: nowrap;

      > h2 {
        margin-top: 0;
        margin-bottom: 0;

        > .area-tag {
          display: none;

          @include responsive.below-desktop {
            display: inline;
          }
        }

        > .area-label {
          display: inline;

          @include responsive.below-desktop {
            display: none;
          }
        }
      }

      .arrow {
        user-select: none;
        display: block;
        position: absolute;

        right: margins.$margin-default;
        top: 50%;
        margin-top: -0.375em;

        width: 1em;
        height: 0.75em;
        transition: transform 0.25s;

        color: var(--text-default);

        > svg {
          transform: rotate(-90deg) scale(1.8);
        }

        &.active {
          transform: rotate(180deg);
        }
      }
    }

    .area-selector-related-wrapper {
      overflow-y: auto;
      gap: margins.$margin-default;

      scrollbar-width: none;

      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-grow: 1;

      padding-top: margins.$margin-small3;
      padding-bottom: margins.$margin-small3;

      &::-webkit-scrollbar {
        display: none;
      }

      > .area-selector-related-group {
        display: flex;
        flex-direction: column;

        margin-left: margins.$margin-small0;

        > p {
          margin: 0;
          padding-left: margins.$margin-small2;
          font-size: fonts.$font-tiny;
        }

        > div {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          .area-selector-related {
            display: block;

            color: var(--text-default);
            font-weight: var(--font-medium);
            text-decoration: none;
            white-space: nowrap;

            line-height: 2em;
            padding: 0 margins.$margin-small2;

            &:hover {
              color: var(--text-on-brand);
              background: var(--bg-brand);
              border-radius: borders.$border-radius;
            }
          }
        }
      }
    }
  }
}
