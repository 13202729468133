@use 'styles/mixins/responsive';

.pagination-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 500px;
  margin: var(--space-lg) auto;

  .pagination-bar-middle {
    display: flex;
    align-items: center;
    gap: var(--space-lg);
  }

  .pagination-button {
    display: flex;
    align-items: center;
    gap: var(--space-sm);
    line-height: 0;
    font-weight: var(--font-semibold);
    padding: var(--space-md);
    cursor: pointer;
    user-select: none;

    &:hover {
      span {
        text-decoration: underline;
      }
    }

    &.is-disabled {
      color: var(--text-disabled);
      cursor: default;
      pointer-events: none;
    }
  }

  .pagination-bar-text {
    min-width: 70px;
    text-align: center;
  }

  &.is-loading {
    //opacity: 0.5;
  }

  @include responsive.below-tablet {
    .pagination-button {
      &.is-first, &.is-last {
        span {
          display: none;
        }
      }
    }
  }
}
