@use 'styles/vars/margins';
@use 'styles/vars/fonts';

header.settings-page {
  background: var(--bg-main-container);

  > .settings-page-width {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 56px;

    > .pull-right {
      margin-left: auto;
    }
    > * {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }

  h1 {
    font-size: var(--font-lg);
  }

  svg {
    width: 100px;
    height: 56px;
    margin-right: var(--space-lg);
  }
}
