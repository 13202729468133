$-legend-symbol-height: 0.75rem;

.simple-legend-bar {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 2px;
  height: $-legend-symbol-height;

  &.is-flipped {
    transform: scale(1, -1);
  }

  .simple-legend-bar-1,
  .simple-legend-bar-2 {
    flex-shrink: 0;
    width: 3px;
  }

  .simple-legend-bar-1 {
    height: calc($-legend-symbol-height * 0.6);
  }

  .simple-legend-bar-2 {
    height: $-legend-symbol-height;
  }
}
