@use 'sass:math';
@use 'styles/vars/margins';
@use 'styles/vars/colors';

// Dimensions
$top-bar-height: 56px;
$top-bar-horizontal-padding: margins.$margin-small0;

// Logo
$top-bar-logo-size: 36px;
$top-bar-logo-margin: math.div($top-bar-height - $top-bar-logo-size, 2);

// Colors
$top-bar-text-color: colors.$color-white;
$top-bar-border-color: colors.$color-white;
