@use 'styles/vars/margins';
@use 'styles/mixins/responsive';

.local-forecasts-list {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto;
  gap: margins.$margin-default;

  .local-forecasts-card {
    grid-column: span 4;

    @include responsive.below-desktop() {
      grid-column: span 6;
    }

    @include responsive.below-tablet() {
      grid-column: span 12;
    }
  }
}
