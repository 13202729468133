@use 'styles/vars/palette';
@use 'styles/mixins/theme';

.radio-group {
  label.radio-input {
    display: grid;
    grid-template-columns: min-content auto;
    grid-gap: var(--space-sm);
    align-items: center;
    font: var(--body-2);
    margin: var(--space-md) 0;
    color: var(--text-default);
    cursor: pointer;

    > input[type='checkbox']:disabled ~ span.radio-input-label {
      opacity: 0.35;
    }
  }

  &.radio-group-horizontal {
    display: flex;
    flex-flow: row wrap;
    gap: var(--space-xl);
    margin: var(--space-md) 0;

    label.radio-input {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}
