@use 'styles/vars/margins';
@use 'styles/mixins/rounded-borders';
@use 'styles/mixins/shadows';

.knowledge-base {
  .knowledge-base-article-card {
    display: flex;
    align-items: center;
    gap: var(--space-md);
    min-height: 96px;
    padding: var(--space-md);
    background-color: var(--bg-main-container);
    text-decoration: none;
    color: inherit;
    transition:
      border-color 150ms,
      transform 50ms;
    @include rounded-borders.border-radius();

    .article-card-icon {
      flex-shrink: 0;
      opacity: 0.3;
      font-size: 2em;
      transition:
        opacity 150ms,
        color 150ms;
    }

    .article-card-content {
      flex: 1;

      h4 {
        margin: 0 0 var(--space-xs) 0;
      }

      p {
        margin: 0;
      }
    }

    .article-card-arrow {
      flex-shrink: 0;
      font-size: 1.25em;
      opacity: 0.3;
      transition:
        opacity 150ms,
        color 150ms;
    }

    &:hover {
      color: inherit;

      .article-card-icon,
      .article-card-arrow {
        opacity: 0.8;
        color: var(--text-brand);
      }
    }
  }
}
