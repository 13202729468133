@use 'styles/mixins/rounded-borders';
@use 'styles/mixins/scrollbars';

.overflow {
  @include scrollbars.simple();

  &.overflow-x {
    &-auto {
      overflow-x: auto;
    }
  }

  &.overflow-y {
    &-auto {
      overflow-y: auto;
    }
  }

  &.overflow-nowrap {
    table {
      white-space: nowrap;
    }
  }
}
