@use 'styles/mixins/rounded-borders';
@use 'styles/vars/margins';

.calculator-file-upload {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 2px dashed var(--border-subtle);
  padding: margins.$margin-default;
  @include rounded-borders.border-radius();

  input[type='file'] {
    display: none;
  }

  .upload-name {
    text-align: center;
    margin-bottom: margins.$margin-default;
  }

  .upload-actions {
    display: flex;
    gap: margins.$margin-default;
    justify-content: center;
    align-items: center;
  }
}
