@use 'styles/vars/margins';
@use 'styles/mixins/responsive';
@use 'styles/mixins/rounded-borders';

.data-integrations {
  max-width: 1200px;

  margin: margins.$margin-large4 * 2 auto;
  padding: margins.$margin-default;

  font-size: 1.2em;

  .integrations-downloads {
    margin: margins.$margin-large5 auto;

    .integrations-download-button {
      margin: margins.$margin-default auto;
      padding: margins.$margin-large1;

      max-width: 320px;

      border: 1px solid var(--border-subtle);

      display: flex;
      align-items: center;
      flex-grow: 0;

      @include rounded-borders.border-radius();

      > .downloads-icon {
        width: 64px;
        flex-grow: 0;

        > svg {
          font-size: 32px;
        }
      }

      > .downloads-content {
        width: auto;
        flex-grow: 1;

        > h3 {
          margin-top: 0 !important;
          margin-bottom: margins.$margin-small2;
        }
      }
    }
  }
}
