@use 'styles/vars/margins';
@use 'styles/vars/zindex';
@use 'styles/vars/breakpoints';
@use 'styles/vars/colors';
@use 'styles/vars/palette';
@use 'styles/mixins/responsive';
@use 'styles/mixins/theme';
@use 'styles/vars/fonts';
@use 'styles/mixins/rounded-borders';

.daily-comment-section {
  margin-top: margins.$margin-default;

  @include responsive.below(responsive.$breakpoint-monitor-1280) {
    max-width: 800px;
    margin: 0 auto;
  }
  .title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include responsive.below(responsive.$breakpoint-monitor-1280) {
      flex-direction: column;
      margin-bottom: margins.$margin-large2;
    }

    > h1 {
      margin: margins.$margin-default 0;
    }

    .archive-and-logo {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }

  .dropdown-selector-daily-comments {
    position: relative;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    max-width: 400px;
    margin: margins.$margin-default auto;
    height: 64px;

    cursor: pointer;

    background-color: var(--selector-bg);
    border-radius: var(--radius-md);

    .select-input {
      position: relative;

      display: flex;
      align-items: center;

      width: 100%;
      height: 100%;
      padding-right: margins.$margin-large4;

      .select-input-icon {
        position: absolute;
        top: 50%;
        right: margins.$margin-default;

        display: block;

        width: 1em;
        height: 0.5em;
        margin-top: -0.25em;

        background-color: var(--text-subtle);

        clip-path: polygon(0 15%, 50% 100%, 100% 15%, 100% 0, 50% 85%, 0 0);
        user-select: none;
      }

      .dropdown-selector-text {
        padding: 0 0.5rem;

        color: var(--text-default);
        text-decoration: none;

        cursor: pointer;

        > h3 {
          margin: 0;
          padding: margins.$margin-default;
        }
      }
    }

    .dropdown-selector-card-wrapper {
      position: absolute;
      right: -1px;
      top: calc(100% + #{margins.$margin-small3});

      width: 100%;
      min-width: 250px;

      user-select: none;

      .dropdown-selector-card {
        position: absolute;
        right: 0;
        z-index: zindex.$z-index-element-mouseovers;

        display: block;
        width: 100%;
        padding: margins.$margin-small4 0;

        background-color: var(--selector-bg);

        overflow-y: auto;

        @include rounded-borders.border-radius;

        .dropdown-item {
          padding: margins.$margin-small2 margins.$margin-default;
          font-size: 16px;

          &:last-child {
            border: 0;
          }

          &:hover {
            background: var(--bg-hover);
          }
        }

        .dropdown-item.ec {
          border-left: 5px solid palette.$color-red-3;
        }

        .dropdown-item.gfs {
          border-left: 5px solid palette.$color-blue-3;
        }

        .is-selected {
          > p {
            color: colors.$color-white;
            opacity: 0.6;
          }

          > h3 {
            color: colors.$color-white;
          }
        }

        .is-selected.ec {
          background-color: palette.$color-red-3;
        }

        .is-selected.gfs {
          background-color: palette.$color-blue-3;
        }
      }
    }
  }

  .menu-wrapper {
    padding: margins.$margin-large2 0;

    .content-menu {
      display: flex;
      border-radius: var(--radius-md);
      background-color: var(--selector-bg);

      .forecast-item {
        flex: 1;

        padding: margins.$margin-small2 margins.$margin-default;
        min-width: 200px;
        cursor: pointer;

        &:hover {
          background: var(--bg-hover);
        }

        > * {
          margin: margins.$margin-small2 0;
        }
      }

      .forecast-item.ec {
        border-bottom: 5px solid palette.$color-red-3;
      }

      .forecast-item.gfs {
        border-bottom: 5px solid palette.$color-blue-3;
      }

      .is-selected.ec {
        background-color: palette.$color-red-3;
      }

      .is-selected.gfs {
        background-color: palette.$color-blue-3;
      }

      .is-selected {
        > p {
          color: colors.$color-white;
          opacity: 0.6;
        }

        > h4 {
          color: colors.$color-white;
        }
      }
    }
  }

  .content-display {
    display: grid;
    grid-template-columns: 600px 1fr;
    gap: margins.$margin-default margins.$margin-large4;

    @include responsive.below(responsive.$breakpoint-monitor-1280) {
      grid-template-columns: 100%;

      min-width: 250px;
    }

    .smhi-section-header {
      grid-column: 1;

      p {
        font-size: 1.2em;
      }

      @include responsive.below(responsive.$breakpoint-monitor-1280) {
        max-width: 600px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .smhi-section-comment {
      grid-column: 1;

      @include responsive.below(responsive.$breakpoint-monitor-1920) {
        max-width: 600px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .smhi-section-numbers {
      grid-column: 2;
      max-width: 1000px;

      @include responsive.below(responsive.$breakpoint-monitor-1280) {
        grid-column: 1;
        overflow-x: auto;
      }
    }

    .smhi-section-numbers {
      display: flex;
      flex-direction: column;
      gap: margins.$margin-large4;

      > table {
        border-spacing: 0;
        border-collapse: collapse;

        > tbody {
          > tr {
            > th,
            > td {
              padding: 5px;
              border-left: 1px dotted var(--border-subtle);
              border-right: 1px dotted var(--border-subtle);
              text-align: right;

              &:first-child {
                text-align: left;
                font-weight: fonts.$font-weight-medium;
                border-left-style: solid;
              }

              &:last-child {
                border-right-style: solid;
              }

              &[colspan='2'] {
                text-align: center;
              }

              .smhi-blue {
                color: var(--table-value-blue);
              }

              .smhi-red {
                color: var(--table-value-red);
              }
            }

            &:first-child {
              > th {
                padding-top: margins.$margin-default;
                padding-bottom: margins.$margin-default;

                border-top: 1px solid var(--border-subtle);
                border-bottom: 2px solid var(--border-subtle);
              }
            }

            &:last-child {
              td {
                border-bottom: 1px solid var(--border-subtle);
              }
            }

            &:nth-child(2) {
              font-weight: fonts.$font-weight-medium;
            }

            &:nth-child(2n + 1) {
              background: var(--bg-default);
            }
          }
        }
      }

      & {
        --table-value-red: #{palette.$color-red-6};
        --table-value-blue: #{palette.$color-blue-6};

        @include theme.variant(light) {
          --table-value-red: #{palette.$color-red-4};
          --table-value-blue: #{palette.$color-blue-5};
        }
      }
    }
  }

  > p {
    @include responsive.below(responsive.$breakpoint-mobile-1) {
      text-align: center;
    }
  }
}
