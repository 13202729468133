@use 'styles/mixins/responsive';
@use 'styles/vars/margins';
@use 'styles/vars/fonts';

// The navigation for an analysis section
.analysis-section-wrapper {
  nav {
    flex-shrink: 0;
    flex-grow: 0;

    @include responsive.analysis(responsive.$re-analysis-left-menu-map);

    // The button for mobile
    .left-bar-toggle {
      display: none;
      align-items: center;
      background: transparent;
      padding: margins.$margin-small0;
      cursor: pointer;
      overflow: hidden;
      scrollbar-width: none;

      @include responsive.below(responsive.$re-analysis-monitor-1280) {
        display: flex;
      }

      > svg {
        width: 1.5em;
        height: 1.5em;

        margin-right: margins.$margin-small2;

        opacity: 0.5;

        transition:
          opacity 0.15s,
          transform 0.4s;
      }

      &:hover {
        > svg {
          opacity: 1;
        }

        > span {
          opacity: 0.9;
        }
      }

      &.open-menu {
        > svg {
          transform: rotate(180deg) scale(1.25);
          color: var(--text-brand);
          opacity: 1;
        }
      }

      .left-bar-toggle-breadcrumbs {
        display: flex;
        align-items: center;
        gap: margins.$margin-small2;

        span {
          opacity: 0.8;
          display: block;
          white-space: nowrap;

          &:not(:last-child):after {
            content: '>';
            margin-left: margins.$margin-small2;
          }
        }
      }
    }

    // The left bar
    .left-bar {
      // Responsive – minimize/maximize defined below

      > .content {
        padding: margins.$margin-small0 0 margins.$margin-small0 margins.$margin-small0;

        background: var(--bg-page-body);
        // background: green;

        > .group {
          padding: margins.$margin-small1 0;

          &:first-child {
            padding-top: 0;
          }

          &:last-child {
            padding-bottom: 0;
          }

          > .header {
            font-size: fonts.$font-tiny;
            font-weight: fonts.$font-weight-normal;
            text-transform: uppercase;

            color: var(--text-default);
            opacity: 0.75;

            padding: 0;
            margin: 0 0 margins.$margin-small2 0;
          }

          > .items {
            font-weight: fonts.$font-weight-medium;
            // font-family: $font-family-narrow;

            > a,
            > a:link,
            > a:visited {
              display: block;

              padding: 0.35rem margins.$margin-small0;
              margin: 0 -#{margins.$margin-small0};

              color: var(--text-default);

              text-decoration: none;

              background: transparent;

              &:hover {
                color: var(--text-default);

                border-left: 3px solid var(--text-default);
                padding-left: calc(#{margins.$margin-small0} - 1px);
              }

              &.active {
                color: var(--text-brand);

                border-left: 3px solid var(--text-brand);
                padding-left: calc(#{margins.$margin-small0} - 1px);
              }
            }
          }
        }
      }

      @include responsive.below(responsive.$re-analysis-monitor-1280) {
        transition:
          visibility 0.5s ease-in-out,
          max-height 0.5s ease-in-out;

        // Not visible
        visibility: hidden;
        overflow: hidden;
        max-height: 0;

        // Visible
        &.open-menu {
          visibility: visible;
          max-height: 1000px;
        }

        > .content {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
          gap: margins.$margin-default margins.$margin-large4;

          padding: margins.$margin-small0;
          margin-bottom: margins.$margin-default;

          max-width: none;
          width: auto;

          // If you want card – turn on this
          // background: var(--bg1);
          // border: 1px solid var(--border);
          // @include border-radius;

          > .group {
            min-width: 120px;
            padding: 0 !important;
          }
        }
      }
    }
  }
}
