@use 'styles/mixins/responsive';
@use 'styles/vars/margins';

@forward 'analysis-nav';
@forward 'analysis-header';
@forward 'analysis-main';
@forward 'analysis-top-bar';

.analysis-section-wrapper {
  // Wrapper
  max-width: 100%;
  margin-top: margins.$margin-small2;

  > .analysis-section {
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 0 auto;

    // Responsive
    max-width: 100%;
    @include responsive.analysis(responsive.$re-analysis-full-map);
  }
}
