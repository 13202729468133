@use 'styles/vars/margins';
@use 'styles/vars/palette';
@use 'styles/mixins/theme';

table.default-table {
  border-collapse: collapse;
  width: 100%;

  th,
  td {
    text-align: left;
    padding: var(--space-sm) var(--space-sm);
  }

  td {
    border-bottom: 1px solid var(--border-subtle);
  }

  thead {
    > tr {
      > th {
        color: var(--text-subtle);
        font-weight: var(--font-regular);
      }
    }
  }

  tbody {
    > tr {
      &:hover {
        background: var(--bg-hover);
      }

      &:last-child td {
        border-bottom: none;
      }

      > td {
        &.table-link-cell {
          padding: 0;
          letter-spacing: -0.25px;

          > * {
            display: block;
            padding: var(--space-md);
          }

          > a,
          a:link,
          a:visited {
            text-decoration: none;

            &:hover,
            &:focus {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
