@use 'styles/vars/margins';
@use 'styles/mixins/theme';

.analysis-section-wrapper {
  .analysis-top-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: margins.$margin-default;

    padding: var(--space-sm);
    margin-bottom: margins.$margin-large1;
    margin-top: -8px;

    background-color: var(--bar-bg);

    --bar-bg: var(--neutral-925);

    @include theme.variant(light) {
      --bar-bg: var(--neutral-100);
    }
  }
}
