@use 'styles/mixins/rounded-borders';
@use 'styles/mixins/shadows';
@use 'styles/vars/margins';
@use 'styles/vars/zindex';
@use 'styles/vars/colors';
@use 'styles/vars/fonts';
@use './NavigationProps';

.navigation-profile {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 0;

  .navigation-profile-icon {
    padding: var(--space-xs) 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid var(--border-subtle);
    padding-left: var(--space-md);
  }

  .navigation-profile-menu {
    z-index: zindex.$z-index-nav-menu;
    min-width: 260px;
    width: auto;
    padding: margins.$margin-small2 0;
    background-color: var(--bg-card);
    box-shadow: var(--shadow-lg);

    @include rounded-borders.border-radius();

    > .navigation-profile-id {
      color: var(--text-default);
      font-family: fonts.$font-family-mono;
      font-weight: fonts.$font-weight-normal;

      > span {
        letter-spacing: -0.05em;
      }
    }

    > hr {
      margin: var(--space-sm) 0;
      border-top: 1px solid var(--border-subtle);
    }

    > a,
    > a:link,
    > div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: margins.$margin-small1 (margins.$margin-small1 * 1.5);

      text-decoration-color: transparent;
      font-weight: fonts.$font-weight-medium;
      color: var(--text-default);

      white-space: nowrap;

      opacity: 0.8;

      transition: 0.15s all;

      > svg {
        height: 1.25em;
        width: 1.25em;
        margin-right: margins.$margin-small2;

        opacity: 0.35;
      }

      > button {
        width: 100%;
      }
    }

    > a {
      cursor: pointer;

      &:hover {
        opacity: 1;

        > svg {
          opacity: 1;

          position: relative;
          transform: scale(1.15);
        }
      }
    }
  }
}
