@use 'styles/vars/margins';
@use 'styles/mixins/responsive';
@use 'styles/mixins/rounded-borders';
@use 'styles/mixins/shadows';

.startup-tips {
  img {
    max-width: 100%;
  }

  .startup-tips-actions {
    display: flex;
    gap: margins.$margin-small2;
  }

  .startup-tips-content {
    .startup-tips-media {
      margin-bottom: var(--space-lg) 0;
      border: 1px solid var(--border-subtle);
      overflow: hidden;
      @include rounded-borders.border-radius();

      video,
      img {
        max-width: 100%;
        min-height: 200px;
      }
    }
  }

  .startup-tips-close {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--space-md);
    margin-top: var(--space-xxl);
  }

  @include responsive.below-tablet() {
    .popup-card-header {
      flex-direction: column;
      align-items: flex-start !important;
    }

    .startup-tips-content {
      .startup-tips-media {
        video,
        img {
          min-height: 75px;
        }

        img {
          object-fit: cover;
        }
      }
    }
  }
}
