//
// Helpers for light/dark mode
//
// Note: These four rules are also implemented in the base Django template
//       available in server/apps/webapps/app/templates/base.html
//

// Selectors for system-selected dark mode or light mode where
// there is not override in place
@media (prefers-color-scheme: dark) {
  :root:not([color-scheme='light']) .light-mode-only {
    display: none !important;
  }
}
@media (prefers-color-scheme: light) {
  :root:not([color-scheme='dark']) .dark-mode-only {
    display: none !important;
  }
}

// Rules for overriding the system default mode
:root[color-scheme='dark'] .light-mode-only {
  display: none !important;
}
:root[color-scheme='light'] .dark-mode-only {
  display: none !important;
}
