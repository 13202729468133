@use 'styles/vars/margins';

.consumption-selector {
  display: flex;
  align-items: center;
  gap: margins.$margin-small4;

  input {
    text-align: right;
  }
}
