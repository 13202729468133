@use 'sass:color';

@use 'styles/vars/margins';
@use 'styles/vars/palette';
@use 'styles/vars/colors';
@use 'styles/mixins/rounded-borders';
@use 'styles/mixins/shadows';
@use 'styles/mixins/theme';
@use 'styles/vars/fonts';
@use 'styles/mixins/responsive';

.outage-list {
  .outage-list-card {
    position: relative;
    border: 2px solid var(--transparent);
    margin-bottom: margins.$margin-default;
    transition: transform 100ms;

    .outage-list-card-header {
      display: flex;
      align-items: center;
      gap: var(--space-sm);
      margin-bottom: var(--space-lg);

      .published-status {
        margin-left: auto;
      }

      @include responsive.below-tablet {
        flex-wrap: wrap-reverse;

        .published-status {
          margin-left: 0;
        }
      }
    }

    .outage-list-card-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: var(--font-xxs);
      gap: var(--space-lg);

      & + .outage-list-card-remark {
        margin-top: var(--space-lg);
      }

      @include responsive.below-tablet {
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        gap: 0;
      }
    }

    .outage-list-card-periods {
      @include responsive.below-tablet {
        margin-bottom: var(--space-lg);
      }
    }

    .outage-list-card-columns {
      display: flex;
      align-items: center;
      gap: var(--space-3xl);

      .column {
        .column-label {
          font-size: 0.85em;
          text-transform: uppercase;
          text-wrap: nowrap;
          color: var(--text-subtle);
          margin-bottom: var(--space-xxs);
        }

        .column-value {
          text-wrap: nowrap;
        }
      }

      @include responsive.below-tablet {
        gap: var(--space-lg);
      }
    }

    .outage-list-card-remark {
      font-size: var(--font-xxs);
      background-color: var(--bg-page-body);
      padding: var(--space-md) var(--space-lg);
      @include rounded-borders.border-radius;
    }

    &.is-cancelled {
      .outage-list-card-content,
      .outage-list-card-remark {
        text-decoration: line-through;
      }
    }

    &:hover {
      cursor: pointer;
    }

    &.is-now {
      border-color: var(--bg-brand);
    }

    &.is-recent {
      border-color: var(--bg-brand);
    }
  }
}
