// Font family

$font-family: 'Roboto', Helvetica, Arial, sans-serif;
$font-family-mono: 'Roboto Mono', consolas, monospace;
$font-family-narrow: 'Roboto Condensed', Helvetica, Arial, sans-serif;

$font-family-headers: $font-family;

// Font weights

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

// Font sizes

$font-tiny: 11px;
$font-small: 12px;
$font-normal: 14px;
$font-large: 16px;
$font-subheader: 18px;
$font-header: 22px;
$font-title: 28px;

// Input line-heights

$line-height-forms-large: 28px;
$line-height-forms-default: 24px;
$line-height-forms-small: 22px;

// Font size adjustments

$font-smaller: 0.9em;
$font-larger: 1.15em;

// Default line heights

$line-height-headers: 1.1;
$line-height-small: 1.2;
$line-height-pararaph: 1.5;
$line-height-large: 1.75;
