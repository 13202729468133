@use 'styles/vars/margins';
@use 'styles/vars/fonts';
@use 'styles/mixins/responsive';

.chart-creator-curve-card {
  padding: 0;

  .card-content {
    padding: margins.$margin-default;
  }

  .curve-card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: margins.$margin-default;
    margin-bottom: margins.$margin-default;
    border-bottom: 1px dashed var(--border-subtle);

    .curve-card-header-actions {
      display: flex;
      align-items: center;
      gap: margins.$margin-default;
      font-size: fonts.$font-large;

      .action-up,
      .action-down {
        color: var(--text-brand);
        cursor: pointer;

        &.is-disabled {
          color: var(--text-disabled);
          opacity: 0.3;
          cursor: default;
        }
      }

      .action-delete {
        color: var(--text-error);
        cursor: pointer;
      }
    }
  }

  .curve-card-content {
    h4 {
      margin: 0 0 0.5rem 0;
    }

    .content-style {
      display: flex;
      align-items: center;
      gap: margins.$margin-default;

      .content-column {
        label {
          display: block;
          font-size: 0.75em;
          text-transform: uppercase;
          color: var(--text-subtle);
          margin: 0 0 0.25rem 0;
        }
      }

      @include responsive.below-desktop {
        flex-wrap: wrap;
      }
    }
  }
}
