@use 'styles/mixins/theme';
@use 'styles/mixins/rounded-borders';
@use 'styles/vars/margins';
@use 'styles/vars/colors';
@use 'styles/vars/fonts';
@use 'styles/vars/palette';

.places-facilities {
  .places-facilities-table {
    tr {
      &.is-current {
        color: var(--text-default);
        font-weight: fonts.$font-weight-bold;
      }

      &.is-child {
        .child-icon {
          display: block;
        }
      }

      .name {
        display: flex;
        align-items: center;
        gap: 0.75rem;
        margin-left: margins.$margin-small2;
      }

      .child-icon {
        display: none;
        width: 0.35rem;
        height: 0.5rem;
        border-left: 1px solid var(--text-subtle);
        border-bottom: 1px solid var(--text-subtle);
        transform: translateY(-0.2rem);
      }
    }
  }
}
