@use 'styles/vars/margins';
@use 'styles/vars/colors';
@use 'styles/vars/palette';
@use 'styles/mixins/theme';

.archive-selector {
  .archive-selector-button {
    &.is-archived {
      box-shadow: inset 0 0 0 1px var(--purple);
      border-color: var(--purple);
    }
  }

  .archive-help-text {
    margin-top: var(--space-md);
    margin-bottom: var(--space-lg);
  }
}
