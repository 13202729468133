@use 'sass:list';
@use '../utils/list' as list-utils;

$indent-types: (decimal, lower-alpha, lower-roman);
$indent-lists: (list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9);

// Limit these overrides to certain areas like
// the Knowledge Base, Page Description Popup, Changelog etc.
.article-content,
.changelog-card-description {
  ul,
  ol {
    padding-left: 3em;

    > li {
      list-style-type: none;
      counter-reset: $indent-lists;
      counter-increment: list-0;

      &:before {
        display: inline-block;
        white-space: nowrap;
        width: 1.2em;
        content: counter(list-0, decimal) '. ';
        margin-left: -1.5em;
        margin-right: 0.2em;
      }

      $padding-left: 1.5em;

      @for $i from 1 through list.length($indent-lists) {
        $padding-left: $padding-left + 1.5em;

        &.ql-indent-#{$i} {
          $indent-list: list.nth($indent-lists, $i);
          $indent-type: list.nth($indent-types, ($i % list.length($indent-types)) + 1);

          padding-left: $padding-left;
          counter-increment: $indent-list;

          @if $i != list.length($indent-lists) {
            counter-reset: list-utils.subset($indent-lists, $i + 1);
          }

          &:before {
            content: counter($indent-list, $indent-type) '. ';
          }
        }
      }
    }
  }

  // Force dot as list type in unordered lists
  ul > li:before {
    content: '\2022' !important;
  }
}
