@use 'styles/vars/margins';
@use 'styles/mixins/responsive';

.page-content-wrapper {
  margin-top: margins.$margin-default;
  .page-content {
    .title-with-selector {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include responsive.below-tablet {
        flex-direction: column;
        align-items: flex-start;
      }

      .selector-and-logo {
        display: flex;
        align-items: center;
        gap: margins.$margin-default;
      }

      > h1 {
        margin: margins.$margin-default 0;
      }
    }

    .comment-summary {
      margin-bottom: margins.$margin-large2;
    }

    .comments-list {
      .comment-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: margins.$margin-default;

        @include responsive.below-tablet {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }

    .comments-content {
      display: flex;
      gap: margins.$margin-large3;

      @include responsive.below-tablet {
        flex-direction: column;
        gap: margins.$margin-default;
      }

      > * {
        flex-basis: 50%;
        max-width: 550px;

        > :first-child {
          margin-top: 0;
        }

        > :last-child {
          margin-bottom: 0;
        }
      }
    }

    .comments-images {
      margin-left: -#{margins.$margin-default};
      margin-right: -#{margins.$margin-default};
      margin-bottom: -#{margins.$margin-default};

      .image-wrapper {
        float: left;
        min-width: 200px;
        max-width: 550px;
        margin: margins.$margin-default;

        > img {
          display: block;
          width: 100%;
        }

        .card-content {
          padding: margins.$margin-small3 0;
          font-size: small;
        }
      }
    }
    > p {
      @include responsive.below-tablet {
        text-align: center;
      }
    }
  }
}
