:root {
  --font-sans: 'Roboto', Helvetica, Arial, sans-serif;
  --font-mono: 'Roboto Mono', consolas, monospace;
  --font-narrow: 'Roboto Condensed', Helvetica, Arial, sans-serif;

  --font-3xs: 10px;
  --font-xxs: 12px;
  --font-xs: 14px;
  --font-sm: 16px;
  --font-md: 20px;
  --font-lg: 24px;
  --font-xl: 28px;
  --font-xxl: 32px;
  --font-3xl: 36px;
  --font-4xl: 40px;

  --font-light: 300;
  --font-regular: 400;
  --font-italic: italic;
  --font-medium: 500;
  --font-semibold: 600;
  --font-bold: 700;

  --line-none: 100%;
  --line-short: 125%;
  --line-base: 130%;
  --line-tall: 135%;
  --line-taller: 140%;
  --line-extra-tall: 145%;
  --line-tallest: 150%;

  --letter-sm: 5%;
  --letter-md: 10%;
}
