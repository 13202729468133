@use 'styles/mixins/responsive';

.date-range-selector {
  &.is-shortcuts {
    .default-selector {
      display: none;
    }

    @include responsive.above-tablet {
      display: flex;
      align-items: center;

      button {
        top: 0 !important;
        box-shadow: none !important;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      .default-selector {
        display: flex;
        border-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}
