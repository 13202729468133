@use 'styles/vars/margins';
@use 'styles/mixins/responsive';
@use 'styles/mixins/rounded-borders';

.data-integrations-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--space-md);
  flex-wrap: wrap;

  p {
    margin: 0;
    line-height: 0;
  }
}

.data-integrations {
  max-width: 1200px;

  margin: margins.$margin-large4 * 2 auto;
  padding: margins.$margin-default;

  font-size: 1.2em;

  .data-integrations-types {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: margins.$margin-default;

    max-width: 900px;
    margin: margins.$margin-large4 auto;

    > .card {
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 0;

      padding: margins.$margin-large5 margins.$margin-large1 margins.$margin-large1
        margins.$margin-large1;

      > .data-integration-icon {
        display: block;
        font-size: 32px;

        margin-bottom: margins.$margin-default;
      }
    }

    @include responsive.below-tablet {
      flex-direction: column;
      max-width: 400px;

      > .card {
        padding-top: margins.$margin-large4;
      }
    }
  }
}
