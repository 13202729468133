@use 'styles/vars/margins';
@use 'styles/vars/fonts';
@use 'styles/vars/breakpoints';
@use 'styles/mixins/responsive';
@use 'styles/mixins/rounded-borders';

div.page-tabs {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: margins.$margin-small2;
  margin: margins.$margin-default 0 margins.$margin-default 0;
  border-bottom: 1px solid var(--border-subtle);

  > div.page-tabs-bar {
    position: relative;
    top: 1px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-y: auto;

    padding: 0;

    scrollbar-width: none;

    > a,
    > a:link,
    > a:visited {
      height: 3rem;
      line-height: 3rem;

      color: var(--text-default);
      font-size: fonts.$font-header;
      font-weight: fonts.$font-weight-medium;

      white-space: nowrap;

      padding-left: margins.$margin-large0;
      padding-right: margins.$margin-large0;

      text-decoration: none;

      opacity: 0.8;

      cursor: pointer;

      &:hover {
        opacity: 0.95;
      }

      &.active {
        opacity: 1;
        color: var(--text-brand);
        border-bottom: 3px solid var(--text-brand);

        @include rounded-borders.border-radius-top();
      }

      &.deactivated {
        opacity: 0.55;

        &.active {
          opacity: 0.8;
        }
      }
    }

    > .page-tabs-logo {
      // SMHI Wrapper in Weather section only
      position: absolute;
      right: margins.$margin-default;
      bottom: margins.$margin-default;

      @include responsive.below(responsive.$breakpoint-monitor-1280) {
        display: none;
      }

      > .logo-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include responsive.below(responsive.$breakpoint-monitor-1280) {
          display: none;
        }

        > p {
          margin: 0 0 margins.$margin-small4 0;
          font-size: fonts.$font-small;
        }

        .smhi-logo-path {
          fill: var(--text-default);
          fill-opacity: 1;
          fill-rule: nonzero;
          stroke: none;
        }
      }
    }
  }

  .page-tabs-actions {
    position: relative;
    display: flex;
    align-items: center;

    .page-tabs-action {
      .label {
        font-size: 1.1em;
      }
    }

    .page-tabs-action:not(:first-child):not(:empty) {
      border-left: 1px solid var(--border-subtle);
      margin-left: margins.$margin-default;
      padding-left: margins.$margin-default;
    }

    .page-tabs-actions-fade {
      position: absolute;
      top: 0;
      left: -0.5rem;
      bottom: 0;
      transform: translateX(-100%);
      width: 40px;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, var(--bg-page-body) 100%);
      pointer-events: none;
    }

    @include responsive.below-desktop() {
      .page-tabs-action:not(:first-child):not(:empty) {
        margin-left: margins.$margin-small2;
        padding-left: margins.$margin-small2;
      }
    }
  }
}
