.empty-analysis-checker {
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.75;

  p {
    text-align: center;
  }
}
