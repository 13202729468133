.select-input {
  display: inline-block;
  position: relative;

  .select-input-icon {
    user-select: none;

    display: block;
    position: absolute;

    right: var(--space-lg);
    top: 50%;
    margin-top: -0.25em;

    width: 1em;
    height: 0.5em;
    background-color: var(--text-subtle);
    clip-path: polygon(0 15%, 50% 100%, 100% 15%, 100% 0, 50% 85%, 0 0);
  }
}
