@use 'styles/vars/margins';
@use 'styles/vars/zindex';
@use 'styles/vars/fonts';
@use 'styles/mixins/rounded-borders';

.chart-creator-color {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;

  .color-preview {
    width: 1.75rem;
    height: 1.75rem;
    border-radius: 50%;
    cursor: pointer;
  }

  .color-popover {
    background-color: var(--bg-default);
    border: 1px solid var(--border-subtle);
    padding: margins.$margin-default;
    z-index: zindex.$z-index-above-siblings;
    @include rounded-borders.border-radius();
  }
}

.colors-label {
  margin-bottom: margins.$margin-small2;
  color: var(--text-subtle);
  font-size: fonts.$font-small;
  text-transform: uppercase;
}

.colors-list {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  flex-wrap: wrap;
  gap: margins.$margin-small2;

  &:not(:last-child) {
    margin-bottom: margins.$margin-default;
  }

  .color {
    border-radius: 50%;
    height: 1.5rem;
    width: 1.5rem;
    cursor: pointer;
    transition: transform 100ms;

    &:hover {
      transform: scale(1.2);
    }
  }
}
