@use 'styles/vars/colors';
@use 'styles/vars/palette';
@use 'styles/vars/fonts';
@use 'styles/vars/margins';
@use 'styles/vars/zindex';
@use 'styles/mixins/rounded-borders';
@use 'styles/vars/selectors';
@use 'styles/mixins/theme';

.days-range-container {
  display: flex;
  align-items: center;
}

.days-range {
  width: 300px;
  height: selectors.$selector-height;
  padding: 0 margins.$margin-small2;
  background-color: var(--selector-bg);
  display: flex;
  align-items: center;
  @include rounded-borders.border-radius-left;

  @mixin slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 9px;
    width: 9px;
    background-color: var(--neutral-500);
    border-radius: 100%;
    border: none;
  }

  .days-range-slider {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 5px;

    // Custom positioning because <input range> is not behaving >:(
    margin-top: -2px;

    input[type='range'] {
      -webkit-appearance: none;
      appearance: none;
      width: 100%;
      cursor: pointer;
      outline: none;
      height: 5px;
      opacity: 0.8;
      transition: opacity 200ms;
      @include rounded-borders.border-radius-right();

      // days-range-selector.ts sets colors
      //background: var(--bg);

      &:hover {
        opacity: 1;
      }

      // These cannot be combined, but I don't know why
      &::-webkit-slider-thumb {
        @include slider-thumb;
      }
      &::-moz-range-thumb {
        @include slider-thumb;
      }
    }

    &.is-backward {
      input[type='range'] {
        transform: scaleX(-1);
      }

      .duration {
        text-align: left;
      }
    }

    .duration {
      font-size: fonts.$font-tiny;
      color: var(--text-disabled);
      text-align: right;

      > span {
        color: var(--text-default);
        font-weight: bold;
      }
    }
  }

  .days-range-separator {
    position: relative;
    z-index: zindex.$z-index-above-siblings;
    width: 1px;
  }
}

.days-range-action {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--selector-accent);
  font-size: 1.5em;
  height: selectors.$selector-height;
  padding: 0 margins.$margin-small2;
  margin-left: -2px;
  cursor: pointer;
  @include rounded-borders.border-radius-right();

  > svg {
    opacity: 0.5;
  }

  &:hover {
    > svg {
      opacity: 0.8;
    }
  }
}
