@use 'sass:math';

// Default subtle opacity

.subtle {
  opacity: 0.7;
}

// Opacity 10–100 (step: 10)

@for $o from 1 through 9 {
  .opacity-#{$o}0 {
    opacity: math.div($o, 10) !important;
  }
}

.opacity-100 {
  opacity: 1 !important;
}
