:root {
  --size-none: 0;
  --size-3xs: 1px;
  --size-xxs: 2px;
  --size-xs: 4px;
  --size-sm: 8px;
  --size-md: 12px;
  --size-lg: 16px;
  --size-xl: 20px;
  --size-xxl: 24px;
  --size-3xl: 28px;
  --size-4xl: 32px;
  --size-5xl: 36px;
  --size-6xl: 40px;
  --size-7xl: 48px;
  --size-8xl: 56px;
  --size-9xl: 64px;
  --size-10xl: 72px;
  --size-11xl: 80px;

  --space-none: 0;
  --space-3xs: 1px;
  --space-xxs: 2px;
  --space-xs: 4px;
  --space-sm: 8px;
  --space-md: 12px;
  --space-lg: 16px;
  --space-xl: 20px;
  --space-xxl: 24px;
  --space-3xl: 28px;
  --space-4xl: 32px;
  --space-5xl: 36px;
  --space-6xl: 40px;
  --space-7xl: 48px;
  --space-8xl: 56px;
  --space-9xl: 64px;
  --space-10xl: 72px;
  --space-11xl: 80px;

  --radius-none: 0;
  --radius-xs: 2px;
  --radius-sm: 4px;
  --radius-md: 6px;
  --radius-lg: 8px;
  --radius-full: 1000px;
}
