@use 'styles/vars/margins';
@use 'styles/vars/zindex';
@use 'styles/vars/colors';
@use 'styles/vars/palette';
@use 'styles/vars/fonts';
@use 'styles/mixins/rounded-borders';
@use 'styles/mixins/shadows';
@use 'styles/mixins/theme';

.card-analysis {
  position: relative;
  padding: 0;
  background-color: var(--bg-main-container);
  border-radius: var(--radius-md);

  &:hover {
    .card-header {
      &.has-menu {
        .arrow {
          opacity: 1;
        }
      }
    }
  }

  > .card-header {
    position: relative;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: margins.$margin-small3 margins.$margin-small2 0;

    background-color: transparent;
    @include rounded-borders.border-radius-top;

    > h3 {
      display: flex;
      align-items: center;
      gap: margins.$margin-small4 margins.$margin-small2;
      flex-wrap: wrap;

      padding: margins.$margin-small3 0;
      margin: 0;

      font-size: fonts.$font-normal;
      font-weight: fonts.$font-weight-medium;
    }

    &.has-menu {
      > .arrow {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        margin-left: margins.$margin-default;

        opacity: 0.5;
        transition: opacity ease-in-out 150ms;
        border-radius: 12px;

        cursor: pointer;

        > svg {
          transform: scale(1.3) rotate(-90deg);
          transition: transform ease-in-out 0.25s;
        }
      }

      &.is-menu-open {
        > .arrow {
          opacity: 1;

          > svg {
            transform: scale(1.3) rotate(90deg);
          }
        }
      }

      .card-menu {
        position: absolute;
        top: 30px; // TODO Fix positioninig
        right: 4px;
        z-index: zindex.$z-index-element-mouseovers;

        min-width: 160px;
        padding: margins.$margin-small3;
        background-color: var(--bg-subtle);

        user-select: none;

        border-radius: var(--radius-sm);
        box-shadow: var(--shadow-lg);

        > div.menu-item {
          display: flex;
          align-items: center;
          justify-content: left;
          padding: margins.$margin-small2;
          cursor: pointer;
          @include rounded-borders.border-radius;

          > svg {
            margin-right: margins.$margin-small2;
          }

          &:hover {
            color: var(--text-on-brand);
            background: var(--bg-brand-hover);
          }
        }

        > hr {
          margin: margins.$margin-small3;
          border-color: var(--border-subtle);
        }
      }
    }
  }

  > .card-content {
    position: relative;
    padding: 0 margins.$margin-small2 margins.$margin-small2;
    overflow-x: hidden;
  }

  // Placeholder overrides

  &.is-placeholder {
    user-select: none;

    > .card-header {
      > h3 {
        width: 300px;
        max-width: 60%;
      }
    }

    > .card-content {
      @keyframes is-placeholder-p-fade {
        0% {
          opacity: 0.25;
        }
        50% {
          opacity: 0.1;
        }
        100% {
          opacity: 0.25;
        }
      }

      p {
        opacity: 0.25;
        animation: is-placeholder-p-fade 1.5s infinite;
      }

      > div.is-placeholder-chart,
      > div.is-placeholder-table,
      > div.is-placeholder-map {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 225px;

        //background-color: var(--bg-card);
        border-radius: var(--radius-md);
      }

      > div.is-placeholder-chart {
        height: 225px; // Min-height for charts

        &.placeholder-small {
          height: 150px;
        }

        &.placeholder-large {
          height: 300px;
        }
      }

      > div.is-placeholder-table {
        height: calc(350px); // Tables are usually quite tall

        &.placeholder-small {
          height: 275px;
        }

        &.placeholder-large {
          height: 425px;
        }
      }

      > div.is-placeholder-map {
        height: 600px;

        &.placeholder-small {
          height: 300px;
        }

        &.placeholder-large {
          height: 850px;
        }
      }
    }
  }

  &.is-inline {
    display: inline-block;
  }
}
