@use 'styles/vars/zindex';
@use 'styles/mixins/theme';

.popup.default {
  .popup-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: zindex.$z-index-dialogs;

    background-color: var(--bg-dimmed);
    backdrop-filter: blur(2px);

    // Animation when appearing
    animation: fadeInPopupBackdrop 0.6s ease-in-out;

    // Animation before removing (when is closing)
    transition:
      background-color 0.4s ease-in,
      backdrop-filter 0.3s ease-in;

    @keyframes fadeInPopupBackdrop {
      0% {
        background-color: var(--transparent);
        backdrop-filter: blur(0px);
      }
      20% {
        background-color: var(--transparent);
        backdrop-filter: blur(0px);
      }
      100% {
        background-color: var(--bg-dimmed);
        backdrop-filter: blur(2px);
      }
    }

    &.is-closing {
      background-color: var(--transparent);
      backdrop-filter: blur(0px);
    }
  }
}
