@use 'sass:math';
@use 'styles/vars/margins';
@use 'styles/vars/fonts';
@use 'styles/vars/palette';
@use 'styles/vars/colors';
@use 'styles/mixins/responsive';
@use 'styles/mixins/rounded-borders';
@use 'styles/mixins/shadows';
@use 'styles/mixins/theme';

@use 'styles/components/article-content';

.knowledge-base {
  .knowledge-base-overview {
    .filter-bar {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      gap: var(--space-md);
      margin: -1px auto 0 auto; // Hide border of <header>
      padding: var(--space-sm) var(--space-lg);
      background-color: var(--bg-main-container);
      @include rounded-borders.border-radius();
    }

    .container-navigation-item {
      position: relative;
      display: flex;
      align-items: center;
      gap: var(--space-lg);
      text-decoration: none;
      color: inherit;
      padding: var(--space-xl) 0;

      .item-label {
        flex-shrink: 0;
        position: relative;
        z-index: 1;
        width: var(--size-6xl);
        height: var(--size-6xl);
        background-color: var(--bg-page-body);
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--border-subtle);
        transition:
          border-color 150ms,
          color 150ms;

        span {
          opacity: 0.6;
        }
      }

      .item-vertical-border {
        position: absolute;
        height: 100%;
        width: 1px;
        left: var(--size-xl);
        background-color: var(--border-subtle);
      }

      .item-content {
        h3 {
          margin-top: 0;
        }

        //p {
        //  color: var(--text-subtle);
        //}

        .item-metadata {
          display: flex;
          align-items: center;
          gap: margins.$margin-default;
          color: var(--text-subtle);

          .metadata-item {
            display: flex;
            align-items: center;
            gap: margins.$margin-small2;

            svg {
              font-size: 1.25em;
            }
          }
        }
      }

      &:hover {
        .item-label {
          border-color: var(--text-default);

          span {
            opacity: 1;
          }
        }
      }

      &:first-child {
        .item-vertical-border {
          height: 50%;
          bottom: 0;
        }
      }

      &:last-child {
        .item-vertical-border {
          height: 50%;
          top: 0;
        }
      }
    }

    .container-content-item {
      padding-top: 3rem;
      padding-bottom: 4rem;
      border-bottom: 1px dashed var(--border-subtle);

      &.is-empty {
        padding-bottom: 1rem;
        padding-top: 1rem;
        opacity: 0.5;

        .item-subheader {
          display: none;
        }
      }

      &:first-child {
        padding-top: 0;

        h2 {
          margin-top: 1em;
        }
      }

      &:last-child {
        border-bottom: 0;
      }

      h2 {
        margin-top: 0;
        font-size: 2em;
      }

      .item-subheader {
        font: var(--heading-4);
      }

      .item-videos,
      .item-articles {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        gap: var(--space-md);
        margin-top: var(--space-xl);
      }

      @include responsive.below-desktop {
        .item-videos,
        .item-articles {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  .video-card {
    display: flex;
    align-items: center;
    gap: var(--space-md);
    padding: var(--space-md);
    background-color: var(--bg-main-container);
    transition:
      border-color 150ms,
      transform 50ms;
    @include rounded-borders.border-radius();

    img {
      width: 7rem;
      margin-left: var(--space-md);
      order: 3;
      @include rounded-borders.border-radius(5px);
    }

    .video-card-content {
      flex: 1;
      display: flex;
      align-items: center;
      gap: margins.$margin-small0;

      .video-card-content-icon {
        color: var(--text-brand);
        flex-shrink: 0;
        font-size: 2em;
        transition:
          opacity 150ms,
          color 150ms;
      }

      .video-card-content-text {
        flex: 1;

        h4 {
          margin: 0 0 margins.$margin-small3 0;
        }

        p {
          margin: 0;
        }
      }
    }

    &:hover {
      cursor: pointer;
    }

    @include responsive.below(responsive.$breakpoint-monitor-1440) {
      img {
        display: none;
      }
    }
  }

  .knowledge-base-video-player {
    h2 {
      margin-top: 0;
    }

    .video-player-content {
      @include article-content.render();
    }
  }
}
