@use 'styles/vars/fonts';
@use 'styles/vars/margins';
@use 'styles/mixins/responsive';
@use 'styles/mixins/rounded-borders';
@use 'styles/mixins/shadows';

.places-details-page {
  .places-details-map-container {
    position: relative;
    height: 400px;
    width: 100%;
    overflow: hidden;
    border-bottom: 1px solid var(--bg-main-container);
  }

  .places-details-title-card {
    position: relative;
    background-color: var(--bg-main-container);
    padding: margins.$margin-large3 margins.$margin-large3;
    max-width: calc(
      #{responsive.$re-analysis-full-1920} - (#{margins.$margin-default} * 2)
    );
    margin: -4em auto margins.$margin-large0 auto;
    font-size: fonts.$font-large;

    @include rounded-borders.border-radius();

    @include responsive.below-desktop {
      padding: margins.$margin-default;
      border-left: none;
      border-right: none;
      border-radius: 0;
    }

    h1 {
      margin: 0 0 margins.$margin-large3 0;
    }

    &.is-loading {
      height: 164px;
    }

    .title-card-columns {
      display: flex;
      gap: margins.$margin-large5;
      flex-wrap: wrap;

      .title-card-column {
        display: flex;
        flex-direction: column;

        &.push-right {
          margin-left: auto;
        }

        > div {
          display: block;

          &.column-label {
            text-transform: uppercase;
            font-size: fonts.$font-small;
            color: var(--text-subtle);
            margin-bottom: margins.$margin-small3;
          }
        }
      }

      @include responsive.below-desktop {
        gap: margins.$margin-large2;

        .title-card-column {
          &.push-right {
            margin-left: unset;
          }
        }
      }
    }
  }
}
