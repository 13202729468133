@use 'styles/vars/margins';
@use 'styles/vars/fonts';
@use 'styles/mixins/rounded-borders';

fieldset {
  border: 1px solid var(--border-subtle);
  padding: var(--space-lg);
  margin: var(--space-lg) 0 0 0;

  @include rounded-borders.border-radius;

  > legend {
    padding: 0 var(--space-xs);
    font-weight: var(--font-semibold);
  }

  > .radio-group,
  > .checkbox-group,
  > input,
  > select,
  > textarea {
    margin-top: 0 !important;
    margin-bottom: 0 !important;

    > label:first-child {
      margin-top: 0 !important;
    }

    > label:last-child {
      margin-bottom: 0 !important;
    }
  }

  .form-group:first-of-type {
    label {
      margin-top: 0 !important;
    }
  }
}
