@use 'styles/vars/fonts';
@use 'styles/vars/margins';
@use 'styles/vars/palette';
@use 'styles/mixins/theme';
@use 'styles/mixins/responsive';

.tours-popup {
  .tours-popup-item {
    display: flex;
    justify-content: space-between;

    .item-content {
      h3 {
        margin: 0;
      }

      p {
        margin: margins.$margin-default 0;
        align-self: flex-start;
      }
    }

    .item-completed {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: margins.$margin-small2;
      font-size: fonts.$font-small;
      color: var(--text-subtle);
      flex-shrink: 0;
      width: 125px;

      > svg {
        font-size: 3em;
      }
    }

    &.is-completed {
      .item-completed {
        color: var(--text-success);
      }
    }
  }

  @include responsive.below-tablet {
    .tours-popup-item {
      .item-completed {
        display: none;
      }
    }
  }
}
