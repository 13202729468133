@use 'styles/vars/palette';
@use 'styles/vars/margins';
@use 'styles/mixins/responsive';
@use 'styles/mixins/theme';
@use 'styles/mixins/rounded-borders';
@use 'styles/mixins/shadows';

%overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: margins.$margin-large1;
  color: var(--text-default);
  @include rounded-borders.border-radius;
}

.curve-chart-wrapper {
  .curve-chart-filters {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: margins.$margin-default margins.$margin-small1;
    margin-bottom: margins.$margin-default;
    padding: var(--space-sm);
    background-color: var(--selector-wrapper-bg);
    border-radius: var(--radius-md);

    .curve-chart-filters-right {
      margin-left: auto;

      @include responsive.below-desktop {
        margin-left: unset;
      }
    }
  }

  .curve-chart {
    position: relative;
    display: flex;
    overflow-x: hidden;

    .curve-chart-loading {
      @extend %overlay;

      > span {
        opacity: 0.25;
      }
    }

    .curve-chart-error {
      @extend %overlay;

      &.is-blocked {
        color: var(--text-on-error);
        background: var(--bg-error);

        a {
          color: inherit;
          text-decoration-color: inherit;
        }
      }
    }
  }

  .curve-chart-options {
    display: flex;
    gap: margins.$margin-default margins.$margin-large4;
    margin-top: margins.$margin-default;
    padding-top: margins.$margin-default;
    border-top: 1px solid var(--border-subtle);

    &.is-top {
      margin-top: 0;
      padding-top: 0;
      border-top: 0;
      margin-bottom: margins.$margin-default;
      padding-bottom: margins.$margin-default;
      border-bottom: 1px solid var(--border-subtle);
    }

    @include responsive.below-desktop {
      flex-wrap: wrap;
    }
  }
}
