@use 'styles/vars/margins';
@use 'styles/vars/palette';
@use 'styles/vars/fonts';
@use 'styles/mixins/theme';
@use 'styles/mixins/responsive';

.period-selector {
  .period-selector-columns {
    display: flex;
    gap: margins.$margin-default;

    .column-label {
      padding: margins.$margin-small3;
      font-weight: fonts.$font-weight-bold;
    }

    .column-values {
      display: grid;
      grid-template-columns: 1fr;
      gap: margins.$margin-small3;
    }

    @include responsive.below-tablet {
      flex-direction: column;

      .column-values {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
      }
    }
  }

  .period-selector-option-card {
    cursor: pointer;
    min-width: 125px;
    padding: var(--space-sm);
    border-radius: var(--radius-md);

    &:hover {
      background-color: var(--bg-hover);
    }

    &.is-selected {
      background-color: var(--bg-brand);
      color: var(--text-on-brand);
    }
  }
}
