@use 'styles/vars/palette';

.popup-button {
  position: relative;
  cursor: pointer;
  border-radius: 50%;

  > svg {
    font-size: 1.75em;
    transition: opacity 150ms;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }
}
