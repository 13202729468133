@use 'styles/vars/margins';

.outage-map-marker-indicators {
  max-width: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1px;

  > span {
    display: inline-block;
    height: 7px;
    width: 7px;
    margin-bottom: margins.$margin-small3;
    border-radius: 50%;

    &.green {
      background-color: green;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
    }

    &.yellow {
      background-color: yellow;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3);
    }

    &.red {
      background-color: red;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    }
  }
}
