@use 'sass:map';

$margins: (
  small4: 0.125rem,
  small3: 0.25rem,
  small2: 0.5rem,
  small1: 0.6875rem,
  small0: 0.875rem,
  default: 1rem,
  large0: 1.125rem,
  large1: 1.3125rem,
  large2: 1.5625rem,
  large3: 2rem,
  large4: 2.75rem,
  large5: 3.75rem
);

$margin-small4: map.get($margins, small4);
$margin-small3: map.get($margins, small3);
$margin-small2: map.get($margins, small2);
$margin-small1: map.get($margins, small1);
$margin-small0: map.get($margins, small0);

$margin-default: map.get($margins, default);

$margin-large0: map.get($margins, large0);
$margin-large1: map.get($margins, large1);
$margin-large2: map.get($margins, large2);
$margin-large3: map.get($margins, large3);
$margin-large4: map.get($margins, large4);
$margin-large5: map.get($margins, large5);
