// Inject global variables
@use 'montel/primitives';
@use 'montel/sizes';
@use 'montel/text';
@use 'montel/tokens';
@use 'montel/typography';
@use 'montel/shadows';


// Inject theme variables
@use 'theme' as theme-utils;

// Reset browser styles using normalize.css
@use '../../node_modules/normalize.css/normalize';

// Resets
@use 'reset';

// Typography
@use 'typography/typography' as typography-typography;
@use 'typography/links';

// Inputs
@use 'inputs/input';
@use 'inputs/labels';
@use 'inputs/forms';
@use 'inputs/fieldset';

// Helpers
@use 'helpers/theme';
@use 'helpers/margins';
@use 'helpers/opacity';
@use 'helpers/responsive';
@use 'helpers/text' as helpers-text;
@use 'helpers/alignment';

// Overrides
@use 'overrides/quill';
@use 'overrides/shepherd';
@use 'overrides/react-select';

// Components
@use 'components/application-content';
@use 'components/data-table';
@use 'components/selector';
@use 'components/uplot-placeholder';
@use 'components/table';
@use 'components/loading';

// Sections
@use 'sections/analysis';

// External pages (controlled by Django)
@use 'external-pages/dialogs';
@use 'external-pages/settings';

// Global components
@use '../app/components/app/Alert/Alert';
@use '../app/components/app/AnalysisCard/AnalysisCard';
@use '../app/components/app/Avatar/Avatar';
@use '../app/components/app/Bento/Bento';
@use '../app/components/app/BigCard/BigCard';
@use '../app/components/app/Card/Card';
@use '../app/components/app/ChangelogPopup/ChangelogPopup';
@use '../app/components/app/CodeTabs/CodeTabs';
@use '../app/components/app/CodeTabs/CodeBlock';
@use '../app/components/app/ContentGrid/ContentGrid';
@use '../app/components/app/Drawer/Drawer';
@use '../app/components/app/EmptyChecker/EmptyChecker';
@use '../app/components/app/EmptyAnalysisChecker/EmptyAnalysisChecker';
@use '../app/components/app/Footer/Footer';
@use '../app/components/app/ForecastScheduleBar/ForecastScheduleBar';
@use '../app/components/app/ForecastScheduleBarInstance/ForecastScheduleBarInstance';
@use '../app/components/app/ForecastScheduleNotifier/ForecastScheduleNotifier';
@use '../app/components/app/Label/Label';
@use '../app/components/app/LabelGroup/LabelGroup';
@use '../app/components/app/LatestMarketComment/LatestMarketComment';
@use '../app/components/app/LiveBadge/LiveBadge';
@use '../app/components/app/Logo/Logo';
@use '../app/components/app/MapSpinner/MapSpinner';
@use '../app/components/app/MapSpinnerIcon/MapSpinnerIcon';
@use '../app/components/app/MessageBar/MessageBar';
@use '../app/components/app/MessageBox/MessageBox';
@use '../app/components/app/Navigation/Navigation';
@use '../app/components/app/NotFound/NotFound';
@use '../app/components/app/OperationalMessageBars/OperationalMessageBars';
@use '../app/components/app/OperationalMessagePopup/OperationalMessagePopup';
@use '../app/components/app/PageDescriptionButton/PageDescriptionButton';
@use '../app/components/app/PageDescriptionPopup/PageDescriptionPopup';
@use '../app/components/app/PageTabs/PageTabs';
@use '../app/components/app/PlainTabs/PlainTabs';
@use '../app/components/app/Selectors/Selectors';
@use '../app/components/app/SharePageIcon/SharePageIcon';
@use '../app/components/app/SmallTopBar/SmallTopBar';
@use '../app/components/app/StartupTips/StartupTips';
@use '../app/components/app/SubscriptionMessageBars/SubscriptionMessageBars';
@use '../app/components/app/SubscriptionMiddleware/SubscriptionMiddleware';
@use '../app/components/app/TextareaLineNumbers/TextareaLineNumbers';
@use '../app/components/app/ToursPopup/ToursPopup';
@use '../app/components/app/TrialsMessagePopup/TrialsMessagePopup';

// Global selectors
@use '../app/components/selectors/AreaDropdown/AreaDropdown';
@use '../app/components/selectors/AreaMapWithTree/AreaMapWithTree';
@use '../app/components/selectors/AreaSelector/AreaSelector';
@use '../app/components/selectors/ArchiveBar/ArchiveBar';
@use '../app/components/selectors/ArchiveSelector/ArchiveSelector';
@use '../app/components/selectors/DateSliderSelector/DateSliderSelector';
@use '../app/components/selectors/DateRangeSelector/DateRangeSelector';
@use '../app/components/selectors/DaysRangeSelector/DaysRangeSelector';
@use '../app/components/selectors/MultipleAreasMap/MultipleAreasMap';
@use '../app/components/selectors/MultipleChoiceSelector/MultipleChoiceSelector';
@use '../app/components/selectors/SingleDropdownSelector/SingleDropdownSelector';

// Global utils
@use '../app/components/utils/Button/Button';
@use '../app/components/utils/ButtonPopover/ButtonPopover';
@use '../app/components/utils/Calendar/Calendar';
@use '../app/components/utils/Checkbox/Checkbox';
@use '../app/components/utils/ColoredEuropeMap/ColoredEuropeMap';
@use '../app/components/utils/EuropeMap/EuropeMap';
@use '../app/components/utils/InputGroup/InputGroup';
@use '../app/components/utils/NavigateTo/NavigateTo';
@use '../app/components/utils/PaginationBar/PaginationBar';
@use '../app/components/utils/RadioButton/RadioButton';
@use '../app/components/utils/RangedCalendar/RangedCalendar';
@use '../app/components/utils/Select/Select';
@use '../app/components/utils/TooltipIcon/TooltipIcon';
@use '../app/components/utils/Overflow/Overflow';
@use '../app/components/utils/VideoJS/VideoJS';

// Global charts
@use '../app/components/charts/SimpleLegend/SimpleLegend';

// Providers
@use '../app/providers/PopupProvider/PopupProvider';

// Pages
@use '../app/pages/power/PowerRoutes';
@use '../app/pages/co2/Co2Routes';
@use '../app/pages/data/DataRoutes';
@use '../app/pages/local-forecasts/LocalForecastsRoutes';
@use '../app/pages/knowledge-base/KnowledgeBaseRoutes';
@use '../app/pages/changelog/ChangelogRoutes';
@use '../app/pages/outages/OutagesRoutes';
@use '../app/pages/weather/WeatherRoutes';
@use '../app/pages/debug-styles/DebugStylesRoutes';
@use '../app/pages/power2sim/Power2SimRoutes';

// Load fonts from Google Font API
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700|Roboto+Mono|Roboto:300,300i,400,400i,500,500i,700,700i');
