@use 'sass:color';

@use 'styles/vars/margins';
@use 'styles/vars/zindex';
@use 'styles/vars/borders';
@use 'styles/vars/colors';
@use 'styles/vars/palette';
@use 'styles/vars/fonts';
@use 'styles/mixins/rounded-borders';
@use 'styles/mixins/theme';
@use 'styles/mixins/responsive';
@use 'styles/mixins/floating-ui';
@use 'styles/mixins/shadows';

.forecast-schedule-bar {
  h3 {
    display: block;
    font-weight: var(--font-regular);
    font-size: var(--font-xs);
    margin: 0 0 var(--space-md) 0;
    color: var(--text-subtle);

    @include responsive.below-desktop() {
      font-size: var(--font-xxs);
      padding: var(--space-md) var(--space-lg);
      margin: 0;
      text-align: center;
    }
  }

  .instances {
    background-color: var(--bg-main-container);
    position: relative;
    height: 150px;
    overflow: hidden;
    @include rounded-borders.border-radius();

    .tick {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 1px;
      background-color: var(--border-subtle);

      .clock {
        position: absolute;
        font-size: fonts.$font-small;
        top: margins.$margin-small3;
        left: margins.$margin-small2;
        color: var(--border-subtle);
      }

      &.now {
        left: 50%;
        width: 1px;
        background-color: var(--border-brand);
        z-index: zindex.$z-index-above-siblings;

        .clock {
          color: var(--text-brand);
        }
      }
    }

    @include responsive.below-desktop() {
      @include rounded-borders.border-radius();

      .tick {
        .clock {
          display: none;
        }

        &.now .clock {
          display: block;
        }
      }
    }
  }
}

.forecast-schedule-bar-popover {
  background-color: var(--popover-background);
  padding: var(--space-xl);
  width: 60rem;
  z-index: zindex.$z-index-element-mouseovers;
  box-shadow: var(--shadow-lg);
  @include rounded-borders.border-radius();

  h4 {
    font-size: 1em;
    margin: 0 0 var(--space-md) 0;
    color: var(--text-subtle);
  }

  .arrow {
    @include floating-ui.arrow(var(--popover-background));
  }

  .forecasts-list-wrapper {
    display: flex;

    .forecasts-list {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 0 var(--space-xl);

      &.is-completed {
        padding-left: 0;
        border-right: 1px solid var(--border-default);
      }

      &.is-next {
        padding-right: 0;
        border-left: 1px solid var(--border-default);
      }
    }
  }

  .forecast-schedule-bar-popover-item {
    &:not(:last-child) {
      margin-bottom: var(--space-xl);
    }

    .header {
      display: flex;
      flex-direction: column;
      gap: var(--space-sm);
      margin-bottom: var(--space-sm);

      .name {
        font-weight: var(--font-bold);
        color: var(--text-default);
      }

      .color {
        height: 5px;
        border-radius: var(--radius-full);
      }
    }

    p {
      margin: 0;
    }
  }

  @include responsive.below-desktop() {
    padding: var(--space-md);
    margin-top: var(--space-md);
    width: auto;
    background-color: transparent;
    box-shadow: none;

    &.is-ongoing-empty {
      display: none;
    }

    .forecasts-list-wrapper {
      .forecasts-list {
        padding: 0;

        &.is-completed,
        &.is-next {
          display: none;
        }
      }
    }
  }

  & {

    --popover-background: var(--neutral-800);

    @include theme.variant(light) {
      --popover-background: var(--neutral-white);
    }
  }
}
