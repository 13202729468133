@use 'styles/vars/margins';
@use 'styles/vars/colors';

div.dialog-page {
  background-size: 2078px 720px;
  background: var(--neutral-50);

  min-height: 100vh;
  padding-bottom: var(--space-7xl);

  padding-left: var(--space-md);
  padding-right: var(--space-md);

  > * {
    margin-left: auto;
    margin-right: auto;
  }
}

div.dialog-page > div.logo {
  background-image: url('resources/logos/montel-eq.svg');
  background-size: 250px;
  background-repeat: no-repeat;
  background-position: center;
  width: 250px;
  height: 125px;
}

div.dialog-page > p {
  max-width: 400px;
  margin-top: var(--space-xl);
  margin-bottom: var(--space-xl);

  text-align: center;

  opacity: 0.9;
}
