@use 'sass:color';

@use 'styles/vars/colors';
@use 'styles/vars/palette';
@use 'styles/mixins/theme';
@use 'styles/vars/fonts';
@use 'styles/mixins/rounded-borders';

@mixin -variant(
  $button-bg,
  $button-bg-hover,
  $button-border,
  $button-border-hover,
  $font-color,
  $font-color-hover: $font-color
) {
  & {
    --button-bg: #{$button-bg};
    --button-bg-hover: #{$button-bg-hover};
    --button-border: #{$button-border};
    --button-border-hover: #{$button-border-hover};
    --button-text: #{$font-color};
    --button-text-hover: #{$font-color-hover};
  }
}

button,
input[type='button'],
input[type='submit'],
.button,
a:link.button {
  cursor: pointer;
  font-size: var(--font-xs);
  font-weight: var(--font-bold);
  text-decoration: none;
  padding: var(--space-sm) var(--space-lg);
  border: 1px solid var(--button-border);
  border-radius: var(--radius-full);
  background-color: var(--button-bg);
  color: var(--button-text) !important;
  display: flex;
  justify-content: center;
  align-items: center;

  & > svg {
    height: 1.25em !important;
    width: 1.25em !important;
  }

  & > svg:first-child {
    margin-left: -4px;
    margin-right: var(--space-xs);
  }

  & > svg:last-child {
    margin-right: -4px;
    margin-left: var(--space-xs);
  }

  &:focus {
    outline: none;
    border: 1px solid var(--border-focus);
  }

  &:hover,
  &:active {
    background-color: var(--button-bg-hover);
    border-color: var(--button-border-hover);
    color: var(--button-text-hover) !important;
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
    border-color: var(--border-disabled) !important;
    background-color: var(--bg-disabled) !important;
    color: var(--text-disabled) !important;
  }

  &.button-small {
    font-size: var(--font-xxs);
    letter-spacing: 0.025em;
    padding: var(--space-xs) var(--space-md);

    & > svg:first-child {
      margin-left: -3px;
      margin-right: var(--space-xs);
    }

    & > svg:last-child {
      margin-right: -3px;
      margin-left: var(--space-xs);
    }
  }

  &.button-large {
    font-size: var(--font-sm);
    padding: var(--space-md) var(--space-xxl);

    & > svg:first-child {
      margin-left: -6px;
      margin-right:var(--space-xs);
    }

    & > svg:last-child {
      margin-right: -6px;
      margin-left: var(--space-xs);
    }
  }

  &.button-inline {
    display: inline-flex !important;
  }

  &.button-block {
    width: 100%;
  }

  &.button-brick {
    border-radius: var(--radius-md);
  }

  @include -variant(
    var(--bg-brand),
    var(--bg-brand-hover),
    var(--bg-brand),
    var(--bg-brand-hover),
    var(--text-on-brand)
  );

  &.button-secondary {
    @include -variant(
      var(--transparent),
      var(--bg-brand-hover),
      var(--bg-brand),
      var(--bg-brand-hover),
      var(--light-pine),
      var(--text-on-brand)
    );

    @include theme.variant(light) {
      @include -variant(
        var(--transparent),
        var(--bg-brand-hover),
        var(--bg-brand),
        var(--bg-brand-hover),
        var(--pine),
        var(--text-on-brand)
      );
    }

    &:disabled {
      color: var(--text-disabled) !important;
      background-color: var(--transparent) !important;
      border-color: var(--border-disabled) !important;
    }
  }

  &.button-tertiary {
    @include -variant(
      var(--transparent),
      var(--bg-hover),
      var(--transparent),
      var(--transparent),
      var(--light-pine)
    );

    @include theme.variant(light) {
      @include -variant(
        var(--transparent),
        var(--bg-hover),
        var(--transparent),
        var(--transparent),
        var(--pine)
      );
    }

    &:disabled {
      border-color: var(--transparent) !important;
      background-color: var(--transparent) !important;
    }
  }

  &.button-negative {
    @include -variant(
      var(--bg-error),
      var(--bg-error-hover),
      var(--bg-error),
      var(--bg-error-hover),
      var(--text-on-error)
    );

    &:disabled {
      background-color: var(--bg-disabled) !important;
    }
  }

  &.button-selector {
    height: 36px;

    @include -variant(
      var(--selector-bg),
      var(--selector-bg),
      var(--selector-bg),
      var(--selector-bg),
      var(--text-default)
    );

    &:disabled {
      background-color: var(--bg-disabled) !important;
    }
  }
}
