@use 'sass:color';

@use 'styles/vars/palette';
@use 'styles/mixins/theme';
@use 'styles/vars/fonts';

// List form error messages for input/fields

p.form-input-description {
  margin: var(--space-sm) 0;
  font: var(--body-3);
  opacity: 0.75;
}

// Global validation error messages
// TODO Consider using message boxes

p.form-validation-error {
  font-weight: var(--font-bold);
  color: var(--text-error);
}

ul.form-validation-error {
  & > li {
    margin-top: var(--space-sm);
    margin-bottom: 0;
    font-weight: var(--font-bold);
    color: var(--text-error);
  }
}

// List form error messages for input/fields

p.form-input-error {
  margin: var(--space-sm) 0;
  font: var(--body-3);
  color: var(--text-error);
}

ul.form-input-error {
  margin: var(--space-sm) 0;
  padding-left: 0;

  & > li {
    list-style: none;
    margin-top: 0;
    margin-bottom: 0;
    font: var(--body-3);
    color: var(--text-error);
  }
}

// Make a group of label and input(s) as in error state

div.form-group {
  &.form-error {
    > input[type='text'],
    > input[type='password'],
    > input[type='email'],
    > input[type='tel'],
    > input[type='date'],
    > input[type='number'],
    > input[type='time'],
    > input[type='url'],
    > textarea,
    > select,
    > .select-input > select {
      border-color: var(--border-error);
    }

    > label {
      color: var(--form-error-text);
    }
  }
}

// Block-display form

form.block-form,
.block-form {
  div.form-group {
    > label,
    > input[type='text'],
    > input[type='password'],
    > input[type='email'],
    > input[type='tel'],
    > input[type='date'],
    > input[type='number'],
    > input[type='time'],
    > input[type='url'],
    > textarea,
    select,
    > .select-input {
      display: block;
      width: 100%;
    }

    > label {
      margin-top: var(--space-lg);
      margin-bottom: var(--space-sm);
    }
  }

  div.button-group {
    display: flex;
    flex-direction: row;
    gap: var(--space-lg);
    justify-content: flex-start;
    align-items: center;
    margin-top: var(--space-lg);

    &:not(:last-child) {
      margin-bottom: var(--space-lg);
    }

    .pull-right {
      margin-left: auto;
    }
  }
}
